import api from "../../../services/api";

import React, { useState, useEffect } from "react";

import * as globalComponents from "../../../components";

import "../styles.css";

export default function ComissionsAndChambers() {
  window.scroll(0, 0);
  const [commissions, setCommissions] = useState({
    resume: "",
    commissions: [
      {
        Titulo: "",
        Materia: "",
      },
      {
        Titulo: "",
        Materia: "",
      },
      {
        Titulo: "",
        Materia: "",
      },
      {
        Titulo: "",
        Materia: "",
      },
    ],
  });

  const [chambers, setChambers] = useState({
    resume: "",
    physiotherapyChambers: [
      {
        Titulo: "",
        Materia: "",
      },
      {
        Titulo: "",
        Materia: "",
      },
    ],
    occupationalTherapyChambers: [
      {
        Titulo: "",
        Materia: "",
      },
      {
        Titulo: "",
        Materia: "",
      },
    ],
    mixedChambers: [
      {
        Titulo: "",
        Materia: "",
      },
      {
        Titulo: "",
        Materia: "",
      },
    ],
  });

  useEffect(() => {
    async function fetchCommissions() {
      await api
        .get("/comissoes")
        .then((response) => {
          setCommissions(response.data);
        })
        .catch((err) => {
          console.log(err);
        });
    }
    fetchCommissions();
  }, []);

  useEffect(() => {
    async function fetchChambers() {
      await api
        .get("/camaras")
        .then((response) => {
          setChambers(response.data);
        })
        .catch((err) => {
          console.log(err);
        });
    }
    fetchChambers();
  }, []);

  return (
    <>
      <globalComponents.Header />

      <globalComponents.Breadcrumbs
        title="Comissões e Câmaras Técnicas"
        dir={[]}
      />

      <div className="container">
        <globalComponents.PageTitle title="Comissões" />

        <div
          className="content-text"
          dangerouslySetInnerHTML={{ __html: commissions.resume }}
        ></div>

        <globalComponents.CustomAccordion data={commissions.commissions} />

        <globalComponents.PageTitle title="Câmaras Técnicas" />

        <div
          className="content-text"
          dangerouslySetInnerHTML={{ __html: chambers.resume }}
        ></div>

        <globalComponents.CustomAccordion
          data={chambers.physiotherapyChambers}
        />

        <div style={{ marginTop: "40px" }}></div>

        <globalComponents.CustomAccordion
          data={chambers.occupationalTherapyChambers}
        />

        <div style={{ marginTop: "40px" }}></div>

        <globalComponents.CustomAccordion data={chambers.mixedChambers} />
      </div>

      <globalComponents.Footer />
    </>
  );
}
