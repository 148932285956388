import React from "react";

import * as localComponents from "./components";
import Author from "../Author";

import "./styles.css";

export default function Footer() {
  return (
    <div className="container-fluid" style={{ padding: "0px" }}>
      <footer>
        <div className="container">
          <div className="row">
            <div className="col-lg-4 col-sm-12 ">
              <localComponents.FooterNewsletter />
            </div>

            <div className="footer-info-wrapper">
              <div className="col-lg-4 col-sm-12 footer-info">
                <localComponents.FooterContacts />
              </div>

              <div className="col-lg-4 col-sm-12  footer-info">
                <localComponents.FooterNetworks />
              </div>
            </div>
          </div>
          <Author />
        </div>
      </footer>
    </div>
  );
}
