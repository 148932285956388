import api from "../../../../services/api";

import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";

import PublicationCard from "./components/PublicationCard";

import "./styles.css";

import imageIconArrow from "./images/icon-arrow.png";
import imageDefaultThumb from "./images/default_thumb.jpg";

export default function PublicationsBlock() {
  const { accessType } = useParams();
  const [data, setData] = useState([
    {
      Titulo: "",
      Nome_Arquivo: "",
    },
    {
      Titulo: "",
      Nome_Arquivo: "",
    },
    {
      Titulo: "",
      Nome_Arquivo: "",
    },
    {
      Titulo: "",
      Nome_Arquivo: "",
    },
  ]);

  useEffect(() => {
    async function fetchData() {
      await api
        .get("/noticias")
        .then((response) => {
          setData(response.data);
        })
        .catch((err) => {
          console.log(err);
        });
    }
    fetchData();
  }, []);

  return (
    <div
      className="container"
      style={{ paddingTop: "50px", paddingBottom: "50px", marginTop: "0" }}
    >
      <div className="noticias_bloco">
        <div className="noticias_bloco--titulo">
          <div className="noticias_bloco--titulos">
            <h2>Notícias</h2>
            <p>Confira as últimas notícias do Crefito-2</p>
          </div>

          <div className="noticias_bloco--button">
            <Link className="button" to={`/${accessType}/noticias`}>
              Ver mais Notícias <img src={imageIconArrow} alt="" />
            </Link>
          </div>
        </div>

        <div className="news_web">
          <div className="news_highlights">
            {data.slice(0, 1).map((value, index) => (
              <PublicationCard
                key={index}
                type={"highlight"}
                imageThumb={
                  value.Nome_Arquivo === null
                    ? imageDefaultThumb
                    : value.Nome_Arquivo
                }
                title={value.Titulo}
                code={value.Codigo}
              />
            ))}
          </div>
          <div className="news_secondary">
            {data.slice(1, 4).map((value, index) => (
              <PublicationCard
                key={index}
                type={"secondary"}
                imageThumb={
                  value.Nome_Arquivo === null
                    ? imageDefaultThumb
                    : value.Nome_Arquivo
                }
                title={value.Titulo}
                code={value.Codigo}
              />
            ))}
          </div>
        </div>

        <div className="news_mobile">
          <div className="row">
            {data.map((value, index) => (
              <PublicationCard
                key={index}
                type={"mobile"}
                imageThumb={
                  value.Nome_Arquivo === null
                    ? imageDefaultThumb
                    : value.Nome_Arquivo
                }
                title={value.Titulo}
                code={value.Codigo}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}
