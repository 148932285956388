import api from "../../../../services/api";

import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";

import * as globalComponents from "../../../../components";
import * as localComponents from "../../components";

import "../styles.css";

export default function Courses() {
  const { accessType } = useParams();

  const [data, setData] = useState({
    resume:
      "Capacitações e cursos em andamento ou encerrados oferecidos pelo Crefito-2",
    inProgress: [
      {
        Codigo: null,
        Titulo: null,
        Chamada: null,
        Data_Expiracao: null,
        Ordem: null,
      },
    ],
    closed: [
      {
        Codigo: null,
        Titulo: null,
        Chamada: null,
        Data_Expiracao: null,
        Ordem: null,
      },
    ],
  });

  useEffect(() => {
    window.scroll(0, 0);
    async function fetchData() {
      await api
        .get("/capacitacoes-e-cursos")
        .then((response) => {
          setData(response.data);
        })
        .catch((err) => {
          console.log(err);
        });
    }
    fetchData();
  }, []);

  return (
    <>
      <globalComponents.Header />

      <globalComponents.Breadcrumbs title="Capacitações e Cursos" dir={[]} />

      <div className="container">
        <globalComponents.PageTitle
          title="Capacitações e Cursos"
          span={data.resume}
        />

        <div className="title_licitacao__andamento">
          <strong>Capacitações e cursos em andamento</strong>

          {data.inProgress.map((value, index) => {
            if (value.Chamada && value.Ordem) {
              const year = value.Ordem.toString().slice(0, 4);

              if (!value.Chamada.includes(year)) {
                if (value.Chamada.includes("<br />")) {
                  const elementsArray = value.Chamada.split("<br />");

                  elementsArray[0] += ` de ${year}`;
                  value.Chamada = elementsArray.join("<br />");
                } else {
                  value.Chamada += ` (${year})`;
                }
              }
            }

            return (
              <localComponents.InProgressCard
                key={index}
                title={value.Titulo}
                span={value.Chamada}
                href={`/${accessType}/capacitacoes-e-cursos/${value.Codigo}`}
              />
            );
          })}
        </div>

        <div className="title_licitacao__encerradas">
          <strong>Capacitações e cursos encerrados</strong>

          {data.closed.map((value, index) => {
            if (value.Chamada && value.Ordem) {
              const year = value.Ordem.toString().slice(0, 4);

              if (!value.Chamada.includes(year)) {
                if (value.Chamada.includes("<br />")) {
                  const elementsArray = value.Chamada.split("<br />");

                  elementsArray[0] += ` de ${year}`;
                  value.Chamada = elementsArray.join("<br />");
                } else {
                  value.Chamada += ` (${year})`;
                }
              }
            }

            return (
              <localComponents.ClosedCard
                key={index}
                title={value.Titulo}
                span={value.Chamada}
                href={`/${accessType}/capacitacoes-e-cursos/${value.Codigo}`}
              />
            );
          })}
        </div>
      </div>

      <globalComponents.Footer />
    </>
  );
}
