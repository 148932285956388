import React from "react";
import { useParams } from "react-router-dom";

import * as globalComponents from "../../../../components";
import IdentifiedReportsForm from "./components/IdentifiedReportsForm";

import "../styles.css";

export default function IdentifiedReports() {
  const { accessType } = useParams();
  const dir = [
    {
      title: "Fiscalização",
      url: `/${accessType}/fiscalizacao`,
    },
    {
      title: "Denúncias",
      url: `/${accessType}/fiscalizacao/denuncias`,
    },
  ];

  return (
    <>
      <globalComponents.Header />

      <globalComponents.Breadcrumbs title="Denúncia Identificada" dir={dir} />

      <div className="container">
        <globalComponents.PageTitle title="Denúncia Identificada" />

        <div className="denuncia--container__wrapper">
          <div className="denuncia--content">
            <div className="denuncia__texto">
              <p>
                Pode ser oferecida por qualquer pessoa interessada. Deve conter:
                nome completo do denunciante, qualificação, endereço completo,
                telefone, e-mail, nome do denunciado, se possível o número de
                registro do profissional, dados das testemunhas (se houver)
                ,nome do local (hospital, clínica ou consultório), endereço
                completo do local, narração dos fatos, as circunstâncias e
                demais elementos que possam contribuir.
              </p>
              <br />
              <p>
                Poderão também ser enviados comprovantes dos fatos, como fotos,
                declarações de clientes, dentre outros.
              </p>
              <br />
              <p>
                Quanto mais informações forem enviadas, mais dirigida será a
                apuração, aumentando as chances no alcance de resultados
                satisfatórios.
              </p>
              <br />
              <p>
                O denunciante será parte integrante do processo, onde todos os
                envolvidos terão acesso ao seu conteúdo.
              </p>
              <br />
              <p>
                O denunciante receberá um número de protocolo no e-mail
                cadastrado e poderá acompanhar o status de sua denúncia.
              </p>
              <br />
              <p>
                Para acompanhar o protocolo gerado,{" "}
                <a
                  href="https://www.crefito2.com.br/spw/consultacadastral/ConsultarProcessosPublico.aspx"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <strong>Clique Aqui</strong>
                </a>
                .
              </p>
            </div>

            <IdentifiedReportsForm />
          </div>
        </div>
      </div>

      <globalComponents.Footer />
    </>
  );
}
