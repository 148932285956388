import React from "react";
import { useParams } from "react-router-dom";

import * as globalComponents from "../../../../components";
import ReportsForm from "./components/ReportsForm";

import "../styles.css";

export default function Reports() {
  const { accessType } = useParams();
  const dir = [
    {
      title: "Fiscalização",
      url: `/${accessType}/fiscalizacao`,
    },
    {
      title: "Denúncias",
      url: `/${accessType}/fiscalizacao/denuncias`,
    },
  ];

  return (
    <>
      <globalComponents.Header />

      <globalComponents.Breadcrumbs title="Denúncia" dir={dir} />

      <div className="container">
        <globalComponents.PageTitle title="Denúncia" />

        <div className="denuncia--container__wrapper">
          <div className="denuncia--content">
            <div className="denuncia__texto">
              <p>
                Denúncia é o ato formal no qual o representante expõe sua
                acusação perante a autoridade competente para julgar o(s)
                fato(s) descrito(s) nessa petição e pode ser apresentada por
                agente voluntário ou qualquer pessoa, natural ou jurídica,
                sujeita à jurisdição do Conselho Regional e acompanhada de
                indício concernente à irregularidade ou ilegalidade denunciada.
                Não será reconhecida como válida aquela que não observe os
                requisitos e formalidades legais, ou seja, somente será recebida
                quando assinada, declinada a qualificação do denunciante e
                acompanhada da indicação dos elementos comprobatórios do alegado
                - lei 6316/75, art. 17, § 5º. Tudo o que é necessário para
                apresentá-la ao Conselho Regional está listado no §1º do art. 10
                da Resolução COFFITO 13/79 e art. 4º da Resolução COFFITO
                423/13.
              </p>
              <br />
              <p>
                Caso não atenda aos requisitos formais, a denúncia deverá ser
                arquivada. Não é possível a manutenção do sigilo em relação à
                sua autoria em virtude do que dispõe o artigo 5º da
                Constituição, inciso LV: aos litigantes, em processo judicial ou
                administrativo, e aos acusados em geral são assegurados o
                contraditório e ampla defesa, com os meios e recursos a ela
                inerentes.
              </p>
              <br />
              <p>
                A representação, “denúncia” anônima/informal ou comunicação de
                irregularidade é utilizada para dar ciência ao Conselho de um
                fato irregular de que se tenha notícia, sendo fundamental que a
                manifestação seja apresentada com a maior quantidade possível de
                informações para permitir auxiliar os trabalhos da fiscalização
                - Resolução COFFITO 423/13, art. 4-§ único, a qual decidirá
                sobre a melhor oportunidade de utilizá-las. Não é necessário se
                identificar neste caso, mas isso não garante ao mesmo a
                qualidade de parte interessada em eventual processo que venha
                ser instaurado em decorrência da comunicação em questão,
                restando a critério do Órgão recebe-la ou não, bem como decidir
                a que tempo atuar.
              </p>
              <br />
              <p>
                Com o objetivo de proteger os direitos dos litigantes
                (denunciante / representante, denunciado, interessado e/ou
                administrados) e ao melhor cumprimento dos fins da
                administração, após qualquer representação e/ou denúncia formal,
                a administração pública deverá instaurar um processo
                administrativo dando ciência das suas tramitações e possibilitar
                às partes interessadas vista aos autos; disponibilizando, quando
                solicitada, cópias de documentos neles contidos, inclusive suas
                decisões - Leis 9784/99 (processo administrativo) e 12.527/11
                (lei de acesso a informações).
              </p>
              <br />
              <p>
                Na insuficiência de elementos para abertura direta do processo
                ético-disciplinar, a Diretoria do Conselho remeterá os autos ao
                Departamento de Fiscalização, que procederá às diligências
                necessárias - Resolução COFFITO 423/13, art. 5. Em outras
                palavras, se a reclamação configurar infração já consumada, ou
                seja, situação passada, será alvo direto de procedimento
                processual apuratório, diferente se corresponder à ocorrência
                presente, constatável in loco pelo fiscal. O fisioterapeuta e o
                terapeuta ocupacional devem reprovar quem infringe postulado
                ético ou dispositivo legal e representar aos Conselhos Regional
                e Federal de Fisioterapia e Terapia Ocupacional de acordo com as
                previsões do Código do Processo Ético- Disciplinar, e quando for
                o caso, aos demais órgãos competentes - art. 19 da Res. COFFITO
                424 (Fisioterapia) e 425 (Terapia Ocupacional).Portanto,
                denunciar é um ato legítimo e ético.
              </p>
              <br />
              <p>
                Ao apresentar sua queixa ao Conselho esteja ciente que o
                desdobramento para eventual punição dependerá se o fato narrado
                caracterizar transgressão à legislação do Conselho Federal, ou
                seja, se a conduta profissional contrariar as normas a que ele
                está submetido.
              </p>
              <br />
              <p>Departamento de Fiscalização - DEFIS</p>
            </div>

            <ReportsForm />
          </div>
        </div>
      </div>

      <globalComponents.Footer />
    </>
  );
}
