import * as React from "react";

export const useIsOverflown = (ref, callback) => {
  const [isOverflown, setIsOverflown] = React.useState(undefined);

  React.useLayoutEffect(() => {
    const { current } = ref;

    const trigger = () => {
      const hasOverflown = current.scrollWidth > current.clientWidth;

      setIsOverflown(hasOverflown);

      if (callback) callback(hasOverflown);
    };

    if (current) {
      trigger();
    }
  }, [callback, ref]);

  return isOverflown;
};
