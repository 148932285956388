import React from "react";

import * as globalComponents from "../../../../../components";
import SicknessBasedDiscountResolutionForm from "./components/SicknessBasedDiscountResolutionForm";

import "../../styles.css";

export default function SicknessBasedDiscountResolution() {
  return (
    <>
      <globalComponents.Header />

      <globalComponents.Breadcrumbs
        title="Solicitação de Isenção de Anuidades por Doenças Graves"
        dir={[]}
      />

      <div className="container">
        <globalComponents.PageTitle title="FORMULÁRIO PARA SOLICITAÇÃO DE ISENÇÃO DE ANUIDADES PARA PORTADORES DE DOENÇAS GRAVES." />
        <p>
          <a
            href="https://www.coffito.gov.br/nsite/?p=6209"
            style={{ color: "var(--secondary-color)" }}
            target="_blank"
            rel="noopener noreferrer"
          >
            RESOLUÇÃO COFFITO Nº 472, DE 20 DE DEZEMBRO DE 2016.
          </a>
        </p>
        <div className="denuncia--container__wrapper">
          <div className="denuncia--content">
            <SicknessBasedDiscountResolutionForm />
          </div>
        </div>
      </div>

      <globalComponents.Footer />
    </>
  );
}
