import React from "react";
import { useParams } from "react-router-dom";

import * as globalComponents from "../../../../../components";
import ListEntry from "../../../components/ListEntry";

import "../../../components/ListEntry/styles.css";
import "../../styles.css";

export default function OnlineServices() {
  window.scroll(0, 0);
  const { accessType } = useParams();

  const professionalExternalLinks = [
    // {
    //   title: "Acesso ao sistema",
    //   href: "https://www.crefito2.com.br/spw/consultacadastral/TelaLogin.aspx",
    // },
    // {
    //   title: "Emissão de Certidão de Regularidade Pecuniária",
    //   href: "https://www.crefito2.com.br/spw/consultacadastral/certidaoexterna.aspx?c=1&m=1",
    // },
    {
      title: "Consultar Veracidade de Certidões Emitidas",
      href: "https://www.crefito2.com.br/spw/consultacadastral/ConfirmaVeracidadePublica.aspx",
    },
    {
      title: "Consulta a protocolos",
      href: "https://www.crefito2.com.br/spw/consultacadastral/ConsultarProcessosPublico.aspx",
    },
  ];

  const establishmentsExternalLinks = [
    // {
    //   title: "Acesso ao sistema/Empresa",
    //   href: "https://www.crefito2.com.br/spw/consultacadastral/TelaLoginEmpresa.aspx",
    // },
    {
      title:
        "Emissão de Declaração de Regularidade para Funcionamento (DRF) - Empresa",
      href: "https://www.crefito2.com.br/spw/DRF/DeclaracaoEmpresa.aspx",
    },
    {
      title:
        "Emissão de Declaração de Regularidade para Funcionamento (DRF) - Consultório",
      href: "https://www.crefito2.com.br/spw/DRF/DeclaracaoConsultorio.aspx",
    },
    {
      title: "Consultar Veracidade de Certidões Emitidas",
      href: "https://www.crefito2.com.br/spw/consultacadastral/ConfirmaVeracidadePublica.aspx",
    },
    {
      title: "Consulta a protocolos",
      href: "https://www.crefito2.com.br/spw/consultacadastral/ConsultarProcessosPublico.aspx",
    },
  ];

  return (
    <>
      <globalComponents.Header />

      <globalComponents.Breadcrumbs title="Serviços Online" dir={[]} />

      <div className="container">
        <div className="title__publications">
          <strong>Profissional</strong>

          <ListEntry
            title={"Justificativa Eleitoral"}
            href={`/${accessType}/servicos-online/justificativa_eleitoral`}
          />

          <ListEntry
            title={"Atualização Cadastral"}
            href={`/${accessType}/servicos-online/atualizacao_cadastral`}
          />

          <ListEntry
            title={"Orientações sobre registro e outros serviços"}
            href={`/${accessType}/servicos-online/orientacoes-profissionais`}
          />

          <a
            className="publications_block"
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.crefito2.com.br/spw/consultacadastral/certidaoexterna.aspx?c=1&m=1"
          >
            <div className="publication-content">
              <p className="title">
                Emissão de Certidão de Regularidade Pecuniária
              </p>
              <p className="call"></p>
            </div>
          </a>

          <ListEntry
            title={"Emissão de Certidão de Ética"}
            href={`/${accessType}/conteudo/4294`}
          />

          {professionalExternalLinks.map((value, index) => (
            <a
              key={index}
              className="publications_block"
              target="_blank"
              rel="noopener noreferrer"
              href={value.href}
            >
              <div className="publication-content">
                <p className="title">{value.title}</p>
                <p className="call"></p>
              </div>
            </a>
          ))}

          <ListEntry
            title="Manual de Autofiscalização"
            href={`/${accessType}/servicos-online/manual_de_autofiscalizacao`}
          />
        </div>

        <div className="title__publications">
          <strong>Empresas e Consultórios</strong>

          <ListEntry
            title="Orientações sobre registro e outros serviços"
            href={`/${accessType}/servicos-online/orientacoes-empresas-e-consultorios`}
          />

          {establishmentsExternalLinks.map((value, index) => (
            <a
              key={index}
              className="publications_block"
              target="_blank"
              rel="noopener noreferrer"
              href={value.href}
            >
              <div className="publication-content">
                <p className="title">{value.title}</p>
                <p className="call"></p>
              </div>
            </a>
          ))}

          <ListEntry
            title="Manual de Autofiscalização"
            href={`/${accessType}/servicos-online/manual_de_autofiscalizacao`}
          />
        </div>

        <div className="title__publications">
          <strong>Estágios</strong>

          <ListEntry
            title="Orientações sobre Estágios Curriculares obrigatórios e não-obrigatórios."
            href={`/${accessType}/servicos-online/orientacoes-estagios`}
          />

          <ListEntry
            title="Modelo de Termo de Compromisso de Estágio."
            href={`/${accessType}/servicos-online/termo-de-compromisso-estagio`}
          />
        </div>
      </div>

      <globalComponents.Footer />
    </>
  );
}
