import api from "../../../../services/api";

import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";

import * as globalComponents from "../../../../components";
import * as localComponents from "../../components";

import "../styles.css";

export default function Bids() {
  const { accessType } = useParams();
  const [data, setData] = useState({
    resume:
      "A Comissão Permanente de Licitação do Conselho é responsável por preparar, analisar, avaliar e julgar os processos licitatórios",
    inProgress: [
      {
        Codigo: null,
        Titulo: null,
        Chamada: null,
        Data_Expiracao: null,
        Ordem: null,
      },
    ],
    dispensation: [
      {
        Codigo: null,
        Titulo: null,
        Chamada: null,
        Data_Expiracao: null,
        Ordem: null,
      },
    ],
    closed: [
      {
        Codigo: null,
        Titulo: null,
        Chamada: null,
        Data_Expiracao: null,
        Ordem: null,
      },
    ],
  });

  useEffect(() => {
    window.scroll(0, 0);
    async function fetchData() {
      await api
        .get("/licitacoes")
        .then((response) => {
          setData(response.data);
        })
        .catch((err) => {
          console.log(err);
        });
    }
    fetchData();
  }, []);

  return (
    <>
      <globalComponents.Header />

      <globalComponents.Breadcrumbs title="Licitações" dir={[]} />

      <div className="container">
        <globalComponents.PageTitle title="Licitações" span={data.resume} />

        <a
          className="link_portaria"
          rel="noopener noreferrer"
          href="https://pncp.gov.br/app/pca/29991262000147/2025"
          target="_blank"
        >
          Link do PCA - CREFITO-2 - 2025 publicado
        </a>

        <br />

        <a
          className="link_portaria"
          rel="noopener noreferrer"
          href="https://crefito2.go2webdbm.com.br/PORTARIA_CREFITO-2_007-2023.pdf"
          target="_blank"
        >
          PORTARIA CREFITO-2 Nº 07, 09 DE MARÇO DE 2023
        </a>

        <div className="title_licitacao__andamento">
          <strong>Licitações em andamento</strong>

          {data.inProgress.map((value, index) => (
            <localComponents.InProgressCard
              key={index}
              title={value.Titulo}
              span={value.Chamada}
              href={`/${accessType}/licitacoes/${value.Codigo}`}
            />
          ))}
        </div>

        <div className="title_licitacao__dispensadas">
          <strong>Dispensa de licitação</strong>

          {data.dispensation.map((value, index) => (
            <localComponents.DispensationCard
              key={index}
              title={value.Titulo}
              span={value.Chamada}
              href={`/${accessType}/licitacoes/${value.Codigo}`}
            />
          ))}
        </div>

        <div className="title_licitacao__encerradas">
          <strong>Licitações encerradas</strong>

          {data.closed.map((value, index) => (
            <localComponents.ClosedCard
              key={index}
              title={value.Titulo}
              span={value.Chamada}
              href={`/${accessType}/licitacoes/${value.Codigo}`}
            />
          ))}
        </div>
      </div>

      <globalComponents.Footer />
    </>
  );
}
