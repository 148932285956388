import api from "../../../services/api";

import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";

import * as globalComponents from "../../../components";

import "../styles.css";

export default function FiscalizationScene() {
  const { accessType, contentID } = useParams();

  const dir = [
    {
      title: "Fiscalizações",
      url: `/${accessType}/fiscalizacao`,
    },
  ];

  const [data, setData] = useState({
    Titulo: null,
    Subtitulo: null,
    Chamada: null,
    Data_Publicacao: null,
    Nome_Arquivo: null,
    Materia: null,
    Link: null,
  });

  useEffect(() => {
    window.scroll(0, 0);
    async function fetchData() {
      await api
        .get(`/conteudo/${contentID}`)
        .then((response) => {
          setData(response.data);
        })
        .catch((err) => {
          console.log(err);
        });
    }
    fetchData();
  }, [contentID]);

  return (
    <>
      <globalComponents.Header />

      <globalComponents.Breadcrumbs title={data.Titulo} dir={dir} />

      <div className="container">
        <div className="content-wrapper">
          <globalComponents.PageTitle
            title={data.Titulo}
            subtitle={data.Subtitulo}
            span={data.Data_Publicacao}
          />

          <div className="content-image">
            {data.Link ? (
              <a href={data.Link}>
                <img src={data.Nome_Arquivo} alt="" />
              </a>
            ) : (
              <img src={data.Nome_Arquivo} alt="" />
            )}
          </div>

          <div
            className="content-text"
            dangerouslySetInnerHTML={{ __html: data.Materia }}
          ></div>
        </div>
      </div>

      <globalComponents.Footer />
    </>
  );
}
