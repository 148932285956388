import React from "react";

import * as globalComponents from "../../../../../components";
import FiftyPercentDiscountResolutionForm from "./components/FiftyPercentDiscountResolutionForm";

import "../../styles.css";

export default function FiftyPercentDiscountResolution() {
  return (
    <>
      <globalComponents.Header />

      <globalComponents.Breadcrumbs
        title="Solicitação de Desconto na Anuidade"
        dir={[]}
      />

      <div className="container">
        <globalComponents.PageTitle title="FORMULÁRIO PARA SOLICITAÇÃO DE 50% DE DESCONTO" />
        <p>
          <a
            href="https://www.coffito.gov.br/nsite/?p=30151"
            style={{ color: "var(--secondary-color)" }}
            target="_blank"
            rel="noopener noreferrer"
          >
            Resolução COFFITO Nº 598/2024
          </a>
          : Profissionais com 30 (trinta) anos de inscrição ou mais, será
          concedido desconto de 50% (cinquenta por cento) para o pagamento das
          anuidades, até o dia 31/12, do ano que antecede a anuidade gerada.
        </p>
        <div className="denuncia--container__wrapper">
          <div className="denuncia--content">
            <FiftyPercentDiscountResolutionForm />
          </div>
        </div>
      </div>

      <globalComponents.Footer />
    </>
  );
}
