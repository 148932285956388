import React from "react";
import { Link } from "react-router-dom";
import { useParams } from "react-router-dom";

import SocialNetworks from "../../../SocialNetworks";

import "./styles.css";

export default function FooterContacts() {
  const { accessType } = useParams();

  return (
    <div className="footer__redes">
      <p>Redes Sociais</p>

      <SocialNetworks />

      <Link className="faq_link" to={`/${accessType}/conteudo/4836`}>
        Perguntas Mais Frequentes
      </Link>
    </div>
  );
}
