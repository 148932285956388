import api from "../../../services/api";

import React, { useState, useEffect } from "react";

import * as globalComponents from "../../../components";

import "../styles.css";

export default function Transparency() {
  const [data, setData] = useState({
    resume: "",
    transparencyContent: [
      {
        Titulo: "",
        Materia: "",
      },
      {
        Titulo: "",
        Materia: "",
      },
      {
        Titulo: "",
        Materia: "",
      },
      {
        Titulo: "",
        Materia: "",
      },
    ],
  });

  useEffect(() => {
    async function fetchData() {
      await api
        .get("/transparencia")
        .then((response) => {
          setData(response.data);
        })
        .catch((err) => {
          console.log(err);
        });
    }
    fetchData();
  }, []);

  return (
    <>
      <globalComponents.Header />

      <globalComponents.Breadcrumbs
        title="Transparência e Prestação de Contas"
        dir={[]}
      />

      <div className="container">
        <globalComponents.PageTitle title="Transparência e Prestação de Contas" />

        <div
          className="content-text"
          dangerouslySetInnerHTML={{ __html: data.resume }}
        ></div>

        <globalComponents.CustomAccordion data={data.transparencyContent} />
      </div>

      <globalComponents.Footer />
    </>
  );
}
