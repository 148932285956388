import React from "react";

import * as globalComponents from "../../../../components";
import NationalSalaryFloorForm from "./components/NationalSalaryFloorForm";

import "./styles.css";
import "../styles.css";

import banner from "./images/banner.jpg";

export default function NationalSalaryFloor() {
  return (
    <>
      <globalComponents.Header />

      <globalComponents.Breadcrumbs
        title="Solicitação do Piso Salarial Nacional"
        dir={[]}
      />

      <div className="container">
        <globalComponents.PageTitle title="Solicitação do Piso Salarial Nacional" />

        <div className="national-floor-banner-container">
          <img
            className="national-salary-floor-banner"
            src={banner}
            alt="Solicite aos deputados federais a aprovação do piso nacional."
          />
        </div>

        <p>
          O projeto de lei 1731/21 estabelece o piso salarial nacional de
          fisioterapeutas e terapeutas ocupacionais. O momento é de nova
          mobilização. Precisamos, mais uma vez, unirmos esforços e fazermos
          nossa voz chegar aos Deputados Federais! Preencha o formulário abaixo
          e envie sua mensagem aos deputados.
        </p>

        <div className="denuncia--container__wrapper">
          <div className="denuncia--content">
            <NationalSalaryFloorForm />
          </div>
        </div>
      </div>

      <globalComponents.Footer />
    </>
  );
}
