import React from "react";
import { Link } from "react-router-dom";

import "./styles.css";

export default function HighlightCard({ service, span, image, href }) {
  return (
    <Link className="card--servicoSecundario" to={href}>
      <div className="card--servicoSecundario--icone">
        <img src={image} alt="" />
      </div>

      <div className="card--servicoSecundario--texto">
        <div
          className="card--servicoSecundario--texto--span"
          dangerouslySetInnerHTML={{
            __html: span,
          }}
        ></div>
        <p>{service}</p>
      </div>
    </Link>
  );
}
