import React from "react";
import { Link } from "react-router-dom";

import "./styles.css";

export default function PublicationCard({ imageThumb, title, href, button }) {
  return (
    <Link className="col-md-3 clickable" to={href}>
      <div className="publicacoes_block">
        <div className="publication-content">
          <div className="publicacoes_block-img">
            <img src={imageThumb} alt="" />
          </div>

          <div className="publicacoes_block-texto">
            <p dangerouslySetInnerHTML={{ __html: title }}></p>
          </div>
        </div>

        {/* <div className="publicacoes_block-button">
          <Link className="button" to={href}>
            {button}
          </Link>
        </div> */}
      </div>
    </Link>
  );
}
