import React, { useState, useEffect } from "react";

import * as globalComponents from "../../../../components";
import api from "../../../../services/api";
import ManualsAndOrientationsSection from "./components/ManualsAndOrientationsSection";

import "../styles.css";

export default function ManualsAndOrientationsMenu() {
  const [data, setData] = useState([]);

  useEffect(() => {
    window.scroll(0, 0);
    async function fetchData() {
      await api
        .get("/manuais_e_orientacoes")
        .then((res) => {
          setData(res.data);
        })
        .catch((err) => {
          console.log(err);
        });
    }
    fetchData();
  }, []);

  return (
    <>
      <globalComponents.Header />

      <globalComponents.Breadcrumbs title="Manuais e Orientações" dir={[]} />

      <div className="container">
        {data.map((value, index) => (
          <ManualsAndOrientationsSection
            key={index}
            title={value.sectionName}
            content={value.content}
          />
        ))}
      </div>

      <globalComponents.Footer />
    </>
  );
}
