import api from "../../../../../../services/api";

import React from "react";
import { useForm } from "react-hook-form";

import "./styles.css";

export default function NewsletterForm() {
  const { register, handleSubmit } = useForm();

  async function onSubmit(data) {
    const userForm = {
      email: data.email,
    };

    try {
      const result = await api.post(`/form/newsletter`, userForm);
      if (!alert(result.data.message)) {
        window.scroll(0, 0);
        window.location.reload();
      }
    } catch (err) {
      if (!alert("Preencha pelo menos um campo, por favor.")) {
        window.scroll(0, 0);
        window.location.reload();
      }
    }
  }

  return (
    <div className="denuncia__form">
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="form__title">
          <p>Newsletter</p>
        </div>

        <div className="form__group">
          <label htmlFor="protocol">Endereço de E-mail:</label>
          <input
            {...register("email")}
            type="email"
            placeholder="Endereço de E-Mail"
          />

          <input id="submit" className="button" type="submit" value="Enviar" />
        </div>
      </form>
    </div>
  );
}
