import api from "../../../../services/api";

import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";

import * as globalComponents from "../../../../components";
import * as localComponents from "../../components";

import "../styles.css";

export default function Contests() {
  const { accessType } = useParams();

  const [data, setData] = useState({
    resume:
      "O Crefito-2 divulga somente concursos públicos que disponham de vagas para fisioterapeuta e terapeuta ocupacional, desde que em conformidade com a legislação vigente para estas profissões da Saúde. Caso seja encontrado algum ponto fora dos quesitos legais em um Edital, o Crefito-2 reserva-se ao direito de não divulgá-los e aciona seu Departamento Jurídico, para que sejam tomadas as devidas providências, garantido o cumprimento da lei em defesa dos profissionais e da sociedade.",
    inProgress: [
      {
        Codigo: null,
        Titulo: null,
        Chamada: null,
        Data_Expiracao: null,
      },
      {
        Codigo: null,
        Titulo: null,
        Chamada: null,
        Data_Expiracao: null,
      },
    ],
    closed: [
      {
        Codigo: null,
        Titulo: null,
        Chamada: null,
        Data_Expiracao: null,
      },
      {
        Codigo: null,
        Titulo: null,
        Chamada: null,
        Data_Expiracao: null,
      },
    ],
  });

  useEffect(() => {
    window.scroll(0, 0);
    async function fetchData() {
      await api
        .get("/concursos")
        .then((response) => {
          setData(response.data);
        })
        .catch((err) => {
          console.log(err);
        });
    }
    fetchData();
  }, []);

  return (
    <>
      <globalComponents.Header />

      <globalComponents.Breadcrumbs
        title="Concursos e Oportunidades"
        dir={[]}
      />

      <div className="container">
        <globalComponents.PageTitle
          title="Concursos e Oportunidades"
          span={data.resume}
        />

        <div className="title_licitacao__andamento">
          <strong>Em andamento</strong>

          {data.inProgress.map((value, index) => (
            <localComponents.InProgressCard
              key={index}
              title={value.Titulo}
              span={value.Chamada}
              href={`/${accessType}/concursos_e_oportunidades/${value.Codigo}`}
            />
          ))}
        </div>

        <div className="title_licitacao__encerradas">
          <strong>Encerrados</strong>

          {data.closed.map((value, index) => (
            <localComponents.ClosedCard
              key={index}
              title={value.Titulo}
              span={value.Chamada}
              href={`/${accessType}/concursos_e_oportunidades/${value.Codigo}`}
            />
          ))}
        </div>
      </div>

      <globalComponents.Footer />
    </>
  );
}
