import api from "../../../../services/api";

import React, { useState, useEffect } from "react";
import { useLocation, useParams } from "react-router-dom";

import * as globalComponents from "../../../../components";
import TributesEntry from "./components/TributesEntry";

import "../styles.css";
import "./styles.css";

export default function Tributes() {
  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }

  let query = useQuery();
  const { accessType } = useParams();
  const currentDate = new Date();
  const [emptyTributes, setEmptyTributes] = useState(false);
  const [month, setMonth] = useState(
    query.get("mes") === null
      ? parseInt(currentDate.getMonth() + 1)
      : parseInt(query.get("mes"))
  );
  const [year, setYear] = useState(
    query.get("ano") === null
      ? parseInt(currentDate.getFullYear())
      : parseInt(query.get("ano"))
  );
  const [publications, setPublications] = useState([]);
  const validYears = [2014];

  useEffect(() => {
    if (emptyTributes) {
      document.getElementById("empty_tribute").removeAttribute("hidden");
    } else if (!emptyTributes) {
      document.getElementById("empty_tribute").setAttribute("hidden", "true");
    }
  }, [emptyTributes]);

  useEffect(() => {
    window.scroll(0, 0);
    let queryParams = new URLSearchParams(window.location.search);
    queryParams.set("mes", month);
    queryParams.set("ano", year);
    window.history.replaceState(null, null, "?" + queryParams.toString());
    document.getElementById("month").value = month;
    document.getElementById("year").value = year;
    async function fetchPublications() {
      await api
        .get(`/homenagens?mes=${month}&ano=${year}`)
        .then((response) => {
          setEmptyTributes(false);
          setPublications(response.data);
        })
        .catch((err) => {
          setEmptyTributes(true);
          setPublications([]);
          console.log(err);
        });
    }
    fetchPublications();
  }, [month, year]);

  for (let i = validYears[0]; i < currentDate.getFullYear(); i++) {
    validYears.push(i + 1);
  }

  return (
    <>
      <globalComponents.Header />

      <globalComponents.Breadcrumbs title={"Homenagens"} dir={[]} />

      <div className="container">
        <div className="title__publications">
          <div className="tribute_header">
            <strong>
              Homenagens - {`${month}/${currentDate.getFullYear()}`}
            </strong>
            <div className="month_select">
              <label htmlFor="month">Mês: </label>
              <select
                id="month"
                name="month"
                onChange={(e) => {
                  setMonth(e.target.value);
                }}
              >
                <option value={1}>Janeiro</option>
                <option value={2}>Fevereiro</option>
                <option value={3}>Março</option>
                <option value={4}>Abril</option>
                <option value={5}>Maio</option>
                <option value={6}>Junho</option>
                <option value={7}>Julho</option>
                <option value={8}>Agosto</option>
                <option value={9}>Setembro</option>
                <option value={10}>Outubro</option>
                <option value={11}>Novembro</option>
                <option value={12}>Dezembro</option>
              </select>

              <label htmlFor="year">Ano: </label>
              <select
                id="year"
                name="year"
                onChange={(e) => {
                  setYear(e.target.value);
                }}
              >
                {validYears.map((value, index) => (
                  <option key={index} value={value}>
                    {value}{" "}
                  </option>
                ))}
              </select>
            </div>
          </div>

          <div id="empty_tribute" className="empty_tribute" hidden>
            <h3>Registros Não Encontrados!</h3>
            <p>Não existem registros para o mês selecionado.</p>
          </div>

          {publications.map((value, index) => (
            <TributesEntry
              key={index}
              title={value.Titulo}
              call={value.Chamada}
              href={`/${accessType}/conteudo/${value.Codigo}`}
              imgURL={value.Nome_Arquivo}
            />
          ))}
        </div>
      </div>

      <globalComponents.Footer />
    </>
  );
}
