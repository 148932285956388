import api from "../../../../services/api";

import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";

import AgendaBlock from "./components/AgendaBlock";

import "./styles.css";

export default function Agenda() {
  const { accessType } = useParams();
  const [data, setData] = useState([
    {
      Titulo: "",
      Subtitulo: "",
      Codigo: null,
    },
  ]);
  const [emptyAgenda, setEmptyAgenda] = useState(false);

  useEffect(() => {
    if (emptyAgenda) {
      document.getElementById("empty_agenda").removeAttribute("hidden");
      document.getElementById("agenda_button").setAttribute("disabled", "true");
      document.getElementById("agenda_button").classList.add("disabled");
    } else if (!emptyAgenda) {
      document.getElementById("empty_agenda").setAttribute("hidden", "true");
      document.getElementById("agenda_button").removeAttribute("disabled");
      document.getElementById("agenda_button").classList.remove("disabled");
    }
  }, [emptyAgenda]);

  useEffect(() => {
    async function fetchData() {
      await api
        .get("/eventos")
        .then((response) => {
          setEmptyAgenda(false);
          setData(response.data);
        })
        .catch((err) => {
          setEmptyAgenda(true);
          setData([]);
          console.log(err);
        });
    }
    fetchData();
  }, []);

  function getCourseShortDate(subtitle) {
    if (subtitle.includes(" de ")) {
      const daysString = subtitle.split(" de ").shift();
      const monthShort = subtitle.split(" de ")[1].slice(0, 3).toUpperCase();

      return `${daysString} <br /> ${monthShort}`;
    } else if (subtitle.split("-").length > 1) {
      return subtitle.replace("-", "<br /> - <br />");
    }

    return subtitle;
  }

  return (
    <div className="agendaHome">
      <div className="agendaHome__title">
        <p>Próximos eventos</p>
      </div>

      <div className="agendaHome__lista">
        <div id="empty_agenda" className="empty_agenda">
          <h4>Não existem eventos programados</h4>
        </div>

        {data.map((value, index) => (
          <AgendaBlock
            key={index}
            date={getCourseShortDate(value.Subtitulo)}
            title={value.Titulo}
            code={value.Codigo}
          />
        ))}
      </div>

      <button
        id="agenda_button"
        className="button"
        onClick={() => {
          window.location.pathname = `/${accessType}/eventos`;
        }}
      >
        Ver agenda completa
      </button>
    </div>
  );
}
