import api from "../../../services/api";

import React, { useState, useEffect } from "react";

import * as globalComponents from "../../../components";

import "../styles.css";

export default function Informations() {
  const [data, setData] = useState({
    Titulo: "",
    Subtitulo: "",
    Chamada: "",
    Data_Publicacao: "",
    Nome_Arquivo: "",
    Materia: "",
  });

  useEffect(() => {
    window.scroll(0, 0);
    async function fetchData() {
      await api
        .get("/conteudo/241")
        .then((response) => {
          setData(response.data);
        })
        .catch((err) => {
          console.log(err);
        });
    }
    fetchData();
  }, []);

  return (
    <>
      <globalComponents.Header />

      <globalComponents.Breadcrumbs
        title="Informações e Notícias sobre o Crefito-2"
        dir={[]}
      />

      <div className="container">
        <div className="content-wrapper">
          <globalComponents.PageTitle
            title={data.Titulo}
            subtitle={data.Subtitulo}
            span={data.Data_Publicacao}
          />

          <div
            className="content-text"
            dangerouslySetInnerHTML={{ __html: data.Materia }}
          ></div>
        </div>
      </div>

      <globalComponents.Footer />
    </>
  );
}
