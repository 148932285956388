import React from "react";

import "./styles.css";

export default function Author() {
  return (
    <div className="author_signature">
      <a
        href="http://www.go2web.com.br/"
        target="_blank"
        rel="noopener noreferrer"
      >
        Desenvolvido por Go2web
      </a>
    </div>
  );
}
