import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import ReactGA from "react-ga";

import AccessButton from "../../components/AccessButton";

import "../styles.css";

import imageLogoWhite from "../../images/logo_branca_crefito2.png";
import imageWork from "../../images/work.png";
import imageUser from "../../images/user.png";

function Login() {
  // Google Analytics
  useEffect(() => {
    ReactGA.pageview("/login");
  });

  return (
    <div className="login">
      <div className="login__container">
        <div className="login__logo">
          <img src={imageLogoWhite} alt="" />
        </div>

        <div className="login__text">
          <h1>Bem-vindo!</h1>

          <p>Escolha o tipo de acesso para prosseguir</p>
        </div>

        <div className="tipo__login">
          <Link className="login_user" to="/home_profissional">
            <AccessButton
              type="Profissional"
              image={imageWork}
              message="ACESSO"
            />
          </Link>

          <Link className="login_user" to="/home">
            <AccessButton
              type="Não Profissional"
              image={imageUser}
              message="ACESSO"
            />
          </Link>
        </div>
      </div>
    </div>
  );
}

export default Login;
