import React from "react";

import * as globalComponents from "../../../../components";
import ContactUsForm from "./components/ContactUsForm";

import "../styles.css";

export default function ContactUs() {
  window.scroll(0, 0);
  return (
    <>
      <globalComponents.Header />

      <globalComponents.Breadcrumbs title="Fale Conosco" dir={[]} />

      <div className="container">
        <globalComponents.PageTitle title="Fale Conosco" />

        <div className="denuncia--container__wrapper">
          <div className="denuncia--content">
            <ContactUsForm />
          </div>
        </div>
      </div>

      <globalComponents.Footer />
    </>
  );
}
