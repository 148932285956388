import React from "react";
import { useParams } from "react-router-dom";

import * as globalComponents from "../../../../components";
import ListEntry from "../../components/ListEntry";

import "../styles.css";

export default function ReportTypes() {
  const { accessType } = useParams();
  const dir = [
    {
      title: "Fiscalização",
      url: `/${accessType}/fiscalizacao`,
    },
  ];

  window.scroll(0, 0);

  return (
    <>
      <globalComponents.Header />

      <globalComponents.Breadcrumbs title="Denúncias" dir={dir} />

      <div className="container">
        <div className="title__publications">
          <strong>Denúncias</strong>

          <ListEntry
            title={"Denúncias Identificadas"}
            href={`/${accessType}/fiscalizacao/denuncias/denuncias_identificadas`}
          />

          <ListEntry
            title={"Denúncias Anônimas"}
            href={`/${accessType}/fiscalizacao/denuncias/denuncias_anonimas`}
          />
        </div>
      </div>

      <globalComponents.Footer />
    </>
  );
}
