import React from "react";

import BoardCard from "../BoardCard";

import "./styles.css";

export default function BoardRow({ members, type }) {
  return (
    <div className={`board_row ${members.length === 4 ? "" : "not_full"}`}>
      {members.map((value, index) => (
        <BoardCard
          key={index}
          name={value.Titulo}
          type={type}
          img={value.Nome_Arquivo}
        />
      ))}
    </div>
  );
}
