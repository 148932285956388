import React, { useState } from "react";
import { Link } from "react-router-dom";

import "./styles.css";

export default function DermatofunctionalCard({
  title,
  description,
  href,
  internal,
  disabled,
}) {
  const [isDescriptionHidden, setIsDescriptionHidden] = useState(true);

  return (
    <div
      className={`dermatofunctional_card ${disabled ? "disabled" : ""} ${
        !isDescriptionHidden ? "expanded" : ""
      }`}
    >
      <div className="main_content">
        <p className="title">{title}</p>
        <div className="actions">
          {!disabled ? (
            internal ? (
              <Link className={`link ${disabled ? "disabled" : ""}`} to={href}>
                Acessar
              </Link>
            ) : (
              <a
                className={`link ${disabled ? "disabled" : ""}`}
                target="_blank"
                rel="noopener noreferrer"
                href={href}
              >
                Acessar
              </a>
            )
          ) : (
            <button className={`link ${disabled ? "disabled" : ""}`} disabled>
              Acessar
            </button>
          )}
          <button
            className="expand"
            onClick={() => {
              setIsDescriptionHidden(!isDescriptionHidden);
            }}
          >
            <svg
              width="18"
              height="10"
              viewBox="0 0 18 10"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M0.866699 1.65962L2.5696 0L9.42459 6.68076L16.2796 0L17.9825 1.65962L9.42459 10L0.866699 1.65962Z"
                fill="black"
              />
            </svg>
          </button>
        </div>
      </div>
      <p className="description" hidden={isDescriptionHidden}>
        {description}
      </p>
    </div>
  );
}
