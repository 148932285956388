import api from "../../../../../../services/api";

import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";

import "./styles.css";

export default function ReportsForm() {
  const [profile, setProfile] = useState("");
  const [actuation, setActuation] = useState("");
  const [formFiles, setFormFiles] = useState(null);
  const { register, handleSubmit } = useForm();

  async function onSubmit(data) {
    const userForm = {
      profile: data.profile === "outro" ? data.other_profile : data.profile,
      actuation:
        data.actuation === "outro" ? data.other_actuation : data.actuation,
      subject: data.subject,
      name: data.name,
      email: data.email,
      cel: data.cel,
      message: data.message,
      files: formFiles,
    };
    const submit = document.getElementById("submit");

    submit.value = "Enviando...";
    submit.classList.add("disabled");
    submit.setAttribute("disabled", true);

    try {
      const result = await api.post("/form/denuncias", userForm);
      if (!alert(result.data.message)) {
        window.scroll(0, 0);
        window.location.reload();
      }
    } catch (err) {
      if (!alert("Ocorreu um erro! Tente novamente mais tarde.")) {
        window.scroll(0, 0);
        submit.value = "Enviar";
        submit.classList.remove("disabled");
        submit.removeAttribute("disabled");
      }
    }
  }

  useEffect(() => {
    const element = document.getElementById("other_profile");
    if (profile === "outro") {
      element.toggleAttribute("disabled");
      element.toggleAttribute("hidden");
    } else {
      element.setAttribute("disabled", "true");
      element.setAttribute("hidden", "true");
    }
  }, [profile]);

  useEffect(() => {
    const element = document.getElementById("other_actuation");
    if (actuation === "outro") {
      element.toggleAttribute("disabled");
      element.toggleAttribute("hidden");
    } else {
      element.setAttribute("disabled", "true");
      element.setAttribute("hidden", "true");
    }
  }, [actuation]);

  function DisableFileUpload() {
    const submit = document.getElementById("submit");

    submit.value = "Carregando Arquivos...";
    submit.classList.add("disabled");
    submit.setAttribute("disabled", true);
  }

  function EnableFileUpload() {
    const submit = document.getElementById("submit");

    submit.value = "Enviar";
    submit.classList.remove("disabled");
    submit.removeAttribute("disabled");
  }

  function ClearFileUpload() {
    document.getElementById("files").value = null;
  }

  return (
    <div className="denuncia__form">
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="form__title">
          <p>Formulário de Denúncias</p>
        </div>

        <div className="form__options">
          <div className="option__column">
            <p>Selecione seu perfil</p>
            <ul>
              <li>
                <input
                  {...register("profile", { required: true })}
                  type="radio"
                  value="profissional"
                  onChange={(e) => {
                    setProfile(e.target.value);
                  }}
                />
                Profissional
              </li>
              <li>
                <input
                  {...register("profile", { required: true })}
                  type="radio"
                  value="acadêmico"
                  onChange={(e) => {
                    setProfile(e.target.value);
                  }}
                />
                Acadêmico
              </li>
              <li>
                <input
                  {...register("profile", { required: true })}
                  type="radio"
                  value="outro"
                  onChange={(e) => {
                    setProfile(e.target.value);
                  }}
                />
                Outro
              </li>
            </ul>
          </div>

          <div className="option__column">
            <p>Selecione sua área</p>
            <ul>
              <li>
                <input
                  {...register("actuation", { required: true })}
                  type="radio"
                  value="fisioterapia"
                  onChange={(e) => {
                    setActuation(e.target.value);
                  }}
                />
                Fisioterapia
              </li>
              <li>
                <input
                  {...register("actuation", { required: true })}
                  type="radio"
                  value="terapia ocupacional"
                  onChange={(e) => {
                    setActuation(e.target.value);
                  }}
                />
                Terapia Ocupacional
              </li>
              <li>
                <input
                  {...register("actuation", { required: true })}
                  type="radio"
                  value="outro"
                  onChange={(e) => {
                    setActuation(e.target.value);
                  }}
                />
                Outro
              </li>
            </ul>
          </div>
        </div>

        <div className="form__group">
          <div className="form__group--half">
            <input
              id="other_profile"
              {...register("other_profile", { required: true })}
              type="text"
              placeholder="Informe seu perfil"
              defaultValue=""
              hidden
              disabled
            />
            <input
              id="other_actuation"
              {...register("other_actuation", { required: true })}
              type="text"
              placeholder="Informe sua área"
              defaultValue=""
              hidden
              disabled
            />
          </div>
        </div>

        <div className="form__divisor"></div>

        <div className="form__group">
          <label htmlFor="assunto">Informações:</label>
          <select
            id="assunto"
            {...register("subject", { required: true })}
            defaultValue="Denúncia"
          >
            <option value="Denúncia">Denúncia</option>
          </select>
          <input
            {...register("name")}
            type="text"
            placeholder="Nome completo"
          />
          <div className="form__group--half">
            <input
              {...register("email")}
              type="email"
              placeholder="Endereço de E-Mail"
            />
            <input {...register("cel")} type="tel" placeholder="Telefone" />
          </div>
          <label htmlFor="message">Denúncia:</label>
          <textarea
            {...register("message", { required: true })}
            id="message"
            cols="30"
            rows="10"
            placeholder="Mensagem"
          ></textarea>

          <div className="form-group files">
            <label htmlFor="files">Envio de arquivo:</label>

            <p className="form__observation">
              <strong>Observação:</strong> em caso de múltiplos arquivos, envie
              uma pasta ".zip" contendo todos.
            </p>

            <input
              id="files"
              {...register("files")}
              type="file"
              className="form-control"
              multiple=""
              onChange={async function uploadFiles(e) {
                const uploadData = new FormData();

                DisableFileUpload();
                uploadData.append(
                  `file`,
                  e.target.files[0],
                  e.target.files[0].name
                );

                if (e.target.files[0].size < 90000000) {
                  try {
                    const result = await api.post("/upload", uploadData);
                    if (result.data.success) {
                      setFormFiles(result.data.uploadedFiles);
                      EnableFileUpload();
                    } else if (result.data.message.code) {
                      alert(result.data.message.code);
                      ClearFileUpload();
                      EnableFileUpload();
                    }
                  } catch (err) {
                    if (
                      !alert("Ocorreu um erro! Tente novamente mais tarde.")
                    ) {
                      window.scroll(0, 0);
                      ClearFileUpload();
                      EnableFileUpload();
                    }
                  }
                } else {
                  alert("Arquivo deve ser menor que 90 MB!");
                  ClearFileUpload();
                  EnableFileUpload();
                }
              }}
            />
          </div>
          <input id="submit" className="button" type="submit" value="Enviar" />
        </div>
      </form>
    </div>
  );
}
