import React from "react";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";

import "./styles.css";

export default function CustomAccordion({ data }) {
  return (
    <div className="accordion__body">
      <Accordion className="accordion" allowZeroExpanded={true}>
        {data.map((value, index) => (
          <AccordionItem key={index} className="card">
            <AccordionItemHeading
              className="card-header"
              style={
                index % 2 === 0
                  ? { background: "var(--secondary-color)" }
                  : { background: "" }
              }
            >
              <AccordionItemButton className="card-header-button">
                {value.Titulo}
              </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel className="card-body">
              <p dangerouslySetInnerHTML={{ __html: value.Materia }}></p>
            </AccordionItemPanel>
          </AccordionItem>
        ))}
      </Accordion>
    </div>
  );
}
