import api from "../../services/api";
import { useIsOverflown } from "../../hooks/useIsOverflown";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";

import * as globalComponents from "../../components";

import "./styles.css";

import PerksClubBanner from "./images/perks-club-banner.png";
import ViagemIcon from "./images/icons/viagem-icon.svg";
import EducacaoIcon from "./images/icons/educacao-icon.svg";
import ServicosIcon from "./images/icons/servicos-icon.svg";
import ProdutosEEquipamentosDeSaudeIcon from "./images/icons/produtos-e-equipamentos-de-saude-icon.svg";
import AutomovelIcon from "./images/icons/automovel-icon.svg";
import SaudeIcon from "./images/icons/saude-icon.svg";
import FinanceiroIcon from "./images/icons/financeiro-icon.svg";
import SegurosIcon from "./images/icons/seguros-icon.svg";
import EditalDeChamamentoIcon from "./images/icons/edital-de-chamamento-icon.svg";
import Placeholder from "./images/placeholder.png";
import ArrowLeft from "./images/icons/arrow-left.png";
import ArrowRight from "./images/icons/arrow-right.png";
// import { set } from "react-ga";

const removeSpecialCharactersAndSpaces = (string) => {
  return string.replace(/[^a-zA-Z0-9]/g, "").toLowerCase();
};

const getIcon = (perkType) => {
  switch (perkType) {
    case "Viagem":
      return ViagemIcon;
    case "Educação":
      return EducacaoIcon;
    case "Serviços":
      return ServicosIcon;
    case "Produtos e Equipamentos de Saúde":
      return ProdutosEEquipamentosDeSaudeIcon;
    case "Automóvel":
      return AutomovelIcon;
    case "Saúde":
      return SaudeIcon;
    case "Financeiro":
      return FinanceiroIcon;
    case "Seguros":
      return SegurosIcon;
    default:
      return null;
  }
};

const PerksClubMenuButton = ({ perk, handleClick }) => {
  return (
    <div className="menu-button" onClick={handleClick} title={perk.perkType}>
      <div className="menu-button-image-container">
        <img src={getIcon(perk.perkType)} alt={perk.perkType} />
      </div>

      <p>{perk.perkType}</p>
    </div>
  );
};

const BecomePartnerButton = ({ accessType }) => {
  return (
    <Link
      className="menu-button partner"
      title="Edital de Chamamento"
      to={`/${accessType}/licitacoes/3077`}
    >
      <div className="menu-button-image-container partner">
        <img src={EditalDeChamamentoIcon} alt="Edital de Chamamento" />
      </div>

      <p>Edital de Chamamento</p>
    </Link>
  );
};

const PerksClubContentTitle = ({ perk }) => {
  return (
    <div className="content-title">
      <p>{`${perk.perkType}`.toUpperCase()}</p>
      <img src={getIcon(perk.perkType)} alt={perk.perkType} />
    </div>
  );
};

const PerksClubContentCard = ({ perk, handleClick }) => {
  const { accessType } = useParams();
  const discountString = perk.Subtitulo || "descontos de até 10%*";
  const discountArray = discountString.replace(/\*/g, "").split(" ");
  const discountValue = discountArray.pop();
  const discount = discountArray.join(" ");

  return (
    <div className="content-card">
      <div className="image-container">
        <div className="image-filter"></div>
        <img src={perk.Nome_Arquivo || Placeholder} alt={perk.Titulo} />
      </div>

      <div className="card-text">
        <h3 className="title">{perk.Titulo}</h3>

        <div className="separator"></div>

        <p className="discount">
          {discount} <span>{discountValue}</span>*
        </p>

        <p className="terms">{perk.Chamada}</p>
      </div>

      <div className="button-container">
        <Link className="button" to={`/${accessType}/conteudo/${perk.Codigo}`}>
          saiba mais
        </Link>
      </div>
    </div>
  );
};

// const PerksClubCardExpand = ({ perk }) => {
//   return (
//     <div id="expand" className="card-expand">
//       <div className="image-container">
//         <img src={perk.Nome_Arquivo || null} alt={perk.Titulo} />
//       </div>

//       <div className="text-container">
//         <h3 className="title">{perk.Titulo}</h3>

//         <p
//           className="content"
//           dangerouslySetInnerHTML={{ __html: perk.Materia }}
//         ></p>

//         <div className="separator"></div>

//         <p className="terms">{perk.Chamada}</p>
//       </div>
//     </div>
//   );
// };

export default function Club() {
  const { accessType } = useParams();
  const [perks, setPerks] = useState([]);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [menuId, setMenuId] = React.useState(null);
  const [content, setContent] = useState();
  // const [expand, setExpand] = useState();
  const contentCardsContainerRef = React.useRef();
  const isContentCardsOverflown = useIsOverflown(
    contentCardsContainerRef,
    (isOverflowFromCallback) => {
      return isOverflowFromCallback;
    }
  );

  useEffect(() => {
    window.scroll(0, 0);
    async function fetchPerks() {
      await api
        .get("/clube_de_vantagens")
        .then((res) => {
          setPerks(res.data);
        })
        .catch((err) => {
          console.log(err);
        });
    }
    fetchPerks();
  }, []);

  const handleClick = (event) => {
    setMenuId(event.currentTarget.id);
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setMenuId(null);
    setAnchorEl(null);
  };

  return (
    <>
      <globalComponents.Header />

      <div className="perks-club-container">
        <div className="banner-container">
          <img
            className="banner"
            src={PerksClubBanner}
            alt="Clube de Vantagens: sua anuidade de volta com mais benefícios para você. Aproveite os descontos exclusivos para fisioterapeutas e terapeutas ocupacionais em diversos produtos e serviços!"
          />
          <div className="banner-filter"></div>
        </div>

        <div className="menu-header">
          <ul className="menu-header-buttons-container">
            {perks.map((perk, index) => {
              const id = removeSpecialCharactersAndSpaces(perk.perkType);
              const open = id === menuId ? Boolean(anchorEl) : false;

              return (
                <div key={index}>
                  <Button
                    id={id}
                    aria-controls={open ? "dropdown-menu" : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? "true" : undefined}
                    onClick={handleClick}
                  >
                    <p id={id} className="menu-header-button">
                      {perk.perkType}
                    </p>
                  </Button>
                  <Menu
                    id={`${id}-menu`}
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    MenuListProps={{
                      "aria-labelledby": `${id}`,
                    }}
                    className="menu-list"
                  >
                    {perk.perks.map((link, key) => (
                      <MenuItem
                        className="menu-item"
                        id={link.id}
                        key={key}
                        title={link.Titulo}
                      >
                        <Link
                          className="button"
                          to={`/${accessType}/conteudo/${link.Codigo}`}
                        >
                          {link.Titulo}
                        </Link>
                      </MenuItem>
                    ))}
                  </Menu>
                </div>
              );
            })}
          </ul>
        </div>

        <div className="menu">
          <div className="menu-buttons-container">
            {perks.map((perk, index) => (
              <PerksClubMenuButton
                perk={perk}
                key={index}
                handleClick={() => {
                  setContent(perk);
                  const elem = document.getElementById("content");

                  if (elem) {
                    elem.scrollIntoView({
                      behavior: "smooth",
                    });
                  }
                }}
              />
            ))}
            <BecomePartnerButton />
          </div>

          {/* <div className="become-partner-button-container">
            <BecomePartnerButton />
          </div> */}
        </div>

        <div id="content" className="content-container">
          {content && <PerksClubContentTitle perk={content} />}

          {content && (
            <>
              <div className="content-carousel">
                <span
                  className="arrow left"
                  hidden={!isContentCardsOverflown}
                  onClick={() => {
                    contentCardsContainerRef.current.scrollLeft -= 340;
                  }}
                >
                  <img src={ArrowLeft} alt="Arraste para a esquerda" />
                </span>

                <div
                  className="content-cards-container"
                  ref={contentCardsContainerRef}
                >
                  {content.perks.map((perk, index) => (
                    <PerksClubContentCard
                      perk={perk}
                      key={index}
                      // handleClick={() => {
                      //   setExpand(perk);
                      //   const elem = document.getElementById("expand");
                      //   elem?.scrollIntoView({
                      //     behavior: "smooth",
                      //   });
                      // }}
                    />
                  ))}
                </div>

                <span
                  className="arrow right"
                  hidden={!isContentCardsOverflown}
                  onClick={() => {
                    contentCardsContainerRef.current.scrollLeft += 340;
                  }}
                >
                  <img src={ArrowRight} alt="Arraste para a direita" />
                </span>
              </div>
              <p
                className="scroll-observation"
                hidden={!isContentCardsOverflown}
              >
                Role a barra para ver todos os parceiros
              </p>
            </>
          )}

          {/* {expand && <PerksClubCardExpand perk={expand} />} */}
        </div>
      </div>

      <globalComponents.Footer />
    </>
  );
}
