import React from "react";
import { Link } from "react-router-dom";

import "./styles.css";

export default function ListEntry({ title, call, href, image }) {
  return (
    <Link className="publications_block" to={href}>
      {image && (
        <div className="publication-image">
          <img src={image} alt={title} />
        </div>
      )}
      <div className="publication-content">
        <p className="title" dangerouslySetInnerHTML={{ __html: title }}></p>
        <p className="call" dangerouslySetInnerHTML={{ __html: call }}></p>
      </div>
    </Link>
  );
}
