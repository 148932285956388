import React from "react";
import { useParams } from "react-router-dom";

import MostSearchedServiceCard from "./components/MostSearchedServiceCard";

import "./styles.css";

export default function MostSearchedServices({ type }) {
  function checkBigTitle(title) {
    return title.length >= 55 ? "true" : "false";
  }

  const { accessType } = useParams();
  const services = [Array(type === "Profissional" ? 8 : 4).fill({})];

  if (type === "Profissional") {
    services[0] = {
      service: "Serviços Online",
      subtitle: "Profissionais, empresas e consultórios",
      href: `/${accessType}/servicos-online`,
      external: "false",
      isDisabled: false,
    };
    services[1] = {
      service: "2º via de anuidade",
      subtitle: "Esqueceu de pagar?",
      href: `/${accessType}/anuidade`,
      external: "false",
      isDisabled: false,
    };
    services[2] = {
      service: "Capacitações e Cursos",
      subtitle: "Capacitações e Cursos do Crefito-2",
      href: `/${accessType}/capacitacoes-e-cursos`,
      external: "false",
      isDisabled: false,
    };
    services[3] = {
      service: "Câmaras técnicas e Comissões",
      subtitle: "Comissões em atividade",
      href: `/${accessType}/comissoes_e_camaras_tecnicas`,
      external: "false",
      isDisabled: false,
    };
    services[4] = {
      service: "Sede e Subsedes",
      subtitle: "Localize a mais próxima de você",
      href: `/${accessType}/conteudo/4111`,
      external: "false",
      isDisabled: false,
    };
    services[5] = {
      service: "Fiscalização",
      subtitle: "Acompanhe as ações",
      href: `/${accessType}/fiscalizacao`,
      external: "false",
      isDisabled: false,
    };
    services[6] = {
      service: "Aplicativo Crefito-2",
      subtitle: "Passo a passo para utilização",
      href: `/${accessType}/conteudo/4783`,
      external: "false",
      isDisabled: false,
    };
    services[7] = {
      service: "Clube de Vantagens",
      subtitle: "Benefícios exclusivos pra você",
      href: `/${accessType}/clube_de_vantagens`,
      external: "false",
      isDisabled: false,
    };
  } else {
    services[0] = {
      service: "Consulta",
      subtitle: "Processos e protocolos",
      href: "https://www.crefito2.com.br/spw/consultacadastral/ConsultarProcessosPublico.aspx",
      external: "true",
      isDisabled: false,
    };
    services[1] = {
      service: "Crefito-2",
      subtitle: "Informações e notícias",
      href: `/${accessType}/informacoes_e_noticias`,
      external: "false",
      isDisabled: false,
    };
    services[2] = {
      service: "Cadastro",
      subtitle: "Profissionais e empresas",
      href: "https://www.crefito2.com.br/spw/consultacadastral/TelaConsultaPublicaCompleta.aspx",
      external: "true",
      isDisabled: false,
    };
    services[3] = {
      service: "Confirmação de Veracidade",
      subtitle: "Certidões e Declarações",
      href: "https://www.crefito2.com.br/spw/consultacadastral/ConfirmaVeracidadePublica.aspx",
      external: "true",
      isDisabled: false,
    };
  }

  return (
    <div
      className="container horizontalBreak"
      style={{ paddingTop: "50px", paddingBottom: "50px", marginTop: "0" }}
    >
      <div className="destaques_bloco--titulos mais-procurados">
        <h2>Mais Procurados</h2>
        <p>O que você precisa com mais facilidade</p>
      </div>

      <div className="row">
        {services.map((value, index) => (
          <MostSearchedServiceCard
            key={index}
            service={value.service}
            subtitle={value.subtitle}
            href={value.href}
            external={value.external}
            bigTitle={checkBigTitle(value.service)}
            isDisabled={value.isDisabled}
          />
        ))}
      </div>
    </div>
  );
}
