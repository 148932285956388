import React from "react";
import { Link, useParams } from "react-router-dom";

import "./styles.css";

export default function AgendaBlock({ date, title, code }) {
  const { accessType } = useParams();

  return (
    <Link to={`/${accessType}/conteudo/${code}`}>
      <div className="agendaHome__block">
        <div className="agendaHome__data">
          <p dangerouslySetInnerHTML={{ __html: date }}></p>
        </div>
        <div className="agendaHome__descricao">
          <p>{title}</p>
        </div>
      </div>
    </Link>
  );
}
