import api from "../../../../../../services/api";

import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";

import "./styles.css";

export default function ContactUsForm() {
  const subjectOptions = ["Dúvida", "Sugestão", "Crítica", "Reclamação"];
  const [profile, setProfile] = useState("");
  const [actuation, setActuation] = useState("");
  const { register, handleSubmit } = useForm();

  async function onSubmit(data) {
    const userForm = {
      profile: data.profile === "outro" ? data.other_profile : data.profile,
      actuation:
        data.actuation === "outro" ? data.other_actuation : data.actuation,
      subject: data.subject,
      name: data.name,
      email: data.email,
      cel: data.cel,
      message: data.message,
    };

    try {
      const result = await api.post("/form/fale-conosco", userForm);
      if (!alert(result.data.message)) {
        window.scroll(0, 0);
        window.location.reload();
      }
    } catch (err) {
      if (!alert("Ocorreu um erro! Tente novamente mais tarde.")) {
        window.scroll(0, 0);
        window.location.reload();
      }
    }
  }

  useEffect(() => {
    const element = document.getElementById("other_profile");
    if (profile === "outro") {
      element.toggleAttribute("disabled");
      element.toggleAttribute("hidden");
    } else {
      element.setAttribute("disabled", "true");
      element.setAttribute("hidden", "true");
    }
  }, [profile]);

  useEffect(() => {
    const element = document.getElementById("other_actuation");
    if (actuation === "outro") {
      element.toggleAttribute("disabled");
      element.toggleAttribute("hidden");
    } else {
      element.setAttribute("disabled", "true");
      element.setAttribute("hidden", "true");
    }
  }, [actuation]);

  return (
    <div className="denuncia__form">
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="form__title">
          <p>Fale Conosco</p>
        </div>

        <div className="form__options">
          <div className="option__column">
            <p>Selecione seu perfil</p>
            <ul>
              <li>
                <input
                  {...register("profile", { required: true })}
                  type="radio"
                  value="profissional"
                  onChange={(e) => {
                    setProfile(e.target.value);
                  }}
                />
                Profissional
              </li>
              <li>
                <input
                  {...register("profile", { required: true })}
                  type="radio"
                  value="acadêmico"
                  onChange={(e) => {
                    setProfile(e.target.value);
                  }}
                />
                Acadêmico
              </li>
              <li>
                <input
                  {...register("profile", { required: true })}
                  type="radio"
                  value="outro"
                  onChange={(e) => {
                    setProfile(e.target.value);
                  }}
                />
                Outro
              </li>
            </ul>
          </div>

          <div className="option__column">
            <p>Selecione sua área</p>
            <ul>
              <li>
                <input
                  {...register("actuation", { required: true })}
                  type="radio"
                  value="fisioterapia"
                  onChange={(e) => {
                    setActuation(e.target.value);
                  }}
                />
                Fisioterapia
              </li>
              <li>
                <input
                  {...register("actuation", { required: true })}
                  type="radio"
                  value="terapia ocupacional"
                  onChange={(e) => {
                    setActuation(e.target.value);
                  }}
                />
                Terapia Ocupacional
              </li>
              <li>
                <input
                  {...register("actuation", { required: true })}
                  type="radio"
                  value="outro"
                  onChange={(e) => {
                    setActuation(e.target.value);
                  }}
                />
                Outro
              </li>
            </ul>
          </div>
        </div>

        <div className="form__group">
          <div className="form__group--half">
            <input
              id="other_profile"
              {...register("other_profile", { required: true })}
              type="text"
              placeholder="Informe seu perfil"
              defaultValue=""
              hidden
              disabled
            />
            <input
              id="other_actuation"
              {...register("other_actuation", { required: true })}
              type="text"
              placeholder="Informe sua área"
              defaultValue=""
              hidden
              disabled
            />
          </div>
        </div>

        <div className="form__divisor"></div>

        <div className="form__group">
          <select
            id="assunto"
            {...register("subject", { required: true })}
            defaultValue=""
          >
            <option value="" disabled hidden>
              Selecione um assunto
            </option>
            {subjectOptions.map((value, index) => (
              <option key={index} value={value}>
                {value}
              </option>
            ))}
          </select>
          <input
            {...register("name", { required: true })}
            type="text"
            placeholder="Nome completo"
          />
          <div className="form__group--half">
            <input
              {...register("email", { required: true })}
              type="email"
              placeholder="Endereço de E-Mail"
            />
            <input
              {...register("cel", { required: true })}
              type="tel"
              placeholder="Telefone"
            />
          </div>
          <textarea
            {...register("message", { required: true })}
            id="message"
            cols="30"
            rows="10"
            placeholder="Mensagem"
          ></textarea>

          <input id="submit" className="button" type="submit" value="Enviar" />
        </div>
      </form>
    </div>
  );
}
