import React from "react";

import "./styles.css";

export default function CustomCard({ type, title, img, href }) {
  return (
    <a
      className="col-lg-3 col-md-6 col-sm-6"
      target="_blank"
      rel="noopener noreferrer"
      href={href}
    >
      <div className="link__card">
        <div className="link__card--img">
          <img src={img} alt="" />
        </div>
        <div className="link__card--info">
          <div className="name__link">
            <p>
              <strong>{type}</strong> <br />
              {title}
            </p>
          </div>

          <div className="link__link">
            <p>Acessar link</p>
          </div>
        </div>
      </div>
    </a>
  );
}
