import api from "../../../../services/api";

import React, { useState, useEffect } from "react";
import { Carousel } from "react-responsive-carousel";

import SwiperSlide from "./components/SwiperSlide";

import "./styles/carousel.min.css";
import "./styles/styles.css";

import imageBannerHome from "./images/bannerHome_White.png";

export default function HomeCarousel() {
  const [data, setData] = useState([
    {
      Titulo: "",
      Codigo: "",
      Nome_Arquivo: imageBannerHome,
      Chamada: "",
      Link: "",
    },
    {
      Titulo: "",
      Codigo: "",
      Nome_Arquivo: imageBannerHome,
      Chamada: "",
      Link: "",
    },
  ]);

  useEffect(() => {
    async function fetchData() {
      await api
        .get("/carrossel")
        .then((response) => {
          setData(response.data);
        })
        .catch((err) => {
          console.log(err);
        });
    }
    fetchData();
  }, []);

  return (
    <div className="carousel_home">
      <div className="swiper-container">
        <div className="swiper-wrapper">
          <Carousel
            infiniteLoop={true}
            autoPlay={true}
            interval={5000}
            showThumbs={false}
            showArrows={false}
            showStatus={false}
          >
            {data.map((value, index) => (
              <SwiperSlide
                key={index}
                // title={value.Titulo}
                // call={value.Chamada}
                img={value.Nome_Arquivo}
                href={value.Link}
              />
            ))}
          </Carousel>
        </div>
      </div>
    </div>
  );
}
