import api from "../../../../services/api";

import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";

import * as globalComponents from "../../../../components";

import "../../styles.css";

export default function ProfessionalOrientationsScene() {
  const { accessType, professionalOrientationID } = useParams();

  const dir = [
    {
      title: "Serviços Online",
      url: `/${accessType}/servicos-online`,
    },
    {
      title: "Orientações para Profissionais",
      url: `/${accessType}/servicos-online/orientacoes-profissionais`,
    },
  ];

  const [data, setData] = useState({
    Titulo: null,
    Subtitulo: null,
    Chamada: null,
    Data_Publicacao: null,
    Nome_Arquivo: null,
    Materia: null,
  });

  useEffect(() => {
    window.scroll(0, 0);
    async function fetchData() {
      await api
        .get(`/conteudo/${professionalOrientationID.split("_").pop()}`)
        .then((response) => {
          setData(response.data);
        })
        .catch((err) => {
          console.log(err);
        });
    }
    fetchData();
  }, [professionalOrientationID]);

  return (
    <>
      <globalComponents.Header />

      <globalComponents.Breadcrumbs title="Orientação" dir={dir} />

      <div className="container">
        <div className="content-wrapper">
          <globalComponents.PageTitle
            title={data.Titulo}
            subtitle={data.Subtitulo}
            span={data.Data_Publicacao}
          />

          <div
            className="content-text"
            dangerouslySetInnerHTML={{ __html: data.Materia }}
          ></div>
        </div>
      </div>

      <globalComponents.Footer />
    </>
  );
}
