import api from "../../../../services/api";

import React, { useState, useEffect } from "react";

import Magazine from "./components/Magazine";

import "./styles.css";

export default function MagazinesList() {
  const [magazines, setMagazines] = useState([]);

  useEffect(() => {
    async function fetchMagazines() {
      await api
        .get("/revistas")
        .then((response) => {
          setMagazines(response.data.reverse());
        })
        .catch((err) => {
          console.log(err);
          document.getElementById("coming_soon").removeAttribute("hidden");
        });
    }
    fetchMagazines();
  }, []);

  return (
    <div
      className="container"
      style={{ paddingBottom: "40px", marginTop: "0" }}
    >
      <div className="row">
        <div className="col"></div>
        <div className="revista-home">
          <div className="revista-home-texto">
            <strong>Revista Dinâmica</strong>
            <p>Acompanhe aqui os lançamentos da revista do Crefito-2.</p>
          </div>

          <div className="revista-home-revistas">
            <div className="thumbsForRevista">
              <p id="coming_soon" className="coming_soon" hidden>
                Em breve!
              </p>
              {magazines.map((value, index) => (
                <Magazine
                  key={index}
                  thumb={value.capa}
                  title={value.titulo}
                  href={value.link}
                />
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
