import React from "react";

import "./styles.css";

export default function AccessButton({ type, image, message }) {
  return (
    <div className="access_button_content">
      <img src={image} alt="" />

      <p>
        <span>{message}</span> <br />
        {type}
      </p>
    </div>
  );
}
