import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";

import "./styles.css";

export default function NationalSalaryFloorForm() {
  const { register, handleSubmit } = useForm();
  const [name, setName] = useState("");
  const [cpf, setCpf] = useState("");
  const crefitoAddress = "informatica@crefito2.org.br";
  const emailAddressesList = [
    "dep.abiliobrunini@camara.leg.br",
    "dep.anapaulalima@camara.leg.br",
    "dep.anapimentel@camara.leg.br",
    "dep.antoniobrito@camara.leg.br",
    "dep.augustopupio@camara.leg.br;",
    "dep.betopreto@camara.leg.br",
    "dep.brunofarias@camara.leg.br",
    "dep.clodoaldomagalhaes@camara.leg.br",
    "dep.celiosilveira@camara.leg.br",
    "dep.danielsoranz@camara.leg.br",
    "dep.detinha@camara.leg.br",
    "dep.dimasgadelha@camara.leg.br",
    "dep.dorinaldomalafaia@camara.leg.br",
    "dep.dr.benjamim@camara.leg.br",
    "dep.dr.fernandomaximo@camara.leg.br",
    "dep.dr.francisco@camara.leg.br",
    "dep.dr.frederico@camara.leg.br",
    "dep.dr.jaziel@camara.leg.br",
    "dep.dr.luizovando@camara.leg.br",
    "dep.dr.zachariascalil@camara.leg.br",
    "dep.eduardovelloso@camara.leg.br",
    "dep.fernandapessoa@camara.leg.br",
    "dep.flaviamorais@camara.leg.br",
    "dep.geovaniadesa@camara.leg.br",
    "dep.geraldoresende@camara.leg.br",
    "dep.ismaelalexandrino@camara.leg.br",
    "dep.izaarruda@camara.leg.br",
    "dep.jandirafeghali@camara.leg.br",
    "dep.jefersonrodrigues@camara.leg.br",
    "dep.jorgesolla@camara.leg.br",
    "dep.julianacardoso@camara.leg.br",
    "dep.juniormano@camara.leg.br",
    "dep.lucianovieira@camara.leg.br",
    "dep.leoprates@camara.leg.br",
    "dep.marxbeltrao@camara.leg.br",
    "dep.meireserafim@camara.leg.br",
    "dep.miltonvieira@camara.leg.br",
    "dep.osmarterra@camara.leg.br",
    "dep.paulofoletto@camara.leg.br",
    "dep.pinheirinho@camara.leg.br",
    "dep.rafaelsimoes@camara.leg.br",
    "dep.robertomonteiro@camara.leg.br",
    "dep.rodrigogambale@camara.leg.br",
  ];

  function validateCPF(cpf) {
    var Soma;
    var Resto;
    Soma = 0;
    if (cpf === "00000000000") return false;

    for (let i = 1; i <= 9; i++)
      Soma = Soma + parseInt(cpf.substring(i - 1, i)) * (11 - i);
    Resto = (Soma * 10) % 11;

    if (Resto === 10 || Resto === 11) Resto = 0;
    if (Resto !== parseInt(cpf.substring(9, 10))) return false;

    Soma = 0;
    for (let i = 1; i <= 10; i++)
      Soma = Soma + parseInt(cpf.substring(i - 1, i)) * (12 - i);
    Resto = (Soma * 10) % 11;

    if (Resto === 10 || Resto === 11) Resto = 0;
    if (Resto !== parseInt(cpf.substring(10, 11))) return false;
    return true;
  }

  function validateName(name) {
    const nameRegex = /^[a-zA-ZÀ-ú ]+$/;
    return nameRegex.test(name);
  }

  useEffect(() => {
    showForm(validateCPF(cpf) && validateName(name));
  }, [name, cpf]);

  function showForm(condition) {
    const submit = document.getElementById("submit");

    if (condition) {
      submit.removeAttribute("disabled");
      submit.classList.remove("disabled");
    } else {
      submit.setAttribute("disabled", true);
      submit.classList.add("disabled");
    }
  }

  async function onSubmit(data) {
    const { name, cpf } = data;

    const emailAddressesPool = [...emailAddressesList];
    const emailMessage = `Excelentíssimo(a) Deputado(a), os Fisioterapeutas e Terapeutas Ocupacionais são profissionais de saúde essenciais na reabilitação e recuperação da nossa população e, neste momento, temos salários extremamente desvalorizados e indignos de uma formação de nível superior.\nA pandemia tornou ainda mais evidente a nossa relevância na recuperação dos pacientes críticos e dos doentes que permaneceram com alguma sequela. Somos profissionais que resgatamos a qualidade de vida e, que através do nosso plano terapêutico, permitimos o retorno as atividades laborais dos nossos pacientes. Portanto, contamos com o seu apoio para votar a favor ao PL1731/2021, que trata do Piso Salarial Nacional dos Fisioterapeutas e Terapeutas Ocupacionais, e que foi apensado ao PL988/2015 na Câmara dos Deputados. O PL1731 já foi aprovado no senado federal.\nSendo assim, contamos com a sensibilidade de todos para alcançarmos o mínimo de dignidade profissional para nossa categoria. \n\nNome: ${name}\nCPF: ${cpf}\n\n`;
    let emailAddressesListString = "";

    for (let i = 0; i < 9; i++) {
      const randomIndex = Math.floor(Math.random() * emailAddressesPool.length);
      const randomAddress = emailAddressesPool.splice(randomIndex, 1);

      emailAddressesListString += `${randomAddress}`;

      if (i < 8) {
        emailAddressesListString += ",";
      }
    }

    let emailHref = `mailto:${emailAddressesListString}?subject=PL1731/2021&body=${encodeURI(
      emailMessage
    )}&bcc=${crefitoAddress}`;

    window.location.href = emailHref;
  }

  return (
    <div className="denuncia__form">
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="form__title">
          <p>Formulário de Solicitação de Piso Salarial Nacional</p>
        </div>

        <div className="form__group">
          <p>
            Excelentíssimo(a) Deputado(a), os Fisioterapeutas e Terapeutas
            Ocupacionais são profissionais de saúde essenciais na reabilitação e
            recuperação da nossa população e, neste momento, temos salários
            extremamente desvalorizados e indignos de uma formação de nível
            superior. A pandemia tornou ainda mais evidente a nossa relevância
            na recuperação dos pacientes críticos e dos doentes que permaneceram
            com alguma sequela. Somos profissionais que resgatamos a qualidade
            de vida e, que através do nosso plano terapêutico, permitimos o
            retorno as atividades laborais dos nossos pacientes. Portanto,
            contamos com o seu apoio para votar a favor ao PL1731/2021, que
            trata do Piso Salarial Nacional dos Fisioterapeutas e Terapeutas
            Ocupacionais, e que foi apensado ao PL988/2015 na Câmara dos
            Deputados. O PL1731 já foi aprovado no senado federal. Sendo assim,
            contamos com a sensibilidade de todos para alcançarmos o mínimo de
            dignidade profissional para nossa categoria.
          </p>

          <p>
            O botão "Enviar" só estará liberado após o correto preenchimento das
            informações abaixo.
          </p>
        </div>

        <div id="validationGroup" className="form__group">
          <input
            id="name"
            {...register("name", { required: true })}
            type="text"
            className="validationInput"
            placeholder="Nome completo*"
            defaultValue=""
            onChange={(e) => {
              setName(e.target.value);
            }}
          />
          <input
            id="cpf"
            {...register("cpf", { required: true })}
            type="text"
            maxLength={11}
            className="validationInput"
            placeholder="CPF* (somente números)"
            defaultValue=""
            onChange={(e) => {
              setCpf(e.target.value);
            }}
          />

          <input
            id="submit"
            className="button disabled"
            type="submit"
            value="Enviar"
            disabled
          />
        </div>
      </form>
    </div>
  );
}
