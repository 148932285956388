import React from "react";

import * as globalComponents from "../../components";
import MagazinesList from "./components/MagazinesList";

import "./styles.css";

export default function Magazines() {
  return (
    <>
      <globalComponents.Header />

      <globalComponents.Breadcrumbs title={"Revistas"} dir={[]} />

      <div className="container">
        <MagazinesList />
      </div>

      <globalComponents.Footer />
    </>
  );
}
