import api from "../../../../../services/api";

import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";

import * as globalComponents from "../../../../../components";
import ListEntry from "../../../components/ListEntry";

import "../../styles.css";

export default function EstablishmentOrientations() {
  window.scroll(0, 0);
  const { accessType } = useParams();

  const dir = [
    {
      title: "Serviços Online",
      url: `/${accessType}/servicos-online`,
    },
  ];

  const [data, setData] = useState({
    empresas: [],
    consultorios: [],
  });

  useEffect(() => {
    async function fetchData() {
      await api
        .get("/empresas/orientacoes")
        .then((response) => {
          setData(response.data);
        })
        .catch((err) => {
          console.log(err);
        });
    }
    fetchData();
  }, []);

  return (
    <>
      <globalComponents.Header />

      <globalComponents.Breadcrumbs
        title="Orientações para Empresas e Consultórios"
        dir={dir}
      />

      <div className="container">
        <div className="title__publications">
          <strong>Empresas (Pessoas Jurídicas)</strong>

          {data.empresas.map((value, index) => (
            <ListEntry
              key={index}
              title={value.Titulo}
              call={value.Subtitulo}
              href={`/${accessType}/servicos-online/orientacoes-empresas-e-consultorios/${value.Titulo.toLowerCase()
                .normalize("NFD")
                .replace(/[\u0300-\u036f]|/g, "")
                .replace(/[^a-zA-Z0-9\s]/g, "")
                .replace(/ /g, "_")}_${value.Codigo}`}
            />
          ))}
        </div>
        <div className="title__publications">
          <div className="strong_container">
            Consultórios (Autônomos Localizados)
          </div>

          {data.consultorios.map((value, index) => (
            <ListEntry
              key={index}
              title={value.Titulo}
              call={value.Subtitulo}
              href={`/${accessType}/servicos-online/orientacoes-empresas-e-consultorios/${value.Titulo.toLowerCase()
                .normalize("NFD")
                .replace(/[\u0300-\u036f]|/g, "")
                .replace(/[^a-zA-Z0-9\s]/g, "")
                .replace(/ /g, "_")}_${value.Codigo}`}
            />
          ))}
        </div>
      </div>

      <globalComponents.Footer />
    </>
  );
}
