import React from "react";
import { useParams } from "react-router-dom";

import * as globalComponents from "../../../../components";
import UserRegistrationUpdateForm from "./components/UserRegistrationUpdateForm";

import "../styles.css";

export default function UserRegistrationUpdate() {
  const { accessType } = useParams();
  const dir = [
    {
      title: "Serviços Online",
      url: `/${accessType}/servicos-online`,
    },
  ];

  return (
    <>
      <globalComponents.Header />

      <globalComponents.Breadcrumbs
        title="Atualização Cadastral para Profissionais"
        dir={dir}
      />

      <div className="container">
        <globalComponents.PageTitle title="Atualização Cadastral para Profissionais" />
        <p>
          Para atualizar seus dados cadastrais, poderá alterá-los de imediato,
          acessando o sistema on line por este
          <a
            href="https://www.crefito2.com.br/spw/consultacadastral/TelaLogin.aspx"
            target="_blank"
            rel="noopener noreferrer"
            className="external-link"
          >
            {" "}
            link.
          </a>
        </p>
        <p>
          <strong>Importante:</strong> Este serviço atende a alteração de
          Contato (endereço, telefone e email). Para mudança de nome, deverá
          seguir os procedimentos contidos neste
          <a
            href="http://www.crefito2.gov.br/home_profissional/servicos-online/orientacoes-profissionais/mudanca_de_nome_219"
            target="_blank"
            rel="noopener noreferrer"
            className="external-link"
          >
            {" "}
            link.
          </a>
        </p>
        <p>
          No campo “Comentário”, indique que dados sofreram mudança (endereço,
          telefone ou e-mail) e selecione a opção “Enviar”. Basta preencher o
          formulário abaixo.
        </p>
        <div className="denuncia--container__wrapper">
          <div className="denuncia--content">
            <UserRegistrationUpdateForm />
          </div>
        </div>
      </div>

      <globalComponents.Footer />
    </>
  );
}
