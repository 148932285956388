import React from "react";
import { Link } from "react-router-dom";

import SearchBox from "../../../../components/SearchBox";

import "./styles.css";

export default function HomeBreadcrumb({ type }) {
  return (
    <div
      className="col breadcrumbHome"
      style={{ display: "flex", justifyContent: "space-between" }}
    >
      <Link to="/">
        <h1 className="acesso">
          Acesso <br /> <strong>{type}</strong>
        </h1>
      </Link>

      <SearchBox />
    </div>
  );
}
