import React from "react";
import { Link } from "react-router-dom";

import "./styles.css";

export default function InProgressCard({ title, span, href }) {
  return (
    <Link to={href}>
      <div className="licitacao_block">
        <p dangerouslySetInnerHTML={{ __html: title }}></p>

        <div className="licitacao_info">
          <span dangerouslySetInnerHTML={{ __html: span }}></span>
        </div>
      </div>
    </Link>
  );
}
