import React from "react";

import "./styles.css";

export default function BoardCard({ name, type, img }) {
  return (
    <div className="card_gestao">
      <div className="gestao-photo">
        <img src={img} alt="" />
      </div>
      <div className="gestao-name">
        <p>{type}</p>
        <p>{name}</p>
      </div>
    </div>
  );
}
