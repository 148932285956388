import React from "react";
import { Link } from "react-router-dom";
import { useParams } from "react-router-dom";

import "./styles.css";

export default function FooterContacts() {
  const { accessType } = useParams();

  return (
    <div className="footer__contato">
      <p>Contato</p>

      <ul>
        <li>
          <span>Endereço:</span> Rua Félix da Cunha, 41 - Tijuca, Rio de Janeiro
          - RJ, CEP 20.260-300.
        </li>
        <li>
          <span>Horário de funcionamento:</span> 09h às 16h, de segunda a
          sexta-feira.
        </li>
        <li>
          <span>Email:</span>{" "}
          <a
            className="footer__contato__link"
            href="mailto:faleconosco@crefito2.org.br"
          >
            faleconosco@crefito2.org.br
          </a>
        </li>
        <li>
          <span>Telefone WhatsApp:</span>{" "}
          <a className="footer__contato__link" href="tel:+552121692169">
            (21) 2169-2169
          </a>
        </li>
      </ul>

      <Link className="faq_link" to={`/${accessType}/fale_conosco`}>
        Fale Conosco
      </Link>
    </div>
  );
}
