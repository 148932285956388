import React from "react";
import { Link } from "react-router-dom";
import ArrowCircleRightOutlinedIcon from "@mui/icons-material/ArrowCircleRightOutlined";

import "./styles.css";

export default function MostSearchedServiceCard({
  service,
  subtitle,
  href,
  external,
  bigTitle,
  isDisabled,
}) {
  const serviceClassName =
    bigTitle === "true"
      ? "card--servicoPrincipal--title big-title"
      : "card--servicoPrincipal--title";

  switch (external) {
    default:
      return (
        <Link
          className="col-md-3 horizontalR"
          to={isDisabled ? "#" : href}
          style={{ marginBottom: "50px" }}
          disabled={isDisabled}
        >
          <div
            className={`card--servicoPrincipal ${
              isDisabled ? "disabled" : null
            }`}
          >
            <div className={serviceClassName}>{service}</div>
            <div className="card--servicoPrincipal--subtitle">
              <div className="separator"></div>
              <p>{subtitle}</p>
            </div>
            <div className="card--servicoPrincipal--button">
              {isDisabled ? "EM BREVE" : "ACESSAR"}{" "}
              <ArrowCircleRightOutlinedIcon />
            </div>
          </div>
        </Link>
      );
    case "true":
      return (
        <a
          className="col-md-3 horizontalR"
          disabled={isDisabled}
          target="_blank"
          rel="noopener noreferrer"
          href={isDisabled ? "/" : href}
          style={{ marginBottom: "50px" }}
        >
          <div
            className={`card--servicoPrincipal ${
              isDisabled ? "disabled" : null
            }`}
          >
            <div className={serviceClassName}>{service}</div>
            <div className="card--servicoPrincipal--subtitle">
              <div className="separator"></div>
              <p>{subtitle}</p>
            </div>
            <div className="card--servicoPrincipal--button">
              {isDisabled ? "EM BREVE" : "ACESSAR"}
              <ArrowCircleRightOutlinedIcon />
            </div>
          </div>
        </a>
      );
  }
}
