import React, { useState, useEffect } from "react";
import qs from "qs";

import constants from "../../shared/constants";
import spotifyApi from "../../services/spotifyApi";
import spotifyToken from "../../services/spotifyToken";

import * as globalComponents from "../../components";

import "./styles.css";

export default function Podcast() {
  const [token, setToken] = useState();
  const [episodes, setEpisodes] = useState([]);

  useEffect(() => {
    async function fetchToken() {
      const data = {
        grant_type: "client_credentials",
      };

      const config = {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/x-www-form-urlencoded",
        },
        auth: {
          username: constants.spotifyClientID,
          password: constants.spotifySecretKey,
        },
      };

      await spotifyToken
        .post("/token", qs.stringify(data), config)
        .then((response) => {
          setToken(response.data.access_token);
        })
        .catch((err) => {
          console.log(err);
        });
    }
    fetchToken();
  }, []);

  useEffect(() => {
    async function fetchEpisodes() {
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          market: "BR",
          limit: "10",
        },
      };

      await spotifyApi
        .get(`shows/${constants.crefito2PodcastSpotifyID}/episodes`, config)
        .then((response) => {
          setEpisodes(response.data.items);
        })
        .catch((err) => {
          console.log(err);
        });
    }
    fetchEpisodes();
  }, [token]);

  return (
    <>
      <globalComponents.Header />

      <globalComponents.Breadcrumbs title="Podcast" dir={[]} />

      <div className="container">
        <div className="episodes_grid">
          {episodes.map((value, index) => (
            <iframe
              title={`PodcastIframe_${index}`}
              src={`https://open.spotify.com/embed-podcast/episode/${value.id}`}
              width="100%"
              height="232"
              frameBorder="0"
              allowtransparency="true"
              allow="encrypted-media"
            ></iframe>
          ))}
        </div>
      </div>

      <globalComponents.Footer />
    </>
  );
}
