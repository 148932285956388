import React from "react";
import { useParams, Link } from "react-router-dom";

import * as globalComponents from "../../../../components";
import DermatofunctionalCard from "./components/DermatofunctionalCard";

import "../styles.css";
import "./styles.css";

export default function DigitalDocuments() {
  const { accessType } = useParams();
  const dermatofunctionalLinks = [
    {
      title: "PCDT-BIOESTIMULADOR DE COLÁGENO",
      description: "O procedimento é indicado para tratar flacidez de pele.",
      href: "https://crefito2.go2webdbm.com.br/arquivos/dermatofuncional/PCDT-BIOESTIMULADOR%20DE%20COL%C3%81GENO.pdf",
      internal: false,
      disabled: false,
    },
    {
      title: "PCDT-CRIOLIPÓLISE",
      description:
        "Gordura localizada em áreas como abdômen, flancos, coxas, braços, costas e região do sutiã.",
      href: "https://crefito2.go2webdbm.com.br/arquivos/dermatofuncional/PCDT-CRIOLIP%C3%93LISE.pdf",
      internal: false,
      disabled: false,
    },
    {
      title: "PCDT-INTRADERMOTERAPIA",
      description:
        "Descrever o procedimento de intradermoterapia na Dermatofuncional.",
      href: "https://crefito2.go2webdbm.com.br/arquivos/dermatofuncional/PCDT-INTRADERMOTERAPIA.pdf",
      internal: false,
      disabled: false,
    },
    {
      title: "PCDT-JATO DE PLASMA",
      description:
        "Guia para a realização do tratamento em pacientes que buscam melhorar a aparência da pele.",
      href: "https://crefito2.go2webdbm.com.br/arquivos/dermatofuncional/PCDT-JATO%20DE%20PLASMA.pdf",
      internal: false,
      disabled: false,
    },
    {
      title: "PCDT-MICRO VASOS PEIM",
      description:
        "Descrever o procedimento injetável para eliminação de telangiectasias e microvasos utilizando glicose.",
      href: "https://crefito2.go2webdbm.com.br/arquivos/dermatofuncional/PCDT-MICRO%20VASOS%20PEIM.pdf",
      internal: false,
      disabled: false,
    },
    {
      title: "PCDT-OZONIOTERAPIA INJETÁVEL",
      description: "Descrever o procedimento de Ozonioterapia injetável.",
      href: "https://crefito2.go2webdbm.com.br/arquivos/dermatofuncional/PCDT-OZONIOTERAPIA%20INJET%C3%81VEL.pdf",
      internal: false,
      disabled: false,
    },
    {
      title: "PCDT-OZONIOTERAPIA DERMATOFUNCIONAL",
      description:
        "Rejuvenescimento, acne, hipercromias, gordura localizada, fortalecimento capilar, celulite e flacidez.",
      href: "https://crefito2.go2webdbm.com.br/arquivos/dermatofuncional/PCDT-OZONIOTERAPIA%20DERMATFUNCIONAL.pdf",
      internal: false,
      disabled: false,
    },
    {
      title: "PCDT-PEELING QUIMICO",
      description:
        "Utilizado para o tratamento de hipercromia, rejuvenescimento, cicatrizes de acne, acne ativa e pele desidratada.",
      href: "https://crefito2.go2webdbm.com.br/arquivos/dermatofuncional/PCDT-PEELING%20QUIMICO.pdf",
      internal: false,
      disabled: false,
    },
    {
      title: "PCDT-PREENCHIMENTO COM ÁCIDO HIALURÔNICO",
      description:
        "Descrever o procedimento padrão para realização de preenchimento com ácido hialurônico.",
      href: "https://crefito2.go2webdbm.com.br/arquivos/dermatofuncional/PCDT-PREENCHIMENTO%20COM%20%C3%81CIDO%20HIALUR%C3%94NICO.pdf",
      internal: false,
      disabled: false,
    },
    {
      title: "PCDT-RADIOFREQUÊNCIA",
      description:
        "Objetivo descrever a aplicação da radiofrequência em diferentes indicações Dermatofuncionais.",
      href: "https://crefito2.go2webdbm.com.br/arquivos/dermatofuncional/PCDT-RADIOFREQU%C3%8ANCIA.pdf",
      internal: false,
      disabled: false,
    },
    {
      title: "PCDT-REGISTRO FOTOGRÁFICO",
      description:
        "Descrever o procedimento de registro fotográfico dos pacientes.",
      href: "https://crefito2.go2webdbm.com.br/arquivos/dermatofuncional/PCDT-REGISTRO%20FOTOGR%C3%81FICO.pdf",
      internal: false,
      disabled: false,
    },
    {
      title: "PCDT-SKINBOOSTER",
      description:
        "Técnica de hidratação e rejuvenescimento facial com o uso de ácido hialurônico ou mesclas de ativos na Dermatofuncional.",
      href: "https://crefito2.go2webdbm.com.br/arquivos/dermatofuncional/PCDT-SKINBOOSTER.pdf",
      internal: false,
      disabled: false,
    },
    {
      title: "PCDT-TOXINA BOTULINICA",
      description:
        "Descrever a aplicação de toxina botulínica tipo A, com o objetivo de tratar rugas dinâmicas e realizar miomodulação.",
      href: "https://crefito2.go2webdbm.com.br/arquivos/dermatofuncional/PCDT-TOXINA%20BOTULINICA.pdf",
      internal: false,
      disabled: false,
    },
    {
      title: "PCDT-ULTRASSOM CAVITAÇÃO",
      description:
        "Descrever a aplicação da radiofrequência em diferentes indicações Dermatofuncionais.",
      href: "https://crefito2.go2webdbm.com.br/arquivos/dermatofuncional/PCDT-ULTRASSOM%20CAVITA%C3%87%C3%83O.pdf",
      internal: false,
      disabled: false,
    },
    {
      title: "ANEXOS DO MANUAL DE ROTINAS E PROCEDIMENTOS EM DERMATOFUNCIONAL",
      description:
        "Anexar a certidão reponsabilidade técnica emitida pelo CREFITO2.",
      href: "https://crefito2.go2webdbm.com.br/arquivos/dermatofuncional/ANEXOS%20DOMANUAL%20DE%20ROTINAS%20E%20PROCEDIMENTOS%20EM%20DERMATOFUNCIONAL.pdf",
      internal: false,
      disabled: false,
    },
    {
      title: "MANUAL DE ROTINAS E PROCEDIMENTOS EM DERMATOFUNCIONAL",
      description:
        "Descrição de todos os documentos sanitários necessários para sua clínica ou consultório.",
      href: "https://crefito2.go2webdbm.com.br/arquivos/dermatofuncional/MANUAL%20DE%20ROTINAS%20E%20PROCEDIMENTOS%20EM%20DERMATOFUNCIONAL.pdf",
      internal: false,
      disabled: false,
    },
    {
      title: "PCDT- CORRENTE RUSSA",
      description:
        "Descrever o procedimento de Corrente Russa na Dermatofuncionanal.",
      href: "https://crefito2.go2webdbm.com.br/arquivos/dermatofuncional/PCDT-%20CORRENTE%20RUSSA.pdf",
      internal: false,
      disabled: false,
    },
    {
      title: "PCDT- DRENAGEM LINFÁTICA",
      description: "Descrever o procedimento de drenagem linfática corporal.",
      href: "https://crefito2.go2webdbm.com.br/arquivos/dermatofuncional/PCDT-%20DRENAGEM%20LINF%C3%81TICA.pdf",
      internal: false,
      disabled: false,
    },
    {
      title: "PCDT- ENDERMOTERAPIA",
      description:
        "Fornecendo informações sobre indicações, contraindicações, equipamentos de proteção individual (EPIs).",
      href: "https://crefito2.go2webdbm.com.br/arquivos/dermatofuncional/PCDT-%20ENDERMOTERAPIA.pdf",
      internal: false,
      disabled: false,
    },
    {
      title: "PCDT FIOS ESPICULADOS",
      description:
        "Descrever o procedimento de aplicação de fios de PDO espiculados para tratamento de flacidez da pele, rugas e lifting leve.",
      href: "https://crefito2.go2webdbm.com.br/arquivos/dermatofuncional/PCDT%20FIOS%20ESPICULADOS.pdf",
      internal: false,
      disabled: false,
    },
    {
      title: "PCDT- HIDROLIPOCLASIA NÃO ASPIRATIVA",
      description:
        "Descrever o procedimento de hidrolipoclasia não aspirativa na Dermatofuncional.",
      href: "https://crefito2.go2webdbm.com.br/arquivos/dermatofuncional/PCDT-%20HIDROLIPOCLASIA%20N%C3%83O%20ASPIRATIVA.pdf",
      internal: false,
      disabled: false,
    },
    {
      title: "PCDT- LASER CO² FRACIONADO",
      description:
        "Principais indicações e contraindicações, bem como os equipamentos de proteção individual e materiais necessários para sua realização.",
      href: "https://crefito2.go2webdbm.com.br/arquivos/dermatofuncional/PCDT-%20LASER%20CO%C2%B2%20FRACIONADO.pdf",
      internal: false,
      disabled: false,
    },
    {
      title: "PCDT- LUZ INTENSA PULSADA",
      description:
        "Descrever o uso da Luz Intensa Pulsada (LIP) para o tratamento de diferentes condições Dermatofuncionais.",
      href: "https://crefito2.go2webdbm.com.br/arquivos/dermatofuncional/PCDT-%20LUZ%20INTENSA%20PULSADA.pdf",
      internal: false,
      disabled: false,
    },
    {
      title: "PCDT- MICROAGULHAMENTO",
      description:
        "Descrever o procedimento de Microagulhamento com rolo manual e caneta automática.",
      href: "https://crefito2.go2webdbm.com.br/arquivos/dermatofuncional/PCDT-%20MICROAGULHAMENTO.pdf",
      internal: false,
      disabled: false,
    },
    {
      title: "PCDT- ULTRASSOM MICROFOCADO",
      description:
        "Melhorar a flacidez e aparência da pele, através de um estímulo térmico controlado que estimula a produção de colágeno e elastina.",
      href: "https://crefito2.go2webdbm.com.br/arquivos/dermatofuncional/PCDT-%20ULTRASSOM%20MICROFOCADO.pdf",
      internal: false,
      disabled: false,
    },
    {
      title: "PCDT-APLICAÇÃO INTRAMUSCULAR",
      description:
        "Descrever o procedimento de injeção intramuscular na Dermatofuncional.",
      href: "https://crefito2.go2webdbm.com.br/arquivos/dermatofuncional/PCDT-APLICA%C3%87%C3%83O%20INTRAMUSCULAR.pdf",
      internal: false,
      disabled: false,
    },
    {
      title:
        "PLANO DE GERENCIAMENTO DE RESÍDUOS SÓLIDOS DE SERVIÇOS DE SAÚDE PARA CLÍNICAS OU CONSULTÓRIOS",
      description:
        "Atendimento às normas sanitárias em clínicas e consultórios",
      href: `/${accessType}/conteudo/5410`,
      internal: true,
      disabled: false,
    },
  ];

  return (
    <>
      <globalComponents.Header />

      <div className="dermatofunctional_banner">
        <Link to={`/${accessType}/documentos_digitais`} className="button">
          Voltar
        </Link>

        <h1 className="title">
          Protocolos Clínicos e Diretrizes Terapêuticas de Dermatofuncional
        </h1>

        <p className="description">
          Cada Procedimento Operacional padrão de Dermatofuncional pode e deve
          ser adaptado e personalizável para o atendimento de cada profissional
        </p>
      </div>

      <div className="dermatofunctional_cards_container">
        <div className="dermatofunctional_cards_grid">
          {dermatofunctionalLinks.map((value, index) => (
            <DermatofunctionalCard
              key={index}
              title={value.title}
              description={value.description}
              href={value.href}
              internal={value.internal}
              disabled={value.disabled}
            />
          ))}
        </div>
      </div>

      <globalComponents.Footer />
    </>
  );
}
