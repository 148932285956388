import api from "../../../../services/api";

import React, { useState, useEffect } from "react";

import { useParams } from "react-router-dom";

import * as globalComponents from "../../../../components";

import "../../styles.css";

export default function InstitutionalCenters() {
  const { accessType } = useParams();

  const dir = [
    {
      title: "Crefito2",
      url: `/${accessType}/crefito2`,
    },
  ];

  const [data, setData] = useState({
    resume: "",
    centers: [
      {
        Titulo: "",
        Materia: "",
      },
      {
        Titulo: "",
        Materia: "",
      },
      {
        Titulo: "",
        Materia: "",
      },
      {
        Titulo: "",
        Materia: "",
      },
    ],
  });

  useEffect(() => {
    async function fetchData() {
      await api
        .get("/polos-institucionais")
        .then((response) => {
          const data = {
            resume: response.data.resume,
            centers: response.data.centers.reverse(),
          };

          setData(data);
        })
        .catch((err) => {
          console.log(err);
        });
    }
    fetchData();
  }, []);

  return (
    <>
      <globalComponents.Header />

      <globalComponents.Breadcrumbs
        title="Polos de Representação Institucional"
        dir={dir}
      />

      <div className="container">
        <globalComponents.PageTitle title="Polos de Representação Institucional" />

        <div
          className="content-text"
          dangerouslySetInnerHTML={{ __html: data.resume }}
        ></div>

        <p>
          <strong>Obs:</strong> O polo 6 contempla as subsedes de Petrópolis e
          Teresópolis.
        </p>

        <globalComponents.CustomAccordion data={data.centers} />
      </div>

      <globalComponents.Footer />
    </>
  );
}
