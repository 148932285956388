// import api from "../../../../services/api";

import React from "react";
// import { useState, useEffect } from "react";
// import { Carousel } from "react-responsive-carousel";

import Footer from "../../../../components/Footer";
// import LocationCard from "./components/LocationCard";

import "./styles.css";

export default function HomeFooter() {
  // const [data, setData] = useState([
  //   {
  //     Titulo: "",
  //     Subtitulo: "",
  //     Chamada: "",
  //     Link: "",
  //   },
  //   {
  //     Titulo: "",
  //     Subtitulo: "",
  //     Chamada: "",
  //     Link: "",
  //   },
  // ]);

  // useEffect(() => {
  //   async function fetchData() {
  //     await api
  //       .get("/enderecos")
  //       .then((response) => {
  //         setData(response.data);
  //       })
  //       .catch((err) => {
  //         console.log(err);
  //       });
  //   }
  //   fetchData();
  // }, []);

  return (
    <>
      {/* <div className="container-fluid ondeEstamosContainer p-0">
        <div className="home__ondeEstamos">
          <div className="container">
            <div className="row">
              <div className="col-md-4 col-sm-12">
                <div className="hold__ondeEstamos">
                  <div>
                    <h3>
                      Onde <br /> estamos
                    </h3>
                    <p>Saiba onde encontrar as sedes e subsedes do Crefito-2</p>
                  </div>
                </div>
              </div>

              <div className="col-md-8 col-sm-12 ondeEstamosId">
                <Carousel
                  infiniteLoop={true}
                  showArrows={true}
                  showIndicators={false}
                  showThumbs={false}
                  showStatus={false}
                  swipeable={false}
                >
                  {data.map((value, index) => (
                    <div key={index} className="row card-wrapper">
                      <LocationCard
                        title={value.Titulo}
                        subtitle={value.Subtitulo}
                        call={value.Chamada}
                        href={value.Link}
                      />
                    </div>
                  ))}
                </Carousel>
              </div>
            </div>
          </div>
        </div>
      </div> */}
      <Footer />
    </>
  );
}
