import React from "react";
import { Link } from "react-router-dom";

import "./styles.css";

import imageDefaultThumb from "./images/default_thumb.jpg";

export default function SearchResultsEntry({
  title,
  description,
  href,
  imgURL,
  date,
}) {
  const CurrentDate = date.toString();
  const year = CurrentDate.slice(0, 4);
  const month = CurrentDate.slice(4, 6);
  const day = CurrentDate.slice(6, 8);

  return (
    <Link className="publications_block search-results" to={href}>
      <div className="search-results-image">
        <img src={imgURL === null ? imageDefaultThumb : imgURL} alt="" />
      </div>
      <div className="search-results-content">
        <p className="title" dangerouslySetInnerHTML={{ __html: title }}></p>
        <p
          className="call"
          dangerouslySetInnerHTML={{
            __html: `${description} - Publicação: ${day}/${month}/${year}`,
          }}
        ></p>
      </div>
    </Link>
  );
}
