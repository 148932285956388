import api from "../../../../services/api";

import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";

import * as globalComponents from "../../../../components";
import ListEntry from "../../components/ListEntry";

import "../styles.css";

export default function MenuFromAPI({ title, apiRoute }) {
  const { accessType } = useParams();

  const [data, setData] = useState([
    {
      Codigo: "",
      Descricao: "",
    },
    {
      Codigo: "",
      Descricao: "",
    },
    {
      Codigo: "",
      Descricao: "",
    },
    {
      Codigo: "",
      Descricao: "",
    },
    {
      Codigo: "",
      Descricao: "",
    },
  ]);

  useEffect(() => {
    async function fetchData() {
      api
        .get(apiRoute)
        .then((response) => {
          setData(response.data);
        })
        .catch((err) => {
          console.log(err);
        });
    }
    fetchData();
  }, [apiRoute]);

  return (
    <>
      <globalComponents.Header />

      <globalComponents.Breadcrumbs title={title} dir={[]} />

      <div className="container">
        <div className="title__publications">
          <strong>{title}</strong>

          {data.map((value, index) => (
            <ListEntry
              key={index}
              title={value.Descricao}
              href={`/${accessType}${apiRoute}/${value.Descricao.toLowerCase()
                .normalize("NFD")
                .replace(/[\u0300-\u036f]/g, "")
                .replace(/[^a-zA-Z0-9\s]/g, "")
                .replace(/ /g, "_")}`}
            />
          ))}
        </div>
      </div>

      <globalComponents.Footer />
    </>
  );
}
