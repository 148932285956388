import React from "react";
import { Link, useParams } from "react-router-dom";

import SearchBox from "../SearchBox";

import "./styles.css";

export default function Breadcrumbs({ dir, title }) {
  const { accessType } = useParams();

  return (
    <div className="container">
      <nav aria-label="breadcrumb">
        <ol className="breadcrumb">
          <div
            className="holdLI"
            style={{ display: "flex", alignItems: "center" }}
          >
            <li className="breadcrumb-item">
              <Link to={`/${accessType}`}>Home</Link>
            </li>

            {dir.map((value, index) => (
              <li key={index} className="breadcrumb-item">
                <Link to={value.url}>{value.title}</Link>
              </li>
            ))}

            <li
              className="breadcrumb-item active"
              aria-current="page"
              dangerouslySetInnerHTML={{ __html: title }}
            ></li>
          </div>
          <SearchBox />
        </ol>
      </nav>
    </div>
  );
}
