import React from "react";

import * as globalComponents from "../../../../components";

import "../../components/ListEntry/styles.css";
import "../styles.css";

export default function Annuity() {
  window.scroll(0, 0);

  return (
    <>
      <globalComponents.Header />

      <globalComponents.Breadcrumbs title="2ª Via de Anuidade" dir={[]} />

      <div className="container">
        <div className="title__publications">
          <strong>Emissão de 2ª Via de Anuidade</strong>
          <a
            className="publications_block"
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.crefito2.com.br/spw/consultacadastral/anuidadeexercicio.aspx"
          >
            <div className="publication-content">
              <p className="title">
                Emissão de 2ª Via de Anuidade do Exercício - Profissional
              </p>
              <p className="call"></p>
            </div>
          </a>

          <a
            className="publications_block"
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.crefito2.com.br/spw/consultacadastral/TelaLogin.aspx"
          >
            <div className="publication-content">
              <p className="title">
                Emissão de 2ª Via de Anuidade de Exercícios anteriores -
                Profissional
              </p>
              <p className="call"></p>
            </div>
          </a>

          <a
            className="publications_block"
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.crefito2.com.br/spw/consultacadastral/anuidadeexercicioempr.aspx"
          >
            <div className="publication-content">
              <p className="title">
                Emissão de 2ª Via de Anuidade do Exercício - Empresas e
                Consultórios
              </p>
              <p className="call"></p>
            </div>
          </a>

          <a
            className="publications_block"
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.crefito2.com.br/spw/consultacadastral/TelaLoginEmpresa.aspx"
          >
            <div className="publication-content">
              <p className="title">
                Emissão de 2ª Via de Anuidade de Exercícios anteriores -
                Empresas e Consultórios
              </p>
              <p className="call"></p>
            </div>
          </a>
        </div>
      </div>

      <globalComponents.Footer />
    </>
  );
}
