import api from "../../../../services/api";

import React from "react";
import { useForm } from "react-hook-form";

import newsletterIcon from "./images/newsletter_icon.png";

import "./styles.css";

export default function FooterNewsletter() {
  const { register, handleSubmit } = useForm();

  async function onSubmit(data) {
    const userForm = {
      email: data.email,
    };

    try {
      const result = await api.post(`/form/newsletter`, userForm);
      if (!alert(result.data.message)) {
        window.scroll(0, 0);
        window.location.reload();
      }
    } catch (err) {
      if (!alert("Preencha pelo menos um campo, por favor.")) {
        window.scroll(0, 0);
        window.location.reload();
      }
    }
  }

  return (
    <div className="newsletter">
      <img src={newsletterIcon} alt="Ícone de Newsletter" />

      <p>Newsletter Crefito-2</p>

      <span>
        Inscreva-se agora em nossa newsletter e receba dicas, oportunidades e
        atualizações exclusivas do Crefito-2!
      </span>

      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="campos">
          <input
            {...register("email")}
            type="email"
            placeholder="Endereço de E-Mail"
          />
        </div>

        <div className="boto">
          <input className="button" type="submit" value="Enviar"></input>
        </div>
      </form>
    </div>
  );
}
