import api from "../../services/api";

import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";

import * as globalComponents from "../../components";

import "./styles.css";

export default function ShowContent() {
  const { contentID } = useParams();
  const [data, setData] = useState([]);

  useEffect(() => {
    window.scroll(0, 0);
    async function fetchData() {
      await api
        .get(`/conteudo/${contentID}`)
        .then((response) => {
          setData(response.data);
        })
        .catch((err) => {
          setData({
            Titulo: "Conteúdo Não Encontrado!",
            Subtitulo:
              "Este conteúdo não foi encontrado em nossa base de dados.",
            Chamada: null,
            Data_Publicacao: null,
            Nome_Arquivo: null,
            Materia: null,
            Link: null,
          });
          console.log(err);
        });
    }
    fetchData();
  }, [contentID]);

  return (
    <>
      <globalComponents.Header />

      <globalComponents.Breadcrumbs title={"Matéria"} dir={[]} />

      <div className="container">
        <div id="common-content" className="content-wrapper">
          <globalComponents.PageTitle
            title={data.Titulo}
            subtitle={data.Subtitulo}
            span={data.Data_Publicacao}
          />

          <div className="content-image">
            {data.Link ? (
              <a href={data.Link}>
                <img src={data.Nome_Arquivo} alt="" />
              </a>
            ) : (
              <img src={data.Nome_Arquivo} alt="" />
            )}
          </div>

          <div
            className="content-text"
            dangerouslySetInnerHTML={{ __html: data.Materia }}
          ></div>
        </div>
      </div>

      <globalComponents.Footer />
    </>
  );
}
