import React from "react";

import * as globalComponents from "../../../../../components";
import AgeBasedDiscountResolutionForm from "./components/AgeBasedDiscountResolutionForm";

import "../../styles.css";

export default function AgeBasedDiscountResolution() {
  return (
    <>
      <globalComponents.Header />

      <globalComponents.Breadcrumbs
        title="Solicitação de Isenção de Anuidades por Idade e Tempo"
        dir={[]}
      />

      <div className="container">
        <globalComponents.PageTitle title="FORMULÁRIO PARA SOLICITAÇÃO DA ISENÇÃO DE PAGAMENTO DE ANUIDADES (IDADE E TEMPO DE CADASTRO)" />
        <p>
          <a
            href="https://www.coffito.gov.br/nsite/?p=30151"
            style={{ color: "var(--secondary-color)" }}
            target="_blank"
            rel="noopener noreferrer"
          >
            Resolução COFFITO Nº 598/2024
          </a>
          : Profissionais que completarem ou tenham 65 (sessenta e cinco) anos
          de idade e 30 (trinta) anos de exercício profissional, contínuo ou
          não, até o dia 31/12, do ano que antecede a anuidade gerada.
        </p>
        <div className="denuncia--container__wrapper">
          <div className="denuncia--content">
            <AgeBasedDiscountResolutionForm />
          </div>
        </div>
      </div>

      <globalComponents.Footer />
    </>
  );
}
