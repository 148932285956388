import React from "react";

import * as globalComponents from "../../../../components";
import NewsletterForm from "./components/NewsletterForm";

import "../styles.css";

export default function Newsletter() {
  return (
    <>
      <globalComponents.Header />

      <globalComponents.Breadcrumbs title="Newsletter" dir={[]} />

      <div className="container">
        <div className="denuncia--container__wrapper">
          <div className="denuncia--content">
            <NewsletterForm />
          </div>
        </div>
      </div>

      <globalComponents.Footer />
    </>
  );
}
