import React from "react";
import { useParams, useLocation } from "react-router-dom";
import { useForm } from "react-hook-form";

import "./styles.css";

export default function SearchBox() {
  const { accessType } = useParams();
  const { search, handleSubmit } = useForm();

  function onSubmit() {
    const search = document.getElementById("search").value;
    window.location = `/${accessType}/buscar?busca=${search}&pagina=1`;
  }

  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }

  let query = useQuery();

  return (
    <form className="search-form" onSubmit={handleSubmit(onSubmit)}>
      <input
        id="search"
        name="search"
        type="text"
        maxLength="30"
        placeholder="Pesquisa de Conteúdo"
        defaultValue={query.get("busca")}
        ref={search}
      />
      <span
        id="search-button"
        className="material-icons search-button"
        onClick={onSubmit}
      >
        search
      </span>
    </form>
  );
}
