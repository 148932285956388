import React from "react";
import { Link, useParams } from "react-router-dom";

import "./styles.css";

export default function PublicationCard({ type, imageThumb, title, code }) {
  const { accessType } = useParams();
  let classType;

  switch (type) {
    case "highlight":
      classType = "highlight";
      break;
    case "secondary":
      classType = "secondary";
      break;
    case "mobile":
      classType = "";
      break;
    default:
      classType = "";
  }

  return (
    <div className={classType === "" ? "col-md-3" : ""}>
      <div className={`noticia_block ${classType}`}>
        <div className="publication-content">
          <div className="noticia_block-img">
            <img src={imageThumb} alt="" />
          </div>

          <div className="card_text">
            <div className="noticia_block-texto">
              <p dangerouslySetInnerHTML={{ __html: title }}></p>
            </div>

            <div className="noticia_block-button">
              <Link className="button" to={`/${accessType}/conteudo/${code}`}>
                Ler tudo
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
