import api from "../../../../../../services/api";
import { Link, useParams } from "react-router-dom";
import React, { useState, useEffect, useCallback } from "react";
import { useForm } from "react-hook-form";

import "./styles.css";

import professionalPictureExample from "../../images/professional_picture_example.jpeg";

export default function ProfessionalCardEmissionForm() {
  const { accessType } = useParams();
  const [professionalImage, setProfessionalImage] = useState(null);
  const [signatureImage, setSignatureImage] = useState(null);
  const [cep, setCep] = useState("");
  const [cepInfo, setCepInfo] = useState({
    cep: "",
    logradouro: "",
    complemento: "",
    bairro: "",
    localidade: "",
    uf: "",
    ibge: "",
    gia: "",
    ddd: "",
    siafi: "",
  });
  const [place, setPlace] = useState("");
  const [neighbourhood, setNeighbourhood] = useState("");
  const [city, setCity] = useState("");
  const [uf, setUf] = useState("");
  const { register, handleSubmit } = useForm();

  const cleanForm = useCallback(() => {
    document.getElementById("cep").value = "";
    document.getElementById("place").value = "";
    document.getElementById("neighbourhood").value = "";
    document.getElementById("city").value = "";
    document.getElementById("uf").value = "";
    disableAddress();
  }, []);

  async function onSubmit(data) {
    const userForm = {
      profile: data.profile,
      register: data.register,
      name: data.name,
      email: data.email,
      cel: data.cel,
      cep: data.cep,
      place: data.place,
      number: data.number,
      complement: data.complement,
      neighbourhood: data.neighbourhood,
      uf: data.uf,
      city: data.city,
      comment: data.comment,
      userInformationRequirement: data.userInformationRequirement,
      professionalImage,
      userImageRequirement: data.userImageRequirement,
      signatureImage,
      userSignatureRequirement: data.userSignatureRequirement,
      userAppRequirement: data.userAppRequirement,
      deliveryOffice: data.deliveryOffice,
    };

    const submit = document.getElementById("submit");

    submit.value = "Enviando...";
    submit.classList.add("disabled");
    submit.setAttribute("disabled", true);

    try {
      const result = await api.post("/form/emissao_carteira", userForm);
      if (!alert(result.data.message)) {
        window.scroll(0, 0);
        window.location.reload();
      }
    } catch (err) {
      if (!alert("Ocorreu um erro! Tente novamente mais tarde.")) {
        window.scroll(0, 0);
        submit.value = "Enviar";
        submit.classList.remove("disabled");
        submit.removeAttribute("disabled");
      }
    }
  }

  useEffect(() => {
    async function fetchCepInfo() {
      if (cep !== "") {
        await fetch(`https://viacep.com.br/ws/${cep}/json/`)
          .then((res) => res.json())
          .then((apiCepInfo) => {
            setCepInfo(apiCepInfo);
          });
      }
    }
    fetchCepInfo();
  }, [cep]);

  useEffect(() => {
    if (!cepInfo.erro) {
      setPlace(cepInfo.logradouro);
      setNeighbourhood(cepInfo.bairro);
      setUf(cepInfo.uf);
      setCity(cepInfo.localidade);
      document.getElementById("place").value = cepInfo.logradouro;
      document.getElementById("neighbourhood").value = cepInfo.bairro;
      document.getElementById("city").value = cepInfo.localidade;
      document.getElementById("uf").value = cepInfo.uf;
      document.getElementById("place").focus();
      document.getElementById("neighbourhood").focus();
      document.getElementById("city").focus();
      document.getElementById("uf").focus();
      document.getElementById("number").focus();
    } else {
      cleanForm();
      alert("CEP inválido. Por favor, digite um CEP válido");
    }
  }, [cepInfo, cleanForm]);

  function enableAddress() {
    document.getElementById("place").removeAttribute("disabled");
    document.getElementById("neighbourhood").removeAttribute("disabled");
    document.getElementById("city").removeAttribute("disabled");
    document.getElementById("uf").removeAttribute("disabled");
    document.getElementById("number").removeAttribute("disabled");
    document.getElementById("complement").removeAttribute("disabled");
  }

  function disableAddress() {
    document.getElementById("place").setAttribute("disabled", true);
    document.getElementById("neighbourhood").setAttribute("disabled", true);
    document.getElementById("city").setAttribute("disabled", true);
    document.getElementById("uf").setAttribute("disabled", true);
    document.getElementById("number").setAttribute("disabled", true);
    document.getElementById("complement").setAttribute("disabled", true);
  }

  function DisableSubmit() {
    const submit = document.getElementById("submit");

    submit.value = "Carregando Arquivos...";
    submit.classList.add("disabled");
    submit.setAttribute("disabled", true);
  }

  function EnableSubmit() {
    const submit = document.getElementById("submit");

    submit.value = "Enviar";
    submit.classList.remove("disabled");
    submit.removeAttribute("disabled");
  }

  return (
    <div className="denuncia__form">
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="form__title">
          <p>Emissão de Carteira Digital</p>
        </div>

        <div className="form__options">
          <div className="option__column">
            <p>Selecione sua categoria</p>
            <ul>
              <li>
                <input
                  {...register("profile", { required: true })}
                  type="radio"
                  value="fisioterapia"
                />
                Fisioterapia
              </li>
              <li>
                <input
                  {...register("profile", { required: true })}
                  type="radio"
                  value="terapia ocupacional"
                />
                Terapia Ocupacional
              </li>
            </ul>
          </div>
        </div>

        <div className="form__divisor"></div>

        <div className="form__group">
          <input
            {...register("register", { required: true })}
            type="text"
            placeholder="Número de registro"
          />
          <input
            {...register("name", { required: true })}
            type="text"
            placeholder="Nome completo"
          />
          <div className="form__group--half">
            <input
              {...register("email", { required: true })}
              type="email"
              placeholder="Endereço de E-Mail"
            />
            <input
              {...register("cel", { required: true })}
              type="tel"
              placeholder="Telefone"
            />
          </div>
        </div>

        <div className="form__divisor"></div>

        <div className="form__group">
          <label htmlFor="cep">CEP:</label>
          <input
            id="cep"
            {...register("cep", { required: true })}
            type="number"
            placeholder="CEP"
            onChange={(e) => {
              if (e.target.value.length < 8) {
                return;
              } else if (e.target.value.length > 8) {
                e.target.value = toString(e.target.value).slice(0, 8);
                return;
              } else {
                var cep = e.target.value.replace(/\D/g, "");

                if (cep !== null && cep !== "") {
                  var cepRegex = /^[0-9]{8}$/;

                  if (cepRegex.test(cep)) {
                    enableAddress();
                    setCep(cep);
                  } else {
                    cleanForm();
                    alert("Formato de CEP inválido.");
                  }
                } else {
                  cleanForm();
                }
              }
            }}
          />
          <label htmlFor="place">Endereço:</label>
          <input
            id="place"
            {...register("place", { required: true })}
            type="text"
            placeholder="Logradouro"
            disabled
            value={place}
          />
          <div className="form__group--half">
            <input
              id="number"
              {...register("number", { required: true })}
              type="text"
              placeholder="Número"
              disabled
            />
            <input
              id="complement"
              {...register("complement")}
              type="text"
              placeholder="Complemento"
              disabled
            />
          </div>
          <input
            id="neighbourhood"
            {...register("neighbourhood", { required: true })}
            type="text"
            placeholder="Bairro"
            disabled
            value={neighbourhood}
          />

          <div className="form__group--half">
            <input
              id="uf"
              {...register("uf", { required: true })}
              type="text"
              value={uf}
              placeholder="Estado (UF)"
              disabled
            />
            <input
              id="city"
              {...register("city", { required: true })}
              type="text"
              value={city}
              placeholder="Cidade"
              disabled
            />
          </div>

          <textarea
            {...register("comment", { required: false })}
            id="comment"
            cols="30"
            rows="10"
            placeholder="Comentário"
          ></textarea>

          <div className="user_authorization">
            <input
              id="userInformationRequirement"
              {...register("userInformationRequirement", { required: true })}
              type="checkbox"
              value="authorized"
            />
            <label htmlFor="userInformationRequirement">
              Declaro que conferi as informações acima inseridas e que são
              verdadeiras e corretas.
            </label>
          </div>
        </div>

        <div className="form__divisor"></div>

        <div className="form__group">
          <div className="form-group files">
            <label htmlFor="image">Anexar imagem da fotografia:</label>

            <input
              id="image"
              {...register("image", { required: true })}
              type="file"
              className="form-control"
              onChange={async function uploadFile(e) {
                if (
                  e.target.files[0].type !== "image/jpeg" &&
                  e.target.files[0].type !== "image/jpg"
                ) {
                  alert("Arquivo deve ser do tipo JPG!");
                  e.target.value = null;
                  return;
                }

                const uploadData = new FormData();

                DisableSubmit();
                uploadData.append(
                  `file`,
                  e.target.files[0],
                  e.target.files[0].name
                );

                if (e.target.files[0].size < 90000000) {
                  try {
                    const result = await api.post("/upload", uploadData);
                    if (result.data.success) {
                      setProfessionalImage(result.data.uploadedFiles);
                      EnableSubmit();
                    } else if (result.data.message.code) {
                      alert(result.data.message.code);
                      e.target.value = null;
                      EnableSubmit();
                    }
                  } catch (err) {
                    if (
                      !alert("Ocorreu um erro! Tente novamente mais tarde.")
                    ) {
                      window.scroll(0, 0);
                      e.target.value = null;
                      EnableSubmit();
                    }
                  }
                } else {
                  alert("Arquivo deve ser menor que 90 MB!");
                  e.target.value = null;
                  EnableSubmit();
                }
              }}
            />
          </div>

          <div className="attention">
            <h3>Atenção:</h3>
            <ul>
              <li>
                A foto identifica o profissional, portanto a fotografia deve ser
                tirada de frente contra fundo branco e com iluminação uniforme.
              </li>

              <li>
                O rosto e os ombros da pessoa fotografada devem etar enquadrados
                e centralizados. Os olhos devem estar abertos, visíveis e
                direcionados para a câmera.
              </li>

              <li>
                A fotografia deve ser realizada colorida e em alta definição e
                impressa em boa qualidade.
              </li>

              <li>
                O fotografado deve apresentar fisionomia neutra ou com um
                sorriso discreto, desde que em ambos os casos mantenha os lábios
                fechados, sem franzir o rosto.
              </li>

              <li>
                Fotografias com uso de óculos de sol, chapéu e de outros
                acessórios que prejudicam esta identificação não serão aceitas.
              </li>

              <li>
                Para o sexo masculino, uso de paletó e gravata (
                <a
                  href="https://www.coffito.gov.br/nsite/?p=2765"
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  Resolução COFFITO 8, Art.22
                </a>
                ), sendo aceita a fotografia tirada com camisa fechada. No caso
                da fotografia digital, deve possuir a qualidade adequada para
                uma boa identificação do profissional. Este arquivo será a sua
                foto de identidade na carteira profissional, não podendo ser
                trocada, a não ser no caso de pedido de segunda via.
              </li>
            </ul>
            <div className="example">
              <p>Veja um exemplo:</p>

              <img src={professionalPictureExample} alt="Exemplo de foto" />
            </div>
          </div>

          <div className="user_authorization">
            <input
              id="userImageRequirement"
              {...register("userImageRequirement", { required: true })}
              type="checkbox"
              value="authorized"
            />
            <label htmlFor="userImageRequirement">
              Atesto que a foto anexada é relativa ao meu rosto e autorizo o
              Crefito-2 a usá-la no documento impresso em PVC, bem como na
              carteira virtual gerada pelo aplicativo C2.
            </label>
          </div>
        </div>

        <div className="form__divisor"></div>

        <div className="form__group">
          <div className="form-group files">
            <label htmlFor="signature">Anexar assinatura:</label>

            <input
              id="signature"
              {...register("signature", { required: true })}
              type="file"
              className="form-control"
              onChange={async function uploadFile(e) {
                if (
                  e.target.files[0].type !== "image/jpeg" &&
                  e.target.files[0].type !== "image/jpg"
                ) {
                  alert("Arquivo deve ser do tipo JPG!");
                  e.target.value = null;
                  return;
                }

                const uploadData = new FormData();

                DisableSubmit();
                uploadData.append(
                  `file`,
                  e.target.files[0],
                  e.target.files[0].name
                );

                if (e.target.files[0].size < 90000000) {
                  try {
                    const result = await api.post("/upload", uploadData);
                    if (result.data.success) {
                      setSignatureImage(result.data.uploadedFiles);
                      EnableSubmit();
                    } else if (result.data.message.code) {
                      alert(result.data.message.code);
                      e.target.value = null;
                      EnableSubmit();
                    }
                  } catch (err) {
                    if (
                      !alert("Ocorreu um erro! Tente novamente mais tarde.")
                    ) {
                      window.scroll(0, 0);
                      e.target.value = null;
                      EnableSubmit();
                    }
                  }
                } else {
                  alert("Arquivo deve ser menor que 90 MB!");
                  e.target.value = null;
                  EnableSubmit();
                }
              }}
            />
          </div>

          <div className="attention">
            <ul>
              <li>
                <strong>Assinatura digital</strong> conforme documento{" "}
                <strong>(com caneta preta escrita grossa)</strong> em{" "}
                <strong>
                  formato JPEG com fundo branco, sem risco ou traços que possam
                  atrapalhar a visão da assinatura.
                </strong>
              </li>

              <li>
                <strong>
                  CASO A FOTO OU A ASSINATURA NÃO ESTEJAM NO PADRÃO DE DOCUMENTO
                  PROFISSIONAL/ILEGÍVEL A EMISSÃO DA CARTEIRA SERÁ CANCELADA.
                </strong>
              </li>
            </ul>
          </div>

          <div className="user_authorization">
            <input
              id="userSignatureRequirement"
              {...register("userSignatureRequirement", { required: true })}
              type="checkbox"
              value="authorized"
            />
            <label htmlFor="userSignatureRequirement">
              Atesto que a assinatura anexada é minha e autorizo o Crefito-2 a
              usá-la no documento impresso em PVC, bem como na carteira virtual
              gerada pelo aplicativo C2.
            </label>
          </div>
        </div>

        <div className="form__divisor"></div>

        <div className="form__group">
          <div className="app_disclaimer">
            <h3>Download do Aplicativo C2</h3>

            <p>
              Acesso imediato à carteira digital, proporcionando praticidade e
              agilidade em minhas atividades. O aplicativo oferece uma variedade
              de documentos digitais com QR CODE, como atestados, relatórios,
              prontuários, entre outros. É uma ferramenta valiosa e de grande
              auxílio no cotidiano de fisioterapeutas e terapeutas ocupacionais,
              simplificando processos e otimizando o tempo dedicado ao cuidado
              dos pacientes.
            </p>

            <p>
              Para baixar o aplicativo C-2,{" "}
              <Link className="app_link" to={`/${accessType}/conteudo/4783`}>
                clique aqui!
              </Link>
            </p>
          </div>

          <div className="user_authorization">
            <input
              id="userAppRequirement"
              {...register("userAppRequirement", { required: true })}
              type="checkbox"
              value="authorized"
            />
            <label htmlFor="userAppRequirement">
              Declaro ter baixado o aplicativo C2 do Crefito-2 em meu aparelho
              celular.
            </label>
          </div>
        </div>

        <div className="form__divisor"></div>

        <div className="form__group">
          <h3>Recebimento da sua carteira</h3>

          <div className="form__options">
            <div className="option__column">
              <p>Selecione a sede para recebimento da sua carteira</p>
              <ul className="delivery_office_list">
                <li>
                  <input
                    {...register("deliveryOffice", { required: true })}
                    type="radio"
                    value="Sede - Rio de Janeiro (Tijuca)"
                  />
                  Sede - Rio de Janeiro (Tijuca)
                </li>
              </ul>
              <ul className="delivery_office_grid">
                <li>
                  <input
                    {...register("deliveryOffice", { required: true })}
                    type="radio"
                    value="Itaperuna"
                  />
                  Itaperuna
                </li>
                <li>
                  <input
                    {...register("deliveryOffice", { required: true })}
                    type="radio"
                    value="Campos dos Goytacazes"
                  />
                  Campos dos Goytacazes
                </li>
                <li>
                  <input
                    {...register("deliveryOffice", { required: true })}
                    type="radio"
                    value="Nova Friburgo"
                  />
                  Nova Friburgo
                </li>
                <li>
                  <input
                    {...register("deliveryOffice", { required: true })}
                    type="radio"
                    value="Macaé"
                  />
                  Macaé
                </li>
                <li>
                  <input
                    {...register("deliveryOffice", { required: true })}
                    type="radio"
                    value="Cabo Frio"
                  />
                  Cabo Frio
                </li>
                <li>
                  <input
                    {...register("deliveryOffice", { required: true })}
                    type="radio"
                    value="Petrópolis"
                  />
                  Petrópolis
                </li>
                <li>
                  <input
                    {...register("deliveryOffice", { required: true })}
                    type="radio"
                    value="Teresópolis"
                  />
                  Teresópolis
                </li>
                <li>
                  <input
                    {...register("deliveryOffice", { required: true })}
                    type="radio"
                    value="Três Rios"
                  />
                  Três Rios
                </li>
                <li>
                  <input
                    {...register("deliveryOffice", { required: true })}
                    type="radio"
                    value="São Gonçalo"
                  />
                  São Gonçalo
                </li>
                <li>
                  <input
                    {...register("deliveryOffice", { required: true })}
                    type="radio"
                    value="Niterói"
                  />
                  Niterói
                </li>
                <li>
                  <input
                    {...register("deliveryOffice", { required: true })}
                    type="radio"
                    value="Duque de Caxias"
                  />
                  Duque de Caxias
                </li>
                <li>
                  <input
                    {...register("deliveryOffice", { required: true })}
                    type="radio"
                    value="Valença"
                  />
                  Valença
                </li>
                <li>
                  <input
                    {...register("deliveryOffice", { required: true })}
                    type="radio"
                    value="Nova Iguaçu"
                  />
                  Nova Iguaçu
                </li>
                <li>
                  <input
                    {...register("deliveryOffice", { required: true })}
                    type="radio"
                    value="Volta Redonda"
                  />
                  Volta Redonda
                </li>
                <li>
                  <input
                    {...register("deliveryOffice", { required: true })}
                    type="radio"
                    value="Angra dos Reis"
                  />
                  Angra dos Reis
                </li>
                <li>
                  <input
                    {...register("deliveryOffice", { required: true })}
                    type="radio"
                    value="Campo Grande"
                  />
                  Campo Grande
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div className="form__group">
          <input id="submit" className="button" type="submit" value="Enviar" />
        </div>
      </form>
    </div>
  );
}
