import React from "react";
import { Link } from "react-router-dom";

import "./styles.css";

import imageDefaultThumb from "./images/memorial_thumb.jpg";

export default function MemorialEntry({ title, call, href, imgURL }) {
  return (
    <Link className="publications_block memorial" to={href}>
      <div className="memorial-image">
        <img src={imgURL === null ? imageDefaultThumb : imgURL} alt="" />
      </div>
      <div className="memorial-content">
        <p className="title" dangerouslySetInnerHTML={{ __html: title }}></p>
        <p className="call" dangerouslySetInnerHTML={{ __html: call }}></p>
      </div>
    </Link>
  );
}
