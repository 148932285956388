import React from "react";
import { Link } from "react-router-dom";

import "./styles.css";

export default function DigitalDocumentCard({
  title,
  icon,
  description,
  href,
  internal,
  disabled,
}) {
  return (
    <div className={`digital_documents_card ${disabled ? "disabled" : ""}`}>
      <div className="content">
        <div className="info">
          <img className="image" src={icon} alt="" />
          <p className="title" dangerouslySetInnerHTML={{ __html: title }}></p>
        </div>
        <p
          className="description"
          dangerouslySetInnerHTML={{ __html: description }}
        ></p>
        {!disabled ? (
          internal ? (
            <Link className={`button ${disabled ? "disabled" : ""}`} to={href}>
              Acessar
            </Link>
          ) : (
            <a
              className={`button ${disabled ? "disabled" : ""}`}
              target="_blank"
              rel="noopener noreferrer"
              href={href}
            >
              Acessar
            </a>
          )
        ) : (
          <button className={`button ${disabled ? "disabled" : ""}`} disabled>
            Acessar
          </button>
        )}
      </div>
    </div>
  );
}
