import api from "../../../services/api";

import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";

import * as globalComponents from "../../../components";

import "../styles.css";

export default function OcupationalTherapyScene() {
  const { accessType, ocupationalTherapyID } = useParams();

  const dir = [
    {
      title: "Terapia Ocupacional",
      url: `/${accessType}/terapia-ocupacional`,
    },
  ];

  const [menu, setMenu] = useState([
    {
      Codigo: 16,
      Descricao: "Definição",
    },
    {
      Codigo: 18,
      Descricao: "Especialidades",
    },
    {
      Codigo: 60,
      Descricao: "Classificação Brasileira de Ocupações",
    },
    {
      Codigo: 58,
      Descricao: "Código de Ética",
    },
    {
      Codigo: 17,
      Descricao: "Símbolo",
    },
    {
      Codigo: 19,
      Descricao: "Piso Salarial e Jornada de Trabalho",
    },
    {
      Codigo: 20,
      Descricao: "Referencial de Honorários",
    },
    {
      Codigo: 77,
      Descricao: "Parâmetros Assistenciais da Terapia Ocupacional",
    },
    {
      Codigo: 21,
      Descricao: "Lista de Procedimentos",
    },
    {
      Codigo: 22,
      Descricao: "Instituições de Ensino",
    },
  ]);

  const [data, setData] = useState([
    {
      Titulo: null,
      Subtitulo: null,
      Chamada: null,
      Data_Publicacao: null,
      Nome_Arquivo: null,
      Materia: null,
    },
  ]);

  useEffect(() => {
    async function fetchMenu() {
      await api
        .get("/terapia-ocupacional")
        .then((response) => {
          setMenu(response.data);
        })
        .catch((err) => {
          console.log(err);
        });
    }
    fetchMenu();
  }, []);

  const currentContent = menu.find((item) => {
    return (
      item.Descricao.toLowerCase()
        .normalize("NFD")
        .replace(/[\u0300-\u036f]/g, "")
        .replace(/[^a-zA-Z0-9\s]/g, "")
        .replace(/ /g, "_") === ocupationalTherapyID
    );
  });

  useEffect(() => {
    window.scroll(0, 0);
    async function fetchData() {
      await api
        .get(`/terapia-ocupacional/${currentContent.Codigo}`)
        .then((response) => {
          setData(response.data);
        })
        .catch((err) => {
          console.log(err);
        });
    }
    fetchData();
  }, [currentContent.Codigo]);

  return (
    <>
      <globalComponents.Header />

      <globalComponents.Breadcrumbs
        title={currentContent.Descricao}
        dir={dir}
      />

      <div className="container">
        {data.map((value, index) => (
          <div key={index} className="content-wrapper">
            <globalComponents.PageTitle
              title={currentContent.Descricao}
              subtitle={value.Subtitulo}
              span={value.Data_Publicacao}
            />

            <div className="content-image">
              <img src={value.Nome_Arquivo} alt="" />
            </div>

            <div
              className="content-text"
              dangerouslySetInnerHTML={{ __html: value.Materia }}
            ></div>
          </div>
        ))}
      </div>

      <globalComponents.Footer />
    </>
  );
}
