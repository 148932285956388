import React from "react";
import { useParams } from "react-router-dom";

import HighlightCard from "./components/HighlightCard";

import "./styles.css";

import imageIconAtestadoDigital from "./images/icon-atestado_digital.png";
import imageIconManuais from "./images/icon-manuais.png";
import imageIconConcurso from "./images/icon-concurso.png";
import imageIconDenuncia from "./images/icon-denuncia.png";
import imageIconCasa from "./images/icon-casa.png";
import imageIconJustica from "./images/icon-justica.png";
import imageIconEducacao from "./images/icon-educacao.png";
import imageIconPisoSalarialNacional from "./images/icon-piso-salarial-nacional.png";
import imageIconAnuidadeProfissional from "./images/icon-anuidade-profissional.png";
import imageIconAcupuntura from "./images/icon-acupuntura.png";
// import imageIconCBD from "./images/icon-cbd.png";
// import imageIconConversa from "./images/icon-conversa.png";

export default function Highlights() {
  const { accessType } = useParams();

  return (
    <div
      className="container"
      style={{ paddingTop: "50px", paddingBottom: "50px", marginTop: "0" }}
    >
      <div className="destaques_bloco--titulos destaques">
        <h2>Destaques</h2>
        <p>Confira os destaques do Crefito-2</p>
      </div>

      <div className="row horizontalR">
        <div className="col horizontalR1">
          <HighlightCard
            service="Denúncias"
            span="contate-nos"
            image={imageIconDenuncia}
            href={`/${accessType}/fiscalizacao/denuncias`}
          />
        </div>
        <div className="col horizontalR1">
          <HighlightCard
            service="Manuais & orientações"
            span="acesse"
            image={imageIconManuais}
            href={`/${accessType}/manuais_e_orientacoes`}
          />
        </div>
        <div className="col horizontalR1">
          <HighlightCard
            service="Concursos & oportunidades"
            span="fique de olho"
            image={imageIconConcurso}
            href={`/${accessType}/concursos_e_oportunidades`}
          />
        </div>
        <div className="col horizontalR1">
          <HighlightCard
            service="Documentos digitais"
            span="acesse"
            image={imageIconAtestadoDigital}
            href={`/${accessType}/documentos_digitais`}
          />
        </div>
        <div className="col horizontalR1">
          <HighlightCard
            service="Leis elaboradas com o Crefito-2"
            span="acompanhe"
            image={imageIconJustica}
            href={`/${accessType}/conteudo/5042`}
          />
        </div>
        <div className="col horizontalR1">
          <HighlightCard
            service="Ações realizadas em Home Care"
            span="fique por dentro"
            image={imageIconCasa}
            href={`/${accessType}/conteudo/5041`}
          />
        </div>
        <div className="col horizontalR1">
          <HighlightCard
            service="Piso Salarial Nacional"
            span="saiba mais sobre o"
            image={imageIconPisoSalarialNacional}
            href={`/${accessType}/conteudo/5247`}
          />
        </div>
        <div className="col horizontalR1">
          <HighlightCard
            service="Anuidade Profissional"
            span="ações conquistadas sobre"
            image={imageIconAnuidadeProfissional}
            href={`/${accessType}/conteudo/5356`}
          />
        </div>
        <div className="col horizontalR1">
          <HighlightCard
            service="Ações realizadas em Acupuntura"
            span="fique por dentro"
            image={imageIconAcupuntura}
            href={`/${accessType}/conteudo/5149`}
          />
        </div>
        <div className="col horizontalR1">
          <HighlightCard
            service="Graduação EAD em Saúde"
            span="Crefito-2 diz <strong>NÃO</strong> à"
            image={imageIconEducacao}
            href={`/${accessType}/conteudo/5040`}
          />
        </div>
      </div>
    </div>
  );
}
