import React from "react";

import "./styles.css";

export default function PageTitle({ title, subtitle, span }) {
  return (
    <div className="title__page">
      <p className="title" dangerouslySetInnerHTML={{ __html: title }}></p>
      <p
        className="subtitle"
        dangerouslySetInnerHTML={{ __html: subtitle }}
      ></p>
      <span dangerouslySetInnerHTML={{ __html: span }}></span>
    </div>
  );
}
