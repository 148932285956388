import api from "../../../../services/api";

import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";

import * as globalComponents from "../../../../components";
import ListEntry from "../../components/ListEntry";

import "../styles.css";

export default function Fiscalization() {
  const { accessType } = useParams();
  const [data, setData] = useState([]);

  useEffect(() => {
    window.scroll(0, 0);
    async function fetchData() {
      await api
        .get("/fiscalizacao")
        .then((response) => {
          const links = response.data;
          const header = links.find((link) => link.Codigo === 5506) || null;

          if (header) {
            links.splice(links.indexOf(header), 1);
            links.unshift(header);
          }

          setData(response.data);
        })
        .catch((err) => {
          console.log(err);
        });
    }
    fetchData();
  }, [accessType]);

  return (
    <>
      <globalComponents.Header />

      <globalComponents.Breadcrumbs title="Fiscalização" dir={[]} />

      <div className="container">
        <div className="title__publications">
          <strong>Fiscalização</strong>

          {data.map((value, index) => (
            <ListEntry
              key={index}
              title={value.Titulo}
              href={`/${accessType}/fiscalizacao/materia/${value.Codigo}`}
            />
          ))}
          <ListEntry
            title="Denúncias"
            href={`/${accessType}/fiscalizacao/denuncias`}
          />
        </div>
      </div>

      <globalComponents.Footer />
    </>
  );
}
