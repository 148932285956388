import api from "../../../../services/api";

import React, { useState, useEffect } from "react";

import * as globalComponents from "../../../../components";
import CustomCard from "../../components/CustomCard";

import imageDefaultThumb from "./images/default_thumb.jpg";

export default function Multimedia() {
  window.scroll(0, 0);
  const [data, setData] = useState([]);

  useEffect(() => {
    async function fetchData() {
      await api
        .get("/multimidia")
        .then((response) => {
          setData(response.data);
        })
        .catch((err) => {
          console.log(err);
        });
    }
    fetchData();
  }, []);

  return (
    <>
      <globalComponents.Header />

      <globalComponents.Breadcrumbs title="Multimídia" dir={[]} />

      <div className="container">
        {data.map((sectionValue, index) => (
          <div key={index}>
            <globalComponents.PageTitle title={sectionValue.Descricao} />

            <div className="row">
              {sectionValue.Registros.map((contentValue, index) => (
                <CustomCard
                  key={index}
                  type={sectionValue.Descricao}
                  title={contentValue.Titulo}
                  img={
                    contentValue.Nome_Arquivo == null
                      ? imageDefaultThumb
                      : contentValue.Nome_Arquivo
                  }
                  href={contentValue.Link}
                />
              ))}
            </div>
          </div>
        ))}
      </div>

      <globalComponents.Footer />
    </>
  );
}
