import React from "react";
import "./styles.css";

export default function Magazine({ thumb, href, title }) {
  return (
    <a
      className="thumbRevista"
      href={href}
      target="_blank"
      rel="noopener noreferrer"
    >
      <img src={thumb} title={title} alt="" />
    </a>
  );
}
