import api from "../../../../services/api";

import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";

import * as globalComponents from "../../../../components";

import "./styles.css";
import "../../styles.css";

export default function InternshipContract() {
  window.scroll(0, 0);
  const { accessType } = useParams();

  const dir = [
    {
      title: "Serviços Online",
      url: `/${accessType}/servicos-online`,
    },
  ];

  const [data, setData] = useState({
    Titulo: "Modelo de Termo de Compromisso de Estágio",
    Subtitulo: "",
    Chamada: "",
    Data_Publicacao: "",
    Nome_Arquivo: "",
    Materia: "",
  });

  useEffect(() => {
    async function fetchData() {
      await api
        .get("/conteudo/4728")
        .then((response) => {
          setData(response.data);
        })
        .catch((err) => {
          console.log(err);
        });
    }
    fetchData();
  }, []);

  return (
    <>
      <globalComponents.Header />

      <globalComponents.Breadcrumbs
        title="Modelo de Termo de Compromisso de Estágio"
        dir={dir}
      />

      <div className="container">
        <div className="content-wrapper">
          <globalComponents.PageTitle
            title={data.Titulo}
            subtitle={data.Subtitulo}
            span={data.Data_Publicacao}
          />

          <div className="content-image">
            <img src={data.Nome_Arquivo} alt="" />
          </div>

          <div
            id="manual_fiscalizacao"
            className="content-text"
            dangerouslySetInnerHTML={{ __html: data.Materia }}
          ></div>
        </div>
      </div>

      <globalComponents.Footer />
    </>
  );
}
