import api from "../../../../../../services/api";

import React, { useState } from "react";
import { useForm } from "react-hook-form";

import "./styles.css";

export default function AnonymousReportsForm() {
  const [formFiles, setFormFiles] = useState(null);
  const { register, handleSubmit } = useForm();

  async function onSubmit(data) {
    const userForm = {
      reporterEmail: data.reporterEmail,
      infractionDatetime: data.infractionDatetime,
      infractorName: data.infractorName,
      infractorCpf: data.infractorCpf,
      infractorCnpj: data.infractorCnpj,
      infractorCrefitoNumber: data.infractorCrefitoNumber,
      infractorAddress: data.infractorAddress,
      report: data.report,
      files: formFiles,
    };
    const submit = document.getElementById("submit");

    submit.value = "Enviando...";
    submit.classList.add("disabled");
    submit.setAttribute("disabled", true);

    try {
      const result = await api.post("/form/denuncias_anonimas", userForm);
      if (!alert(result.data.message)) {
        window.scroll(0, 0);
        window.location.reload();
      }
    } catch (err) {
      if (!alert("Ocorreu um erro! Tente novamente mais tarde.")) {
        window.scroll(0, 0);
        submit.value = "Enviar";
        submit.classList.remove("disabled");
        submit.removeAttribute("disabled");
      }
    }
  }

  function DisableFileUpload() {
    const submit = document.getElementById("submit");

    submit.value = "Carregando Arquivos...";
    submit.classList.add("disabled");
    submit.setAttribute("disabled", true);
  }

  function EnableFileUpload() {
    const submit = document.getElementById("submit");

    submit.value = "Enviar";
    submit.classList.remove("disabled");
    submit.removeAttribute("disabled");
  }

  function ClearFileUpload() {
    document.getElementById("files").value = null;
  }

  return (
    <div className="denuncia__form">
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="form__title">
          <p>Formulário de Denúncia Anônima</p>
        </div>

        <div className="form__group">
          <label>Dados do denunciante / representante:</label>

          <input
            {...register("reporterEmail")}
            type="email"
            placeholder="Endereço de E-Mail (opcional)"
          />

          <div className="form__divisor fiscalization"></div>

          <label>
            Dados do infrator / Indicação de fatos e circunstâncias
            infracionais:
          </label>

          <input {...register("infractionDatetime")} type="datetime-local" />
          <input
            {...register("infractorName", { required: true })}
            type="text"
            placeholder="Nome completo do profissional ou da empresa"
          />
          <div className="form__group--half">
            <input
              {...register("infractorCpf")}
              type="text"
              placeholder="CPF (apenas números)"
              minLength={11}
              maxLength={11}
            />
            <input
              {...register("infractorCnpj")}
              type="text"
              placeholder="CNPJ (apenas números)"
              minLength={14}
              maxLength={14}
            />
          </div>
          <input
            {...register("infractorCrefitoNumber")}
            type="text"
            placeholder="Nº Crefito"
          />
          <input
            {...register("infractorAddress", { required: true })}
            type="text"
            placeholder="Endereço completo"
          />

          <div className="form__divisor fiscalization"></div>

          <label>Relato dos fatos:</label>

          <textarea
            {...register("report", { required: true })}
            cols="30"
            rows="10"
            placeholder="Escreva aqui o relato dos fatos"
          ></textarea>

          <div className="form__divisor fiscalization"></div>

          <div className="form-group files">
            <label htmlFor="files">Envio de arquivo:</label>

            <p className="form__observation">
              <strong>Observação:</strong> em caso de múltiplos arquivos, envie
              uma pasta ".zip" contendo todos.
            </p>

            <input
              id="files"
              {...register("files")}
              type="file"
              className="form-control"
              multiple=""
              onChange={async function uploadFiles(e) {
                const uploadData = new FormData();

                DisableFileUpload();
                uploadData.append(
                  `file`,
                  e.target.files[0],
                  e.target.files[0].name
                );

                if (e.target.files[0].size < 90000000) {
                  try {
                    const result = await api.post("/upload", uploadData);
                    if (result.data.success) {
                      setFormFiles(result.data.uploadedFiles);
                      EnableFileUpload();
                    } else if (result.data.message.code) {
                      alert(result.data.message.code);
                      ClearFileUpload();
                      EnableFileUpload();
                    }
                  } catch (err) {
                    if (
                      !alert("Ocorreu um erro! Tente novamente mais tarde.")
                    ) {
                      window.scroll(0, 0);
                      ClearFileUpload();
                      EnableFileUpload();
                    }
                  }
                } else {
                  alert("Arquivo deve ser menor que 90 MB!");
                  ClearFileUpload();
                  EnableFileUpload();
                }
              }}
            />
          </div>
          <input id="submit" className="button" type="submit" value="Enviar" />
        </div>
      </form>
    </div>
  );
}
