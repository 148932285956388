import React from "react";
import { useParams } from "react-router-dom";

import "./styles.css";

import * as globalComponents from "../../components";
import * as localComponents from "./components";

export default function Home() {
  const { accessType } = useParams();

  if (accessType !== "home_profissional" && accessType !== "home") {
    window.location.pathname = "/";
  }

  const type =
    accessType === "home_profissional" ? "Profissional" : "Não Profissional";

  return (
    <>
      <globalComponents.Header />

      <div className="bg-home">
        <div className="container">
          <div className="row">
            <localComponents.HomeBreadcrumb type={type} />
          </div>

          <div className="row">
            <div className="col">
              <div className="row" style={{ marginTop: "35px" }}>
                <div className="col-md-9 col-sm-12">
                  <localComponents.HomeCarousel />
                </div>
                <div className="col-md-3 col-sm-12">
                  <localComponents.Agenda />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="highlightsContainer">
        <localComponents.Highlights />
      </div>

      <div className="mostSearchedContainer">
        <localComponents.MostSearchedServices type={type} />
      </div>

      <div className="newsBlockContainer">
        <localComponents.NewsBlock />
      </div>

      <div className="linksDisplayContainer">
        <localComponents.LinksDisplay />
      </div>

      <div className="officesLocationsContainer">
        <localComponents.OfficesLocations />
      </div>

      <div className="publicationsBlockAndMagazinesListContainer">
        <localComponents.PublicationsBlock />

        {/* <localComponents.MagazinesList /> */}
      </div>

      <localComponents.HomeFooter />
    </>
  );
}
