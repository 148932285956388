import api from "../../../../services/api";

import React, { useState, useEffect } from "react";

import { useParams } from "react-router-dom";

import * as globalComponents from "../../../../components";

export default function AdvisoryDepartments() {
  const { accessType } = useParams();

  const dir = [
    {
      title: "Crefito2",
      url: `/${accessType}/crefito2`,
    },
  ];

  const [data, setData] = useState([
    {
      Titulo: "",
      Materia: "",
    },
    {
      Titulo: "",
      Materia: "",
    },
    {
      Titulo: "",
      Materia: "",
    },
    {
      Titulo: "",
      Materia: "",
    },
  ]);

  useEffect(() => {
    async function fetchData() {
      await api
        .get("/assessorias-departamentos")
        .then((response) => {
          setData(response.data);
        })
        .catch((err) => {
          console.log(err);
        });
    }
    fetchData();
  }, []);

  return (
    <>
      <globalComponents.Header />

      <globalComponents.Breadcrumbs
        title="Assessorias e Departamentos"
        dir={dir}
      />

      <div className="container">
        <globalComponents.PageTitle title="Assessorias e Departamentos" />

        <globalComponents.CustomAccordion data={data} alt="" />
      </div>

      <globalComponents.Footer />
    </>
  );
}
