import Carousel from "react-multi-carousel";
import { useParams } from "react-router-dom";

import "react-multi-carousel/lib/styles.css";
import "./styles.css";

import LinkCard from "./components/LinkCard";

import faqIcon from "./images/faq-icon.png";
import magazinesIcon from "./images/magazines-icon.png";
import appIcon from "./images/app-icon.png";
import youtubeIcon from "./images/youtube-icon.png";
import memorialIcon from "./images/memorial-icon.png";
import tributesIcon from "./images/tributes-icon.png";
import interviewsIcon from "./images/interviews-icon.png";
import contactsIcon from "./images/contacts-icon.png";
import newsletterIcon from "./images/newsletter-icon.png";
import podcastIcon from "./images/podcast-icon.png";
import custoEfetividadeIcon from "./images/custo_efetividade-icon.png";

export default function LinksDisplay() {
  const { accessType } = useParams();
  const links = [
    {
      image: contactsIcon,
      title: "Fale Conosco",
      href: `/${accessType}/fale_conosco`,
      external: false,
    },
    {
      image: faqIcon,
      title: "Perguntas Mais Frequentes",
      href: `/${accessType}/conteudo/4836`,
      external: false,
    },
    {
      image: custoEfetividadeIcon,
      title: "Custo Efetividade",
      href: `/${accessType}/evidencias-custo-efetividade`,
      external: false,
    },
    {
      image: youtubeIcon,
      title: "C2 no YouTube",
      href: "https://www.youtube.com/@Crefito2rj",
      external: true,
    },
    {
      image: memorialIcon,
      title: "Memorial",
      href: `/${accessType}/memorial`,
      external: false,
    },
    {
      image: tributesIcon,
      title: "Homenagens",
      href: `/${accessType}/homenagens`,
      external: false,
    },
    {
      image: appIcon,
      title: "Aplicativo C2",
      href: `/${accessType}/conteudo/4783`,
      external: false,
    },
    {
      image: interviewsIcon,
      title: "Entrevistas e Matérias",
      href: `/${accessType}/conteudo/5152`,
      external: false,
    },
    {
      image: newsletterIcon,
      title: "Newsletter",
      href: `/${accessType}/newsletter`,
      external: false,
    },
    {
      image: podcastIcon,
      title: "Dois Toques",
      href: "https://open.spotify.com/show/5YBlxXmbIioFQK7qx5iewp?si=0amODQczQiGqcVTmNC3Hsw",
      external: true,
    },
    {
      image: magazinesIcon,
      title: "Revistas",
      href: `/${accessType}/revistas`,
      external: false,
    },
  ];
  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 5,
      slidesToSlide: 5, // optional, default to 1.
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 3,
      slidesToSlide: 3, // optional, default to 1.
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      slidesToSlide: 1, // optional, default to 1.
    },
  };

  return (
    <div
      className="container"
      style={{ paddingTop: "50px", paddingBottom: "50px", marginTop: "0" }}
    >
      <Carousel
        responsive={responsive}
        infinite={true}
        swipeable={false}
        draggable={false}
      >
        {links.map((link, index) => (
          <LinkCard
            key={index}
            title={link.title}
            img={link.image}
            href={link.href}
            external={link.external}
          />
        ))}
      </Carousel>
    </div>
  );
}
