const constants = {
  // apiURL: "http://localhost:3333",
  apiURL: "https://crefito2.go2web-vps.com.br/",
  g2wURL: "https://crefito2.go2webdbm.com.br/g2w",
  spotifyTokenURL: "https://accounts.spotify.com/api/",
  spotifyApiURL: "https://api.spotify.com/v1/",
  spotifyClientID: "f609885360ed4167b66458f58f0ad611",
  spotifySecretKey: "7edef6939b0146e8874cf845f9455245",
  crefito2PodcastSpotifyID: "5YBlxXmbIioFQK7qx5iewp",
  GoogleAnalyticsTrackingId: "UA-188934739-1",
};

export default constants;
