// import api from "../../services/api";

import React from "react";
// import { useEffect, useState } from "react";

import * as globalComponents from "../../components";

import "./styles.css";

export default function Evidences() {
  // const [data, setData] = useState([]);

  // useEffect(() => {
  //   window.scroll(0, 0);
  //   async function fetchData() {
  //     await api
  //       .get(`/conteudo/5598`)
  //       .then((response) => {
  //         setData(response.data);
  //       })
  //       .catch((err) => {
  //         setData({
  //           Titulo: "Conteúdo Não Encontrado!",
  //           Subtitulo:
  //             "Este conteúdo não foi encontrado em nossa base de dados.",
  //           Chamada: null,
  //           Data_Publicacao: null,
  //           Nome_Arquivo: null,
  //           Materia: null,
  //           Link: null,
  //         });
  //         console.log(err);
  //       });
  //   }
  //   fetchData();
  // }, []);

  return (
    <>
      <globalComponents.Header />

      <globalComponents.Breadcrumbs
        title={"Evidências: Custo Efetividade"}
        dir={[]}
      />

      <div className="container">
        <div id="common-content" className="content-wrapper">
          {/* <globalComponents.PageTitle
            title={data.Titulo}
            subtitle={data.Subtitulo}
            span={data.Data_Publicacao}
          />

          <div className="content-image">
            {data.Link ? (
              <a href={data.Link}>
                <img src={data.Nome_Arquivo} alt="" />
              </a>
            ) : (
              <img src={data.Nome_Arquivo} alt="" />
            )}
          </div>

          <div
            className="content-text"
            dangerouslySetInnerHTML={{ __html: data.Materia }}
          ></div> */}

          <iframe
            title="Relatorio Custo Efetividade"
            src="https://lookerstudio.google.com/embed/reporting/9631c400-f5f0-475d-965d-92f0615e3621/page/p_aowwdksdad"
            frameborder="0"
            allowfullscreen
            className="report-iframe"
          ></iframe>
        </div>
      </div>

      <globalComponents.Footer />
    </>
  );
}
