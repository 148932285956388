import api from "../../../services/api";

import React, { useState, useEffect } from "react";

import * as globalComponents from "../../../components";
import OfficesAccordion from "./components/OfficesAccordion";

import "../styles.css";

export default function OfficesAndBranches() {
  window.scroll(0, 0);
  const [offices, setOffices] = useState([
    {
      Titulo: "",
      Subtitulo: "",
      Chamada: "",
      Link: "",
    },
    {
      Titulo: "",
      Subtitulo: "",
      Chamada: "",
      Link: "",
    },
  ]);

  useEffect(() => {
    async function fetchCommissions() {
      await api
        .get("/enderecos")
        .then((response) => {
          setOffices(response.data);
        })
        .catch((err) => {
          console.log(err);
        });
    }
    fetchCommissions();
  }, []);

  return (
    <>
      <globalComponents.Header />

      <globalComponents.Breadcrumbs title="Sedes e Subsedes" dir={[]} />

      <div className="container">
        <globalComponents.PageTitle title="Sedes e Subsedes" />

        <div className="content-text">
          <p>Saiba onde encontrar as sedes e subsedes do Crefito-2</p>
        </div>

        <OfficesAccordion data={offices} />
      </div>

      <globalComponents.Footer />
    </>
  );
}
