import api from "../../../../services/api";

import React, { useState, useEffect } from "react";
import { useLocation, useParams } from "react-router-dom";

import * as globalComponents from "../../../../components";
import ListEntry from "../../components/ListEntry";

import "../styles.css";
import "./styles.css";

export default function Publications() {
  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }

  let query = useQuery();

  var title = "Comunicados";
  var apiRoute = "/comunicados";

  if (useLocation().pathname.includes("noticias")) {
    title = "Notícias";
    apiRoute = "/noticias";
  }

  const { accessType } = useParams();

  const [page] = useState(
    query.get("pagina") === null ? 1 : parseInt(query.get("pagina"))
  );
  const [count, setCount] = useState(null);
  const [publications, setPublications] = useState([
    {
      Codigo: null,
      Titulo: "",
      Nome_Arquivo: "",
      Chamada: "",
    },
    {
      Codigo: null,
      Titulo: "",
      Nome_Arquivo: "",
      Chamada: "",
    },
    {
      Codigo: null,
      Titulo: "",
      Nome_Arquivo: "",
      Chamada: "",
    },
    {
      Codigo: null,
      Titulo: "",
      Nome_Arquivo: "",
      Chamada: "",
    },
  ]);

  useEffect(() => {
    async function fetchCount() {
      await api
        .get(`${apiRoute}/count`)
        .then((response) => {
          setCount(
            response.data.rows % 4 === 0
              ? response.data.rows / 4
              : Math.ceil(response.data.rows / 4)
          );
        })
        .catch((err) => {
          console.log(err);
        });
    }
    fetchCount();
  }, [apiRoute]);

  useEffect(() => {
    window.scroll(0, 0);
    async function fetchPublications() {
      await api
        .get(apiRoute + "?page=" + page)
        .then((response) => {
          setPublications(response.data);
        })
        .catch((err) => {
          console.log(err);
        });
    }
    fetchPublications();
  }, [apiRoute, page]);

  function handleToFirst() {
    window.location.href = `/${accessType}${apiRoute}?pagina=1`;
  }

  function handleToPrevious() {
    if (page > 1) {
      window.location.href = `/${accessType}${apiRoute}?pagina=${page - 1}`;
    }
  }

  function handleToNext() {
    if (page < count) {
      window.location.href = `/${accessType}${apiRoute}?pagina=${page + 1}`;
    }
  }

  function handleToLast() {
    window.location.href = `/${accessType}${apiRoute}?pagina=${count}`;
  }

  return (
    <>
      <globalComponents.Header />

      <globalComponents.Breadcrumbs title={title} dir={[]} />

      <div className="container">
        <div className="title__publications">
          <strong>{title}</strong>

          {publications.map((value, index) => (
            <ListEntry
              key={index}
              title={value.Titulo}
              call={value.Chamada}
              image={value.Nome_Arquivo}
              href={`/${accessType}/conteudo/${value.Codigo}`}
            />
          ))}

          <div className="pagination">
            <div className="btn__pagination">
              <div className="btn-mobel">
                <button onClick={handleToFirst}>Primeira</button>
                <button onClick={handleToPrevious}>Anterior</button>
              </div>
              <div className="btn-mobel">
                <button onClick={handleToNext}>Próxima</button>
                <button onClick={handleToLast}>Última</button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <globalComponents.Footer />
    </>
  );
}
