import * as crefito2Scenes from "./Crefito2Scenes";
import * as onlineServicesScenes from "./OnlineServicesScenes";
import * as legislationScenes from "./LegislationScenes";
import PhysiotherapyScene from "./PhysiotherapyScene";
import OcupationalTherapyScene from "./OcupationalTherapyScene";
import Transparency from "./Transparency";
import BidScene from "./BidScene";
import CourseScene from "./CourseScene";
import ContestScene from "./ContestScene";
import CommissionsAndChambers from "./CommissionsAndChambers";
import Informations from "./Informations";
import ManualsAndOrientationsScene from "./ManualsAndOrientationsScene";
import ElectionsScene from "./ElectionsScene";
import SocialActionsScene from "./SocialActionsScene";
import FiscalizationScene from "./FiscalizationScene";
import OfficesAndBranches from "./OfficesAndBranches";

const crefito2MenuInfo = {
  links: [
    {
      title: "Missão, Visão e Valores",
      href: "/crefito2/missao_visao_e_valores",
    },
    {
      title: "Atribuições",
      href: "/crefito2/atribuicoes",
    },
    {
      title: "Gestão",
      href: "/crefito2/gestao",
    },
    {
      title: "Polos de Representação Institucional",
      href: "/crefito2/polos_de_representacao_institucional",
    },
    {
      title: "Assessorias e Departamentos",
      href: "/crefito2/assessorias_e_departamentos",
    },
    {
      title: "Sistema Coffito/Crefitos",
      href: "/crefito2/sistema_coffito-crefitos",
    },
    {
      title: "Regimento Interno",
      href: "/crefito2/regimento_interno",
    },
  ],
};

export {
  crefito2Scenes,
  crefito2MenuInfo,
  PhysiotherapyScene,
  OcupationalTherapyScene,
  Transparency,
  onlineServicesScenes,
  BidScene,
  ContestScene,
  CommissionsAndChambers,
  ManualsAndOrientationsScene,
  Informations,
  legislationScenes,
  ElectionsScene,
  SocialActionsScene,
  CourseScene,
  FiscalizationScene,
  OfficesAndBranches,
};
