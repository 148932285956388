import api from "../../../services/api";

import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";

import * as globalComponents from "../../../components";

import "../styles.css";

export default function ManualsAndOrientationsScene() {
  const { accessType, manualOrOrientationID } = useParams();
  const [data, setData] = useState([]);

  useEffect(() => {
    window.scroll(0, 0);
    async function fetchData() {
      await api
        .get(`/conteudo/${manualOrOrientationID}`)
        .then((response) => {
          setData(response.data);
        })
        .catch((err) => {
          console.log(err);
        });
    }
    fetchData();
  }, [manualOrOrientationID]);

  return (
    <>
      <globalComponents.Header />

      <globalComponents.Breadcrumbs
        title={`Manual/Orientação ${manualOrOrientationID}`}
        dir={[
          {
            title: "Manuais e Orientações",
            url: `/${accessType}/manuais_e_orientacoes`,
          },
        ]}
      />

      <div className="container">
        <div className="content-wrapper">
          <globalComponents.PageTitle
            title={data.Titulo}
            subtitle={data.Subtitulo}
            span={data.Data_Publicacao}
          />

          <div className="content-image">
            <img src={data.Nome_Arquivo} alt="" />
          </div>

          <div
            className="content-text"
            dangerouslySetInnerHTML={{ __html: data.Materia }}
          ></div>
        </div>
      </div>

      <globalComponents.Footer />
    </>
  );
}
