import React from "react";
import { useParams } from "react-router-dom";

import ManualsAndOrientationsEntry from "../ManualsAndOrientationsEntry";

export default function ManualsAndOrientationsSection({ title, content }) {
  const { accessType } = useParams();

  if (content.length > 0) {
    return (
      <div className="title__publications">
        <strong>{title}</strong>

        {content.map((value, index) => (
          <ManualsAndOrientationsEntry
            key={index}
            title={value.Titulo}
            href={`/${accessType}/manuais_e_orientações/${value.Codigo}`}
            imgURL={value.Nome_Arquivo}
          />
        ))}
      </div>
    );
  } else {
    return <></>;
  }
}
