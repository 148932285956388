import api from "../../../../services/api";

import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";

import * as globalComponents from "../../../../components";
import ListEntry from "../../components/ListEntry";

import "../styles.css";

export default function Elections() {
  const { accessType } = useParams();
  const [data, setData] = useState([]);

  const dir = [];

  useEffect(() => {
    window.scroll(0, 0);
    async function fetchData() {
      await api
        .get("/eleicoes")
        .then((res) => {
          setData(res.data);
        })
        .catch((err) => {
          console.log(err);
        });
    }
    fetchData();
  }, []);

  return (
    <>
      <globalComponents.Header />

      <globalComponents.Breadcrumbs title="Eleições" dir={dir} />

      <div className="container">
        {data.map((value, index) => (
          <div key={index} className="title__publications elections">
            <a
              href="https://www.coffito.gov.br/nsite/?cat=27"
              target="_blank"
              rel="noopener noreferrer"
            >
              <strong>{value.sectionName}</strong>
            </a>

            {value.content.map((contentValue, contentIndex) => (
              <ListEntry
                key={contentIndex}
                title={contentValue.Titulo}
                call={contentValue.Subtitulo}
                href={`/${accessType}/eleicoes/${contentValue.Codigo}`}
              />
            ))}
          </div>
        ))}
      </div>

      <globalComponents.Footer />
    </>
  );
}
