import api from "../../../../services/api";

import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";

import * as globalComponents from "../../../../components";

import "../../styles.css";

export default function CrefitoNormativesScene() {
  const { accessType, normativeID } = useParams();
  const [data, setData] = useState([]);

  const dir = [
    {
      title: "Trasparência e Prestação de Contas",
      url: `/${accessType}/transparencia`,
    },
    {
      title: "Normativas Crefito2",
      url: `/${accessType}/transparencia/normativas_crefito2`,
    },
  ];

  useEffect(() => {
    window.scroll(0, 0);
    async function fetchData() {
      await api
        .get(`/conteudo/${normativeID}`)
        .then((response) => {
          setData(response.data);
        })
        .catch((err) => {
          console.log(err);
        });
    }
    fetchData();
  }, [normativeID]);

  return (
    <>
      <globalComponents.Header />

      <globalComponents.Breadcrumbs
        title={`Normativa Crefito2 ${normativeID}`}
        dir={dir}
      />

      <div className="container">
        <div className="content-wrapper">
          <globalComponents.PageTitle
            title={data.Titulo}
            subtitle={data.Subtitulo}
            span={data.Data_Publicacao}
          />

          <div className="content-image">
            <img src={data.Nome_Arquivo} alt="" />
          </div>

          <div
            className="content-text"
            dangerouslySetInnerHTML={{ __html: data.Materia }}
          ></div>
        </div>
      </div>

      <globalComponents.Footer />
    </>
  );
}
