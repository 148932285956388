import api from "../../../../services/api";

import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";

import * as globalComponents from "../../../../components";
import ListEntry from "../../components/ListEntry";

import "../styles.css";
import "./styles.css";

export default function NextEvents() {
  const { accessType } = useParams();
  const [data, setData] = useState([]);
  const [emptyAgenda, setEmptyAgenda] = useState(false);

  useEffect(() => {
    if (emptyAgenda) {
      document.getElementById("empty_agenda").removeAttribute("hidden");
    } else if (!emptyAgenda) {
      document.getElementById("empty_agenda").setAttribute("hidden", "true");
    }
  }, [emptyAgenda]);

  useEffect(() => {
    async function fetchData() {
      await api
        .get("/eventos")
        .then((response) => {
          setData(response.data);
          setEmptyAgenda(false);
        })
        .catch((err) => {
          setEmptyAgenda(true);
          setData([]);
          console.log(err);
        });
    }
    fetchData();
  }, []);

  return (
    <>
      <globalComponents.Header />

      <globalComponents.Breadcrumbs title="Próximos Eventos" dir={[]} />

      <div className="container">
        <div className="title__publications">
          <strong>Próximos Eventos</strong>

          <div id="empty_agenda" className="empty_agenda" hidden>
            <h3>Eventos Não Encontrados!</h3>
            <p>Atualmente não existem eventos marcados na agenda.</p>
          </div>

          {data.map((value, index) => (
            <ListEntry
              key={index}
              title={value.Titulo}
              call={value.Subtitulo.replace("<br />", " DE ")}
              href={`/${accessType}/conteudo/${value.Codigo}`}
            />
          ))}
        </div>
      </div>

      <globalComponents.Footer />
    </>
  );
}
