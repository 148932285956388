import React from "react";
import { useParams } from "react-router-dom";

import * as globalComponents from "../../../../components";
import ListEntry from "../../components/ListEntry";

import "../styles.css";

export default function Menu({ title, links, dir }) {
  const { accessType } = useParams();

  return (
    <>
      <globalComponents.Header />

      <globalComponents.Breadcrumbs title={title} dir={dir} />

      <div className="container">
        <div className="title__publications">
          <strong>{title}</strong>

          {links.map((value, index) => (
            <ListEntry
              key={index}
              title={value.title}
              href={`/${accessType}${value.href}`}
            />
          ))}
        </div>
      </div>

      <globalComponents.Footer />
    </>
  );
}
