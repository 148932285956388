import React from "react";

import "./styles.css";

export default function SwiperSlide({ title, call, img, href }) {
  return (
    <a className="swiper-slide" href={href}>
      <img src={img} alt="" />
      <div className="overflow_banner">
        <div className="texto__banner">
          <p>{title}</p>
          <span>{call}</span>
        </div>
      </div>
    </a>
  );
}
