import api from "../../../../services/api";

import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";

import * as globalComponents from "../../../../components";
import ListEntry from "../../components/ListEntry";

import "../styles.css";

import socialActionsBanner from "./images/acoes_sociais_banner.png";

export default function SocialActions() {
  const { accessType } = useParams();
  const [data, setData] = useState([]);

  const dir = [];

  useEffect(() => {
    window.scroll(0, 0);
    async function fetchData() {
      await api
        .get("/acoes_sociais")
        .then((res) => {
          setData(res.data);
        })
        .catch((err) => {
          console.log(err);
        });
    }
    fetchData();
  }, []);

  return (
    <>
      <globalComponents.Header />

      <globalComponents.Breadcrumbs title="Ações Sociais" dir={dir} />

      <div className="container">
        <div className="page_banner_container">
          <img
            className="page_banner"
            src={socialActionsBanner}
            alt="Ações Sociais"
          />
        </div>

        <p>
          <br />
          O Crefito-2 tem participação ativa nas ações sociais que versam sobre
          o apoio à população do Rio de Janeiro, com iniciativas que vão desde
          programas de capacitação para profissionais da Fisioterapia e da
          Terapia Ocupacional sobre os benefícios terapêuticos a campanhas de
          conscientização sobre saúde e prevenção de doenças.
          <br />
          <br />
          As ações do Conselho têm foco nas medidas preventivas para
          proporcionar uma vida funcional mais saudável e visam promover o
          bem-estar e melhorar a qualidade de vida, demonstrando o compromisso
          do Crefito-2 com a saúde e o desenvolvimento social da população do
          Estado do Rio.
          <br />
          <br />O Conselho se mobiliza no envolvimento das ações que impactam
          positivamente a qualidade de vida daqueles que mais precisam. A
          atuação do Colegiado do Crefito-2 vai além da vertente profissional e
          reforça também a prática da caridade e solidariedade com a população,
          transcendendo o aspecto técnico da Fisioterapia e da Terapia
          Ocupacional, e abraçando a missão mais ampla de contribuir para um
          mundo melhor.
        </p>

        <div className="title__publications">
          <strong>Ações Sociais</strong>

          {data.map((contentValue, contentIndex) => (
            <ListEntry
              key={contentIndex}
              title={contentValue.Titulo}
              call={contentValue.Subtitulo}
              href={`/${accessType}/acoes_sociais/${contentValue.Codigo}`}
            />
          ))}
        </div>
      </div>

      <globalComponents.Footer />
    </>
  );
}
