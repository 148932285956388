import api from "../../../../../services/api";

import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";

import * as globalComponents from "../../../../../components";
import ListEntry from "../../../components/ListEntry";

import "./styles.css";
import "../../styles.css";

export default function CoffitoResolutions() {
  const { accessType } = useParams();

  const dir = [
    {
      title: "Trasparência e Prestação de Contas",
      url: `/${accessType}/transparencia`,
    },
  ];

  const [data, setData] = useState({
    resume: "",
    resolves: [
      {
        Codigo: null,
        Titulo: "",
        Subtitulo: "",
      },
    ],
  });

  useEffect(() => {
    window.scroll(0, 0);
    async function fetchData() {
      await api
        .get("/resolucoes-coffito")
        .then((response) => {
          setData(response.data);
        })
        .catch((err) => {
          console.log(err);
        });
    }
    fetchData();
  }, []);

  return (
    <>
      <globalComponents.Header />

      <globalComponents.Breadcrumbs title="Resoluções Coffito" dir={dir} />

      <div className="container">
        <div className="title__publications">
          <strong>Resoluções Coffito</strong>

          <div
            className="content-text"
            dangerouslySetInnerHTML={{ __html: data.resume }}
          ></div>

          {data.resolves.map((value, index) => (
            <ListEntry
              key={index}
              title={value.Titulo}
              call={value.Subtitulo}
              href={`/${accessType}/transparencia/resolucoes_coffito/${value.Codigo}`}
            />
          ))}
        </div>
      </div>

      <globalComponents.Footer />
    </>
  );
}
