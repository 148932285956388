import api from "../../../../services/api";

import React, { useState, useEffect } from "react";

import * as globalComponents from "../../../../components";

import "../styles.css";

export default function Comunication() {
  const [data, setData] = useState([]);

  useEffect(() => {
    async function fetchData() {
      await api
        .get("/comunicacao")
        .then((response) => {
          setData(response.data);
        })
        .catch((err) => {
          console.log(err);
        });
    }
    fetchData();
  }, []);

  return (
    <>
      <globalComponents.Header />

      <globalComponents.Breadcrumbs title="Comunicação" dir={[]} />

      <div className="container">
        <div className="title__publications">
          <strong>Assessoria de Comunicação</strong>

          <p
            style={{
              marginTop: "50px",
            }}
          >
            Setor responsável pela gestão da comunicação do Crefito-2, incluindo
            a edição dos veículos impressos e eletrônicos, atendimento à
            imprensa, gestão de marketing de redes sociais, promoção e apoio de
            eventos.
          </p>

          {data.map((value, index) => (
            <a
              key={index}
              className="publications_block"
              href={`mailto:${value.Subtitulo}`}
            >
              <div className="publication-content">
                <p
                  className="title"
                  dangerouslySetInnerHTML={{ __html: value.Titulo }}
                ></p>
                <p className="call">{"E-mail: " + value.Subtitulo}</p>
              </div>
            </a>
          ))}

          <p
            style={{
              marginTop: "100px",
            }}
          >
            Telefone: <a href="tel:+552121692179">(21) 2169-2179</a>
          </p>
        </div>
      </div>

      <globalComponents.Footer />
    </>
  );
}
