import React from "react";
import { useParams } from "react-router-dom";

import * as globalComponents from "../../../../components";
import AnonymousReportsForm from "./components/AnonymousReportsForm";

import "../styles.css";

export default function AnonymousReports() {
  const { accessType } = useParams();
  const dir = [
    {
      title: "Fiscalização",
      url: `/${accessType}/fiscalizacao`,
    },
    {
      title: "Denúncias",
      url: `/${accessType}/fiscalizacao/denuncias`,
    },
  ];

  return (
    <>
      <globalComponents.Header />

      <globalComponents.Breadcrumbs title="Denúncia Anônima" dir={dir} />

      <div className="container">
        <globalComponents.PageTitle title="Denúncia Anônima" />

        <div className="denuncia--container__wrapper">
          <div className="denuncia--content">
            <div className="denuncia__texto">
              <p>
                É possível fazer uma denúncia sem a identificação do
                denunciante. Em alguns casos, as provas necessárias surgirão no
                decorrer da instrução do processo.
              </p>
              <br />
              <p>
                No entanto, é fundamental observar que denúncias anônimas, sem
                indícios mínimos de prova ou tidas como frágeis e
                inconsistentes, dificultam ou até mesmo inviabilizam a instrução
                de possível processo, inexistindo a possibilidade de finalização
                das providências cabíveis, sendo passível de arquivamento.
              </p>
              <br />
              <p>
                Para registrar a denúncia, atente-se à identificação do
                denunciado, registrando todos os dados possíveis para este fim.
              </p>
              <br />
              <p>
                <strong>ATENÇÃO:</strong> Informamos ao denunciante anônimo que
                as denúncias recebidas no Crefito-2 são apuradas e quando
                comprovada a desconformidade com as normativas das profissões,
                serão tomadas as medidas cabíveis, porém o denunciante anônimo
                não poderá acompanhar o andamento da denúncia.
              </p>
              <br />
              <p>
                <strong>Obs:</strong> Neste caso, em virtude do anonimato o
                protocolo de acompanhamento NÃO é gerado.
              </p>
            </div>

            <AnonymousReportsForm />
          </div>
        </div>
      </div>

      <globalComponents.Footer />
    </>
  );
}
