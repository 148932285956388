import api from "../../../../services/api";

import React, { useState, useEffect } from "react";
import { useLocation, useParams } from "react-router-dom";

import * as globalComponents from "../../../../components";
import SearchResultsEntry from "./components/SearchResultsEntry";

import "../styles.css";
import "./styles.css";

export default function SearchResults() {
  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }

  let query = useQuery();
  var title = "Resultados da Busca";
  var apiRoute = "/filtro";

  const { accessType } = useParams();
  const [search] = useState(query.get("busca"));
  const [publications, setPublications] = useState([]);
  const [resultsLength, setResultsLength] = useState(1);
  const [currentPage] = useState(parseInt(query.get("pagina")));
  const [publicationsPerPage] = useState(10);

  useEffect(() => {
    if (resultsLength >= 1) {
      document.getElementById("empty_results").setAttribute("hidden", "true");
      if (resultsLength > publicationsPerPage) {
        document.getElementById("pagination").removeAttribute("hidden");
      }
    } else if (resultsLength < 1) {
      document.getElementById("empty_results").removeAttribute("hidden");
      document.getElementById("pagination").setAttribute("hidden", "true");
    }
  }, [resultsLength, publicationsPerPage]);

  useEffect(() => {
    window.scroll(0, 0);
    async function fetchPublications() {
      await api
        .get(apiRoute + "?filter=" + search)
        .then((response) => {
          setPublications(response.data);
          setResultsLength(response.data.length);
        })
        .catch((err) => {
          console.log(err);
          setResultsLength(0);
        });
    }
    fetchPublications();
  }, [apiRoute, search]);

  const indexOfLastPublication = currentPage * publicationsPerPage;
  const indexOfFirstPublication = indexOfLastPublication - publicationsPerPage;
  const currentPublications = publications.slice(
    indexOfFirstPublication,
    indexOfLastPublication
  );

  function handleToFirst() {
    query.set("pagina", "1");
    window.location.search = query.toString();
  }

  function handleToPrevious() {
    if (parseInt(query.get("pagina")) > 1) {
      query.set("pagina", `${parseInt(query.get("pagina")) - 1}`);
      window.location.search = query.toString();
    }
  }

  function handleToNext() {
    if (
      parseInt(query.get("pagina")) <
      Math.ceil(resultsLength / publicationsPerPage)
    ) {
      query.set("pagina", `${parseInt(query.get("pagina")) + 1}`);
      window.location.search = query.toString();
    }
  }

  function handleToLast() {
    query.set("pagina", `${Math.ceil(resultsLength / publicationsPerPage)}`);
    window.location.search = query.toString();
  }

  return (
    <>
      <globalComponents.Header />

      <globalComponents.Breadcrumbs title={title} dir={[]} />

      <div className="container">
        <div className="title__publications">
          <strong>{title}</strong>

          <div id="empty_results" className="empty_results" hidden>
            <h3>Registros Não Encontrados!</h3>
            <p>Não foram encontrados resultados para essa pesquisa.</p>
          </div>

          {currentPublications.map((value, index) => (
            <SearchResultsEntry
              key={index}
              title={value.Titulo}
              description={value.Descricao}
              href={`/${accessType}/conteudo/${value.Codigo}`}
              imgURL={value.Nome_Arquivo}
              date={value.Data_Publicacao}
            />
          ))}

          <div id="pagination" className="pagination" hidden>
            <div className="btn__pagination">
              <div className="btn-mobel">
                <button onClick={handleToFirst}>Primeira</button>
                <button onClick={handleToPrevious}>Anterior</button>
              </div>
              <div className="btn-mobel">
                <button onClick={handleToNext}>Próxima</button>
                <button onClick={handleToLast}>Última</button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <globalComponents.Footer />
    </>
  );
}
