import api from "../../../../services/api";

import React, { useEffect } from "react";
import ReactGA from "react-ga";

import AccessButton from "../../components/AccessButton";

import "../styles.css";

import imageLogoWhite from "../../images/logo_branca_crefito2.png";
import imagePeople from "../../images/people.svg";
import imageEmail from "../../images/email.svg";

function ExportTable() {
  // Google Analytics
  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  });

  async function ExportRegistrations() {
    document.getElementById("modal").removeAttribute("hidden");
    await api
      .get("/exportar_tabelas?table=Cadastro")
      .then((response) => {
        if (response.data.success) {
          document.getElementById("modal").setAttribute("hidden", "true");
          alert("Sucesso!");
        } else {
          document.getElementById("modal").setAttribute("hidden", "true");
          alert("Ocorreu um erro! Tente novamente mais tarde.");
        }
      })
      .catch((err) => {
        document.getElementById("modal").setAttribute("hidden", "true");
        console.log(err);
      });
  }

  async function ExportNewsletter() {
    document.getElementById("modal").removeAttribute("hidden");
    await api
      .get("/exportar_tabelas?table=Cadastro_Newsletter")
      .then((response) => {
        if (response.data.success) {
          document.getElementById("modal").setAttribute("hidden", "true");
          alert("Sucesso!");
        } else {
          document.getElementById("modal").setAttribute("hidden", "true");
          alert("Ocorreu um erro! Tente novamente mais tarde.");
        }
      })
      .catch((err) => {
        document.getElementById("modal").setAttribute("hidden", "true");
        console.log(err);
      });
  }

  async function ExportProfessionalCards() {
    document.getElementById("modal").removeAttribute("hidden");
    await api
      .get("/exportar_tabelas?table=Emissao_Carteira")
      .then((response) => {
        if (response.data.success) {
          document.getElementById("modal").setAttribute("hidden", "true");
          alert("Sucesso!");
        } else {
          document.getElementById("modal").setAttribute("hidden", "true");
          alert("Ocorreu um erro! Tente novamente mais tarde.");
        }
      })
      .catch((err) => {
        document.getElementById("modal").setAttribute("hidden", "true");
        console.log(err);
      });
  }

  return (
    <>
      <div className="login">
        <div className="login__container">
          <div className="login__logo">
            <img src={imageLogoWhite} alt="" />
          </div>

          <div className="login__text">
            <p>Escolha a tabela que quer exportar.</p>
          </div>

          <div className="tipo__login">
            <div className="login_user" onClick={ExportRegistrations}>
              <AccessButton
                type="Tabela Cadastro"
                image={imagePeople}
                message="EXPORTAR"
              />
            </div>

            <div className="login_user" onClick={ExportNewsletter}>
              <AccessButton
                type="Tabela Newsletter"
                image={imageEmail}
                message="EXPORTAR"
              />
            </div>

            <div className="login_user" onClick={ExportProfessionalCards}>
              <AccessButton
                type="Tabela Emissão de Carteira Digital"
                image={imageEmail}
                message="EXPORTAR"
              />
            </div>
          </div>

          <p className="disclaimer">
            Este conteúdo será enviado EXCLUSIVAMENTE ao departamento de
            Informática do CREFITO2, especificamente ao e-mail do Sr. Hudson
            Soeiro.
          </p>
        </div>
      </div>

      <div id="modal" hidden>
        <div className="content">
          <div className="box">
            <h1>Carregando...</h1>
          </div>
        </div>
      </div>
    </>
  );
}

export default ExportTable;
