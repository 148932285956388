import React from "react";
import { Link } from "react-router-dom";

import "./styles.css";

export default function NavItem({
  title,
  icon = "",
  href,
  external,
  img = null,
}) {
  return img ? (
    <li className="nav-item">
      {external ? (
        <a
          className="nav-link"
          target="_blank"
          rel="noopener noreferrer"
          href={href}
        >
          <img src={img} alt={title} />
          {title}
        </a>
      ) : (
        <Link className="nav-link" to={href}>
          <img src={img} alt={title} />
          {title}
        </Link>
      )}
    </li>
  ) : (
    <li className="nav-item">
      {external ? (
        <a
          className="nav-link"
          target="_blank"
          rel="noopener noreferrer"
          href={href}
        >
          <span className="material-symbols-outlined">{icon}</span>
          {title}
        </a>
      ) : (
        <Link className="nav-link" to={href}>
          <span className="material-symbols-outlined">{icon}</span>
          {title}
        </Link>
      )}
    </li>
  );
}
