import api from "../../../../services/api";

import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";

import * as globalComponents from "../../../../components";

import "../../styles.css";

export default function MissionVisionAndValues() {
  const { accessType } = useParams();

  const dir = [
    {
      title: "Crefito2",
      url: `/${accessType}/crefito2`,
    },
  ];

  const [data, setData] = useState([]);

  useEffect(() => {
    window.scroll(0, 0);
    async function fetchData() {
      await api
        .get(`/conteudo/5068`)
        .then((response) => {
          setData(response.data);
        })
        .catch((err) => {
          console.log(err);
        });
    }
    fetchData();
  }, []);

  return (
    <>
      <globalComponents.Header />

      <globalComponents.Breadcrumbs
        title={"Missão, Visão e Valores"}
        dir={dir}
      />

      <div className="container">
        <div className="content-wrapper">
          <globalComponents.PageTitle
            title="Missão, Visão e Valores"
            subtitle={data.Subtitulo}
            span={data.Data_Publicacao}
          />

          <div className="content-image">
            <img src={data.Nome_Arquivo} alt="" />
          </div>

          <div
            className="content-text"
            dangerouslySetInnerHTML={{ __html: data.Materia }}
          ></div>
        </div>
      </div>

      <globalComponents.Footer />
    </>
  );
}
