import React, { useState } from "react";
import Modal from "../../../Modal";

import "./styles.css";

import carlinhosSplash from "./images/carlinhosSplash.png";
import carlinhosIcon from "./images/carlinhosIcon.jpg";

function Carlinhos({ initialState }) {
  const [hideCarlinhos, setHideCarlinhos] = useState(initialState);

  return (
    <>
      <div
        hidden={!hideCarlinhos}
        className="carlinhos-button"
        onClick={() => {
          setHideCarlinhos(!hideCarlinhos);
        }}
      >
        <img src={carlinhosIcon} alt="Carlinhos" />
      </div>

      <Modal
        id={"carlinhos"}
        hidden={hideCarlinhos}
        imageSrc={carlinhosSplash}
        imageAlt={"Carlinhos"}
        setState={setHideCarlinhos}
        href={"https://api.whatsapp.com/send?phone=552121692169"}
      />
    </>
  );
}

export default Carlinhos;
