import React from "react";
import { Link } from "react-router-dom";

import "./styles.css";

export default function LinkCard({ title, img, href, external }) {
  return external ? (
    <a
      href={href}
      className="link-card"
      target="_blank"
      rel="noopener noreferrer"
    >
      <div className="link-card-image-container">
        <img src={img} alt={title} />
        <div className="background"></div>
      </div>

      <h1 className="link-card-title">{title}</h1>
    </a>
  ) : (
    <Link className="link-card" to={href}>
      <div className="link-card-image-container">
        <img src={img} alt={title} />
        <div className="background"></div>
      </div>

      <h1 className="link-card-title">{title}</h1>
    </Link>
  );
}
