import React from "react";

import "./styles.css";

export default function Modal({
  id,
  hidden,
  text,
  setState,
  imageSrc,
  imageAlt,
  href,
}) {
  return (
    <div id={id} hidden={hidden}>
      <div className="content">
        {href ? (
          <div className="box">
            <div
              className="close"
              onClick={() => {
                setState(true);
              }}
            >
              <span className="material-icons close-icon">close</span>
            </div>
            <a href={href} rel="noreferrer noopener" target="_blank">
              <p dangerouslySetInnerHTML={{ __html: text }}></p>
              {imageSrc ? (
                <img src={imageSrc} alt={imageAlt ? imageAlt : "imagem"} />
              ) : null}{" "}
            </a>
          </div>
        ) : (
          <div className="box">
            <div
              className="close"
              onClick={() => {
                setState(true);
              }}
            >
              <span className="material-icons">close</span>
            </div>
            <p dangerouslySetInnerHTML={{ __html: text }}></p>
            {imageSrc ? (
              <img src={imageSrc} alt={imageAlt ? imageAlt : "imagem"} />
            ) : null}
          </div>
        )}
      </div>
    </div>
  );
}
