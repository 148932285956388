import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import imageIconArrow from "./images/icon-arrow.png";

import "./styles.css";

import api from "../../../../services/api";

import InteractiveMap from "./components/InteractiveMap";

export default function OfficesLocations() {
  const { accessType } = useParams();
  const [offices, setOffices] = useState([]);
  const [currentOffice, setCurrentOffice] = useState({});
  const [currentOfficeName, setCurrentOfficeName] = useState(null);

  useEffect(() => {
    async function fetchOffices() {
      async function fetchRioOffice() {
        return await api
          .get("/enderecos")
          .then((response) => {
            const rioOfficeItem = response.data.find(
              (office) => office.Titulo === "Rio de Janeiro"
            );
            const { Titulo, Chamada, Link, Subtitulo } = rioOfficeItem;
            const rioOfficeObject = {
              Titulo: `${Subtitulo} ${Titulo}`,
              Materia: `</br> ${Chamada} </br></br> <a href="${Link}" target="_blank" rel="noopener noreferrer"><strong>Veja no mapa</strong></a>`,
            };

            return rioOfficeObject;
          })
          .catch((err) => {
            console.log(err);
          });
      }

      const rioOffice = await fetchRioOffice();

      await api
        .get("/polos-institucionais")
        .then((response) => {
          setOffices([rioOffice, ...response.data.centers]);
        })
        .catch((err) => {
          console.log(err);
        });
    }

    fetchOffices();
    setCurrentOfficeName("rio_de_janeiro");
  }, []);

  useEffect(() => {
    if (currentOfficeName) {
      const currentOfficeValue = offices.find((office) => {
        const officeName = office.Titulo.toLowerCase()
          .normalize("NFD")
          .replace(/[\u0300-\u036f]/g, "")
          .split("ede ")
          .pop()
          .trim()
          .replace(/ /g, "_");

        return officeName === currentOfficeName.toLowerCase();
      });

      setCurrentOffice(currentOfficeValue);

      if (currentOfficeValue === "campo_grande") {
        const activeVectors = document.getElementsByClassName("active");

        for (let i = 0; i < activeVectors.length; i++) {
          activeVectors[i].classList.remove("active");
        }

        document.getElementById("rio_de_janeiro").classList.add("active");
      }
    }
  }, [currentOfficeName, offices]);

  return (
    <div
      className="container"
      style={{ paddingTop: "50px", paddingBottom: "50px", marginTop: "0" }}
    >
      <div className="mapa_bloco">
        <div className="mapa_bloco--titulo">
          <div className="mapa_bloco--titulos">
            <h2>Onde Ser Atendido?</h2>
            <p>Crefito-2 mais perto de você</p>
          </div>

          <div className="mapa_bloco--button">
            <Link className="button" to={`/${accessType}/conteudo/4111`}>
              Ver todas as Sedes e Subsedes <img src={imageIconArrow} alt="" />
            </Link>
          </div>
        </div>

        <div className="row">
          <div className="offices-container">
            <InteractiveMap setCurrentOfficeName={setCurrentOfficeName} />
            <div id="office-card" className="office-card">
              {currentOffice && (
                <>
                  <h3 className="office-card-title">{currentOffice.Titulo}</h3>
                  <div
                    className="materia"
                    dangerouslySetInnerHTML={{
                      __html: currentOffice.Materia,
                    }}
                  ></div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
