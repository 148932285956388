import React from "react";
import { useParams } from "react-router-dom";

import * as globalComponents from "../../../../components";
import ElectoralJustificationForm from "./components/ElectoralJustificationForm";

import "../styles.css";

export default function ElectoralJustification() {
  const { accessType } = useParams();
  const dir = [
    {
      title: "Serviços Online",
      url: `/${accessType}/servicos-online`,
    },
  ];

  return (
    <>
      <globalComponents.Header />

      <globalComponents.Breadcrumbs
        title="Formulário de Justificativa Eleitoral"
        dir={dir}
      />

      <div className="container">
        <globalComponents.PageTitle title="Formulário de Justificativa Eleitoral" />
        <div className="denuncia--container__wrapper">
          <div className="denuncia--content">
            <ElectoralJustificationForm />
          </div>
        </div>
      </div>

      <globalComponents.Footer />
    </>
  );
}
