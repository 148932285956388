import React from "react";
import { useParams } from "react-router-dom";

import * as globalComponents from "../../../../components";
import DigitalDocumentsCard from "./components/DigitalDocumentCard";

import "../styles.css";
import "./styles.css";

import documentsModelsDesktop from "./images/documents_models_desktop.png";
import documentsModelsMobile from "./images/documents_models_mobile.png";
import callToAction1 from "./images/call_to_action_1.png";
import callToAction2 from "./images/call_to_action_2.png";
import callToAction3 from "./images/call_to_action_3.png";
// import areaDoPacienteIcon from "./images/icons/area_do_paciente.svg";
import receitaDigitalIcon from "./images/icons/receita_digital.svg";
import atestadoDigitalIcon from "./images/icons/atestado_digital.svg";
import declaracaoDigitalIcon from "./images/icons/declaracao_digital.svg";
import relatorioDigitalIcon from "./images/icons/relatorio_digital.svg";
import solicitacaoDeExameIcon from "./images/icons/solicitacao_de_exame.svg";
import minhasReceitasEDocumentosIcon from "./images/icons/minhas_receitas_e_documentos.svg";
import cartaoDeVisitaIcon from "./images/icons/cartao_de_visita.svg";
import modelosDeProntuarioDoPacienteIcon from "./images/icons/modelos_de_prontuario_do_paciente.svg";
import agendamentoDoPacienteIcon from "./images/icons/agendamento_do_paciente.svg";
import reciboDigitalIcon from "./images/icons/recibo_digital.svg";
import confirmacaoDeAutenticacaoIcon from "./images/icons/confirmacao_de_autenticacao.svg";
import termoDeConsentimentoDeImagemIcon from "./images/icons/termo_de_consentimento_de_imagem.svg";
import contratoDePrestacaoDeServicoDeFisioterapiaIcon from "./images/icons/contrato_de_prestacao_de_servico_de_fisioterapia.svg";
import contratoDePrestacaoDeServicoDeTerapiaOcupacionalIcon from "./images/icons/contrato_de_prestacao_de_servico_de_terapia_ocupacional.svg";
import tcleAtendimentosDeAdultosIcon from "./images/icons/tcle_atendimentos_de_adultos.svg";
import tcleAtendimentosDeCriançasEAdolescentesIcon from "./images/icons/tcle_atendimentos_de_criancas_e_adolescentes.svg";
import dermatofuncionalIcon from "./images/icons/dermatofuncional.svg";

export default function DigitalDocuments() {
  const { accessType } = useParams();
  const digitalDocumentsLinks = [
    {
      title: "Receita Digital",
      icon: receitaDigitalIcon,
      description:
        "Documento que atende os pré-requisitos exigidos na legislação e em normativas sanitárias e éticas para o receituário.",
      href: "https://crefito2.com.br/Agendamento/APP/",
      internal: false,
      disabled: false,
    },
    {
      title: "Atestado Digital",
      icon: atestadoDigitalIcon,
      description:
        "Assegura a veracidade do atendimento e da qualificação do profissional para recomendar o afastamento do convívio temporário de outras pessoas ou atividades.",
      href: "https://crefito2.com.br/Agendamento/APP/",
      internal: false,
      disabled: false,
    },
    {
      title: "Declaração Digital",
      icon: declaracaoDigitalIcon,
      description:
        "Comprova a presença do paciente ao atendimento terapêutico, utilizada para justificar uma ausência temporária dos trabalhadores de suas atividades presenciais.",
      href: "https://crefito2.com.br/Agendamento/APP/",
      internal: false,
      disabled: false,
    },
    {
      title: "Relatório Digital",
      icon: relatorioDigitalIcon,
      description:
        "Descreve o objetivo proposto, o plano e a evolução do tratamento. Podendo ser utilizado para fins de reembolso junto ao plano de saúde e laudos periciais (ex.: INSS).",
      href: "https://crefito2.com.br/Agendamento/APP/",
      internal: false,
      disabled: false,
    },
    {
      title: "Solicitação de Exame Digital",
      icon: solicitacaoDeExameIcon,
      description:
        "Utilizado para complementar o raciocínio clínico do profissional no diagnóstico, como na solicitação de exames de imagem e bioquímicos.",
      href: "https://crefito2.com.br/Agendamento/APP/",
      internal: false,
      disabled: false,
    },
    {
      title: "Minhas Receitas e Documentos",
      icon: minhasReceitasEDocumentosIcon,
      description:
        "Funciona como um laudo histórico que comprova o monitoramento adequado da saúde, planejamento e tratamento do paciente.",
      href: "https://crefito2.com.br/Agendamento/APP/",
      internal: false,
      disabled: false,
    },
    {
      title: "Cartão de Visita",
      icon: cartaoDeVisitaIcon,
      description:
        "Serve como uma apresentação para clientes que querem conhecer o trabalho do(a) profissional, contendo informações dos serviços, endereço, contatos e mídias.",
      href: "https://crefito2.com.br/Agendamento/APP/",
      internal: false,
      disabled: false,
    },
    {
      title: "Prontuário Digital de Pacientes",
      icon: modelosDeProntuarioDoPacienteIcon,
      description:
        "História Terapêutica do Paciente: Consultas, avaliações, exames, atendimento, prognóstico, dentre outros.",
      href: `https://crefito2.com.br/Agendamento/APP/`,
      internal: false,
      disabled: false,
    },
    {
      title: "Agendamento do Paciente",
      icon: agendamentoDoPacienteIcon,
      description:
        "Uma ferramenta essencial para gerenciar consultas e procedimentos, permitindo que profissionais organizem o calendário, registrem informações dos pacientes e definam horários.",
      href: "https://crefito2.com.br/Agendamento/APP/",
      internal: false,
      disabled: false,
    },
    {
      title: "Recibo Digital",
      icon: reciboDigitalIcon,
      description:
        "Serve para comprovar pagamentos e recebimentos entre pessoas físicas ou jurídicas, ajudando você a manter a gestão financeira e contábil organizada.",
      href: "https://crefito2.com.br/Agendamento/APP/",
      internal: false,
      disabled: false,
    },
    {
      title: "Confirmação de Autenticação",
      icon: confirmacaoDeAutenticacaoIcon,
      description:
        "Verificar a veracidade de documentos, evitando possíveis fraudes. Ao autenticar junto ao Crefito-2, é possível garantir a legitimidade dos documentos apresentados, proporcionando maior segurança e confiabilidade.",
      href: "https://www.crefito2.com.br/spw/consultacadastral/ConsultaDocumento.aspx",
      internal: false,
      disabled: false,
    },
    {
      title: "Termo de Consentimento de Imagem",
      icon: termoDeConsentimentoDeImagemIcon,
      description:
        "Utilizado como garantia para realização do uso de fotografia ou filmagem de pacientes, assegurando que não estará violando direitos de imagem em sua divulgação.",
      href: "https://crefito2.go2webdbm.com.br/fotos/fotos/Termo%20de%20Consentimento%20de%20imagem%20Digital.pdf",
      internal: false,
      disabled: false,
    },
    {
      title: "Contrato de Prestação de Serviço de Fisioterapia",
      icon: contratoDePrestacaoDeServicoDeFisioterapiaIcon,
      description:
        "Instrumento jurídico usado para registrar e formalizar a relação comercial entre o profissional que irá realizar o atendimento e quem está recebendo o atendimento.",
      href: "https://crefito2.go2webdbm.com.br/fotos/fotos/CONTRATO%20DE%20PRESTA%C3%87%C3%83O%20DE%20SERVI%C3%87OS%20DE%20FISIOTERAPIA%20-%20DIGITAL.pdf",
      internal: false,
      disabled: false,
    },
    {
      title: "Contrato de Prestação de Serviço de Terapia Ocupacional",
      icon: contratoDePrestacaoDeServicoDeTerapiaOcupacionalIcon,
      description:
        "Instrumento jurídico usado para registrar e formalizar a relação comercial entre o profissional que irá realizar o atendimento e quem está recebendo o atendimento.",
      href: "https://crefito2.go2webdbm.com.br/fotos/fotos/CONTRATO%20DE%20PRESTA%C3%87%C3%83O%20DE%20SERVI%C3%87OS%20DE%20TERAPIA%20OCUPACIONAL%20-%20DIGITAL.pdf",
      internal: false,
      disabled: false,
    },
    {
      title: "TCLE Atendimentos de Adultos Fisioterapia",
      icon: tcleAtendimentosDeAdultosIcon,
      description:
        "Cumpre a finalidade de garantir a integral autonomia do paciente, ao ser informado, de forma clara, acerca da finalidade, riscos e benefícios do seu tratamento de saúde.",
      href: "https://crefito2.go2webdbm.com.br/fotos/fotos/TCLE%20-%20atendimento%20de%20adultos%20Fisioterapia%20Digital.pdf",
      internal: false,
      disabled: false,
    },
    {
      title: "TCLE Atendimentos de Crianças e Adolescentes Fisioterapia",
      icon: tcleAtendimentosDeCriançasEAdolescentesIcon,
      description:
        "Cumpre a finalidade de garantir a integral autonomia do paciente, ao ser informado, de forma clara, acerca da finalidade, riscos e benefícios do seu tratamento de saúde.",
      href: "https://crefito2.go2webdbm.com.br/fotos/fotos/TCLE%20-%20atendimento%20de%20crian%C3%A7as%20e%20adolescentes%20Fisioterapia%20Digital.pdf",
      internal: false,
      disabled: false,
    },
    {
      title: "TCLE Atendimentos de Adultos Terapia Ocupacional",
      icon: tcleAtendimentosDeAdultosIcon,
      description:
        "Cumpre a finalidade de garantir a integral autonomia do paciente, ao ser informado, de forma clara, acerca da finalidade, riscos e benefícios do seu tratamento de saúde.",
      href: "https://crefito2.go2webdbm.com.br/fotos/fotos/TCLE%20-%20atendimento%20de%20adultos%20Terapia%20Ocupacional%20Digital.pdf",
      internal: false,
      disabled: false,
    },
    {
      title: "TCLE Atendimentos de Crianças e Adolescentes Terapia Ocupacional",
      icon: tcleAtendimentosDeCriançasEAdolescentesIcon,
      description:
        "Cumpre a finalidade de garantir a integral autonomia do paciente, ao ser informado, de forma clara, acerca da finalidade, riscos e benefícios do seu tratamento de saúde.",
      href: "https://crefito2.go2webdbm.com.br/fotos/fotos/TCLE%20-%20atendimento%20de%20crian%C3%A7as%20e%20adolescentes%20Terapia%20Ocupacional%20Digital.pdf",
      internal: false,
      disabled: false,
    },
    {
      title: "Dermatofuncional",
      icon: dermatofuncionalIcon,
      description:
        "Protocolos Clínicos e Diretrizes Terapêuticas de Procedimento Operacional padrão de Dermatofuncional  para o atendimento de cada profissional.",
      href: `/${accessType}/documentos_digitais/dermatofuncional`,
      internal: true,
      disabled: false,
    },
  ];

  return (
    <>
      <globalComponents.Header />

      <div className="digital_documents_banner">
        <h1 className="title">
          Eleve a sua atuação Profissional com nossos Documentos Digitais
        </h1>

        <p className="description">
          Descubra a praticidade que o Crefito-2 traz com modelos exclusivos
          para otimizar suas consultas!
        </p>

        <div className="buttons">
          {/* <a
            href={`/${accessType}/documentos_digitais#paciente`}
            className="button"
          >
            Paciente
          </a> */}
          <a
            href={`/${accessType}/documentos_digitais#documentos_digitais`}
            className="button"
          >
            Documentos
          </a>
          <a
            href={`/${accessType}/documentos_digitais#beneficios`}
            className="button"
          >
            Benefícios
          </a>
        </div>
      </div>

      {/* <div id="paciente" className="digital_documents_container">
        <div className="patient_area">
          <div className="title_container">
            <h1 className="title">Acessar área do Paciente</h1>
            <img
              src={areaDoPacienteIcon}
              alt="Área do Paciente"
              className="title_icon"
            />
          </div>
          <div className="patient_area_button_container">
            <p className="description">Acesse e faça seu login do paciente</p>

            <a
              className="button"
              target="_blank"
              rel="noopener noreferrer"
              href="https://crefito2.com.br/Agendamento/APP/"
            >
              Login
            </a>
          </div>
          <div className="patient_area_button_container">
            <p className="description">Não tem uma conta? Faça seu cadastro!</p>
            <a
              className="button colored"
              target="_blank"
              rel="noopener noreferrer"
              href="https://crefito2.com.br/Agendamento/APP/salvarpaciente?c=68776206000702795"
            >
              Cadastro
            </a>
          </div>
        </div>
      </div>

      <div className="separator_container">
        <hr className="separator" />
      </div> */}

      <div id="documentos_digitais" className="digital_documents_container">
        <div className="digital_documents">
          <h1 className="section_title">Documentos Digitais</h1>

          <p className="section_description">
            Experimente a inovação do Crefito-2 e eleve o padrão das suas
            consultas para um novo patamar com nossos modelos.
          </p>

          <div className="digital_documents_cards_grid">
            {digitalDocumentsLinks.map((value, index) => (
              <DigitalDocumentsCard
                key={index}
                title={value.title}
                description={value.description}
                icon={value.icon}
                href={value.href}
                internal={value.internal}
                disabled={value.disabled}
              />
            ))}
          </div>
        </div>
      </div>

      <div id="beneficios" className="container">
        <h1 className="section_title">Diversos Modelos de Documentos</h1>

        <p className="section_description">
          São diversos modelos de documetos desde Receita Digital, Atestado
          Digital, Declaração Digital e mais, tudo ao alcance dos profissionais
          a apenas um clique, uma transformação digital que simplifica e agiliza
          os processos.
        </p>

        <div className="documents_models_panel">
          <img
            className="mobile_only"
            src={documentsModelsMobile}
            alt="Diversos Modelos de Documentos"
          />

          <img
            className="desktop_only"
            src={documentsModelsDesktop}
            alt="Diversos Modelos de Documentos"
          />
        </div>

        <div className="call-to-action">
          <div className="call-to-action_text">
            <h1 className="call-to-action_title">
              Acesse a qualquer hora e lugar
            </h1>

            <p className="call-to-action_description">
              Flexibilidade sem fronteitas para a sua rotina profissional,
              aumentando sua eficiência nos processos. Nossos documentos
              digitais estão disponíveis online, permitindo fácil acesso para
              aprimorar significativamente o seu trabalho.
            </p>
          </div>

          <img
            className="call-to-action_image"
            src={callToAction1}
            alt="Acesse a qualquer hora e lugar"
          />
        </div>

        <div className="call-to-action desktop_reverse">
          <div className="call-to-action_text">
            <h1 className="call-to-action_title">Simples e Rápido</h1>

            <p className="call-to-action_description">
              Siga as instruções , adicione os dados do paciente e da consulta,
              e baixe facilmente, economizando. Transforme sua prática com a
              diversidade de modelos proporcionados pelo Crefito-2.
            </p>
          </div>

          <img
            className="call-to-action_image"
            src={callToAction2}
            alt="Acesse a qualquer hora e lugar"
          />
        </div>

        <div className="call-to-action">
          <div className="call-to-action_text">
            <h1 className="call-to-action_title">Ao alcance de todos</h1>

            <p className="call-to-action_description">
              Na era dos documentos digitais, a informação torna-se acessível a
              todos os profissionais e pacientes, simplificando os processos de
              consultas, além disso, facilita o pedido de reembolso,
              considerando que a maioria das operadoras já adota essa prática
              via aplicativo, contribuindo para uma interação mais eficiente e
              ágil.
            </p>
          </div>

          <img
            className="call-to-action_image"
            src={callToAction3}
            alt="Acesse a qualquer hora e lugar"
          />
        </div>

        <div className="president_comment">
          <h1 className="name">Dr. Wilen Heil e Silva</h1>
          <h2 className="title">PRESIDENTE DO CREFITO-2</h2>
          <p className="comment">
            “O Crefito-2 está cada vez mais comprometido em proporcionar
            ferramentas mais eficientes para os profissionais, buscando oferecer
            maior agilidade na prática clínica de fisioterapeutas e terapeutas
            ocupacionais e tornar a experiência mais atraente, informativa e
            funcional, ajudando a melhorar a qualidade do atendimento aos
            pacientes.”
          </p>
          <div className="separator"></div>
        </div>
      </div>

      <globalComponents.Footer />
    </>
  );
}
