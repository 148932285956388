import api from "../../../../services/api";

import React, { useState, useEffect } from "react";

import { useParams } from "react-router-dom";

import * as globalComponents from "../../../../components";
import BoardRow from "./components/BoardRow";

import "./styles.css";

export default function Board() {
  const { accessType } = useParams();

  const dir = [
    {
      title: "Crefito-2",
      url: `/${accessType}/crefito2`,
    },
  ];

  const [data, setData] = useState([[]]);

  useEffect(() => {
    async function fetchData() {
      await api
        .get("/gestores")
        .then((response) => {
          const board = [];
          const members = [
            ...response.data.chiefs,
            ...response.data.effectiveMentors,
            ...response.data.alternateMentors,
          ];

          while (members.length) {
            board.push(members.splice(0, 4));
          }

          console.log(board);
          setData(board);
        })
        .catch((err) => {
          console.log(err);
        });
    }
    fetchData();
  }, []);

  return (
    <>
      <globalComponents.Header />

      <globalComponents.Breadcrumbs title={"Gestão"} dir={dir} />

      <div className="container">
        <div className="row">
          {data.map((value, index) => (
            <BoardRow key={index} members={value} type={""} />
          ))}
        </div>
      </div>

      <globalComponents.Footer />
    </>
  );
}
