import api from "../../../../../../services/api";

import React, { useState } from "react";
import { useForm } from "react-hook-form";

import "./styles.css";

export default function ElectoralJustificationForm() {
  const [formFiles, setFormFiles] = useState(null);
  const [loading, setLoading] = useState(false);
  const { register, handleSubmit } = useForm();

  const disableFileUpload = () => {
    const submit = document.getElementById("submit");

    submit.value = "Carregando Arquivos...";
    submit.classList.add("disabled");
    submit.setAttribute("disabled", true);
  };

  const enableFileUpload = () => {
    const submit = document.getElementById("submit");

    submit.value = "Enviar";
    submit.classList.remove("disabled");
    submit.removeAttribute("disabled");
  };

  const clearFileUpload = () => {
    document.getElementById("files").value = null;
  };

  const uploadFiles = async (e) => {
    disableFileUpload();

    const uploadData = new FormData();
    const file = e.target.files[0];

    uploadData.append(`file`, file, file.name);

    if (file.size < 90000000) {
      try {
        const result = await api.post("/upload", uploadData);
        if (result.data.success) {
          setFormFiles(result.data.uploadedFiles);
          enableFileUpload();
        } else if (result.data.message.code) {
          alert(result.data.message.code);
          clearFileUpload();
          enableFileUpload();
        }
      } catch (err) {
        if (!alert("Ocorreu um erro! Tente novamente mais tarde.")) {
          window.scroll(0, 0);
          clearFileUpload();
          enableFileUpload();
        }
      }
    } else {
      alert("Arquivo deve ser menor que 90 MB!");
      clearFileUpload();
      enableFileUpload();
    }
  };

  async function onSubmit(data) {
    const userForm = { ...data, files: formFiles };

    try {
      setLoading(true);

      const result = await api.post("/form/justificativa-eleitoral", userForm);

      if (!alert(result.data.message)) {
        window.scroll(0, 0);
        window.location.reload();
      }
    } catch (err) {
      if (!alert("Ocorreu um erro! Tente novamente mais tarde.")) {
        window.scroll(0, 0);
        window.location.reload();
      }
    } finally {
      setLoading(false);
    }
  }

  return (
    <div className="denuncia__form">
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="form__title">
          <p>Justificativa Eleitoral</p>
        </div>

        <div className="form__options">
          <div className="option__column">
            <p>Selecione sua categoria</p>
            <ul>
              <li>
                <input
                  {...register("profile", { required: true })}
                  type="radio"
                  value="fisioterapia"
                />
                Fisioterapia
              </li>
              <li>
                <input
                  {...register("profile", { required: true })}
                  type="radio"
                  value="terapia ocupacional"
                />
                Terapia Ocupacional
              </li>
            </ul>
          </div>
        </div>

        <div className="form__divisor"></div>

        <div className="form__group">
          <input
            {...register("register", { required: true })}
            type="text"
            placeholder="Número de registro"
          />
          <input
            {...register("name", { required: true })}
            type="text"
            placeholder="Nome completo"
          />
          <div className="form__group--half">
            <input
              {...register("email", { required: true })}
              type="email"
              placeholder="Endereço de E-Mail"
            />
            <input
              {...register("cel", { required: true })}
              type="tel"
              placeholder="Telefone"
            />
          </div>
        </div>

        <div className="form__divisor"></div>

        <div className="form__group">
          <textarea
            {...register("justification", { required: true })}
            id="justification"
            cols="30"
            rows="10"
            placeholder="Justificativa"
          ></textarea>
        </div>

        <div className="form__divisor"></div>

        <div className="form__group">
          <div className="form-group files">
            <label htmlFor="files">Envio de arquivo</label>

            <p className="form__observation">
              <strong>Observação:</strong> em caso de múltiplos arquivos, envie
              uma pasta ".zip" contendo todos.
            </p>

            <input
              id="files"
              {...register("files")}
              type="file"
              className="form-control"
              multiple=""
              onChange={uploadFiles}
            />
          </div>

          <div className="user_authorization">
            <input
              id="userAuthorization"
              {...register("userAuthorization", { required: true })}
              type="checkbox"
              value="authorized"
            />
            <label htmlFor="userAuthorization">
              Autorizo o envio de informações para meu e-mail.
            </label>
          </div>

          <input
            id="submit"
            className={`button ${loading ? "disabled" : ""}`}
            type="submit"
            value="Enviar"
          />
        </div>
      </form>
    </div>
  );
}
