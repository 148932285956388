import React, { useEffect } from "react";
import { useParams } from "react-router-dom";

import * as globalComponents from "../../../../components";
import ProfessionalCardEmissionForm from "./components/ProfessionalCardEmissionForm";

import "../styles.css";

export default function ProfessionalCardEmission() {
  const { accessType } = useParams();
  const dir = [
    {
      title: "Serviços Online",
      url: `/${accessType}/servicos-online`,
    },
    {
      title: "Orientações para Profissionais",
      url: `/${accessType}/servicos-online/orientacoes-profissionais`,
    },
  ];

  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  return (
    <>
      <globalComponents.Header />

      <globalComponents.Breadcrumbs
        title="Emissão de Carteira Digital para Profissionais Ativos"
        dir={dir}
      />
      <div className="container">
        <div className="denuncia--container__wrapper">
          <div className="denuncia--content">
            <ProfessionalCardEmissionForm />
          </div>
        </div>
      </div>

      <globalComponents.Footer />
    </>
  );
}
